import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  StringMap: { [key: string]: string };
  Time: string;
  Timestamp: string;
};

/** Common properties between an `OrgAccount` and `UserAccount` */
export type Account = {
  /** The `ControlPlaneConfiguration`s owned by this `Account` */
  controlPlaneConfigurations: Array<ControlPlaneConfiguration>;
  /** Get a `Account`s `AccountControlPlane`s */
  controlPlanes: Array<AccountControlPlane>;
  /** The unique id for this `Account` */
  id: Scalars['ID'];
  /** Get an `Account`s `Repository`s */
  repositories: AccountRepositoriesResult;
  /** Get a `Repository` by `id` within a `Account` */
  repository: Repository;
  /** Spaces owned by this `Account` */
  spaces: Array<Space>;
};

/** Common properties between an `OrgAccount` and `UserAccount` */
export type AccountRepositoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** Common properties between an `OrgAccount` and `UserAccount` */
export type AccountRepositoryArgs = {
  name: Scalars['String'];
};

/** Common properties between an `OrgAccount` and `UserAccount` */
export type AccountSpacesArgs = {
  spaceType?: InputMaybe<SpaceType>;
};

/** An `AccountControlPlane` */
export type AccountControlPlane = {
  __typename?: 'AccountControlPlane';
  /** The `ControlPlane` */
  controlPlane: ControlPlane;
  /** The `ControlPlanePermission` for this `ControlPlane` */
  permission: ControlPlanePermission;
  /** The `ControlPlaneStatus` for this `ControlPlane` */
  status: ControlPlaneStatus;
};

/** Result type for `Repositories` on an `Account` */
export type AccountRepositoriesResult = {
  __typename?: 'AccountRepositoriesResult';
  /** The total count of `Repositories` */
  count: Scalars['Int'];
  /** The `Repositories` */
  nodes: Array<Repository>;
  /** The current page */
  page: Scalars['Int'];
  /** The number of `Repositories` in a page */
  size: Scalars['Int'];
};

/** The payload for adding a `Robot` to a `Team` */
export type AddTeamRobotPayload = {
  /** The `id` of the `Robot` to add to the `Team` */
  robotId: Scalars['ID'];
  /** The `id` of the `Team` to add the `Robot` to */
  teamId: Scalars['ID'];
};

/** `Mutation`s related to 2fa */
export type AuthMutation = {
  __typename?: 'AuthMutation';
  /** Delete a FIDO token */
  deleteFidoKey?: Maybe<FidoKey>;
  /** Delete a TOTP app */
  deleteTotpApp?: Maybe<TotpApplication>;
  /** Verify FIDO enrollment challenge */
  enrollFidoChallenge: Scalars['String'];
  /** Verify TOTP enrollment challenge */
  enrollTotpChallenge: Scalars['String'];
  /** Update a FIDO token */
  updateFidoKey?: Maybe<FidoKey>;
  /** Update a TOTP app */
  updateTotpApp?: Maybe<TotpApplication>;
};

/** `Mutation`s related to 2fa */
export type AuthMutationDeleteFidoKeyArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s related to 2fa */
export type AuthMutationDeleteTotpAppArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s related to 2fa */
export type AuthMutationEnrollFidoChallengeArgs = {
  payload: FidoEnrollPayload;
};

/** `Mutation`s related to 2fa */
export type AuthMutationEnrollTotpChallengeArgs = {
  code: Scalars['String'];
};

/** `Mutation`s related to 2fa */
export type AuthMutationUpdateFidoKeyArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** `Mutation`s related to 2fa */
export type AuthMutationUpdateTotpAppArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** `Query`s related to 2fa */
export type AuthQuery = {
  __typename?: 'AuthQuery';
  /** Enroll in 2fa via FIDO */
  enrollFido: FidoChallenge;
  /** Enroll in 2fa via TOTP */
  enrollTotp: Scalars['String'];
  /** Get all registered 2fa data for a `User` */
  get2fa: TwoFactorKeys;
};

/** Common properties between a `CurrentUser` and `User` */
export type BaseUser = {
  /** A short description for this `BaseUser` */
  biography?: Maybe<Scalars['String']>;
  /** The `Timestamp` when this `BaseUser` was created */
  createdAt: Scalars['Timestamp'];
  /** The `Timestamp` when this `BaseUser` was triggered for deletion */
  deletedAt?: Maybe<Scalars['Timestamp']>;
  /** The email for this `BaseUser` */
  email: Scalars['String'];
  /** The first name for this `BaseUser` */
  firstName?: Maybe<Scalars['String']>;
  /** The unique id for this `BaseUser` */
  id: Scalars['ID'];
  /** The last name for this `BaseUser` */
  lastName?: Maybe<Scalars['String']>;
  /** The location for this `BaseUser` */
  location?: Maybe<Scalars['String']>;
  /** The auth `Token`s for this `BaseUser` */
  tokens: Array<Token>;
  /** The `Timestamp` when this `BaseUser` was last updated */
  updatedAt?: Maybe<Scalars['Timestamp']>;
  /** The username for this `BaseUser` */
  username: Scalars['String'];
};

/** The payload for changing the password for the `CurrentUser` */
export type ChangePasswordPayload = {
  /** The current password for the `CurrentUser` */
  currentPassword: Scalars['String'];
  /** The old password for the `CurrentUser` */
  password: Scalars['String'];
};

/** A `CompositeResourceClaim` and its `CustomResourceDefinition` as well as calculated `additionalPrinterColumns` */
export type ClaimInstanceDetails = {
  __typename?: 'ClaimInstanceDetails';
  /** calculated `additionalPrinterColumns` */
  additionalPrinterColumns: Array<PrinterColumn>;
  /** The `CustomResourceDefinition` for the `CompositeResourceClaim` */
  crd: CustomResourceDefinition;
  /** A `CompositeResourceClaim` */
  instance: CompositeResourceClaim;
};

/** The payload to remove a `Repository` from a `Team` */
export type ClearTeamControlPlanePermissionPayload = {
  /** The `id` of the `ControlPlane` to set the permission on the `Team` */
  controlPlaneId: Scalars['ID'];
  /** The `id` of the `Team` to set the `ControlPlane` permission on */
  teamId: Scalars['ID'];
};

/** The payload for remove a `Repository` from a `Team` */
export type ClearTeamRepositoryPermissionPayload = {
  /** The `id` of the `Repository` to set the permission on the `Team` */
  repositoryId: Scalars['ID'];
  /** The `id` of the `Team` to set the `Repository` permission on */
  teamId: Scalars['ID'];
};

/** The payload to remove a `User` from a `Team` */
export type ClearTeamUserPermissionPayload = {
  /** The `id` of the `Team` to remove the `User` from */
  teamId: Scalars['ID'];
  /** The `id` of the `User` to remove from the `Team` */
  userId: Scalars['ID'];
};

/**
 * A CompositeResource is a resource this is reconciled by composing other
 * composite or managed resources. Composite resources use a Composition to
 * determine which resources to compose, and how.
 */
export type CompositeResource = KubernetesResource &
  Node & {
    __typename?: 'CompositeResource';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The definition of this resource. */
    definition?: Maybe<CompositeResourceDefinition>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The Ready status for this `CompositeResource`. Ready means that there is a `StatusCondition` of type `Ready` that has a status of `True`. */
    ready?: Maybe<Scalars['Boolean']>;
    /** The desired state of this resource. */
    spec: CompositeResourceSpec;
    /** The observed state of this resource. */
    status?: Maybe<CompositeResourceStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A CompositeResourceClaim is a namespaced proxy for a composite resource. */
export type CompositeResourceClaim = KubernetesResource &
  Node & {
    __typename?: 'CompositeResourceClaim';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The definition of this resource. */
    definition?: Maybe<CompositeResourceDefinition>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The Ready status for this `CompositeResourceClaim`. Ready means that there is a `StatusCondition` of type `Ready` that has a status of `True`. */
    ready?: Maybe<Scalars['Boolean']>;
    /** The desired state of this resource. */
    spec: CompositeResourceClaimSpec;
    /** The observed state of this resource. */
    status?: Maybe<CompositeResourceClaimStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/**
 * A CompositeResourceConnection represents a connection to composite resource
 * claims.
 */
export type CompositeResourceClaimConnection = {
  __typename?: 'CompositeResourceClaimConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<CompositeResourceClaim>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * CompositeResourceConnectionDetails represents the observed status of a composite
 * resource claim's connection details.
 */
export type CompositeResourceClaimConnectionDetails = {
  __typename?: 'CompositeResourceClaimConnectionDetails';
  /**
   * The time at which the composite resource claim's connection details were last
   * published.
   */
  lastPublishedTime?: Maybe<Scalars['Time']>;
};

/**
 * A CompositeResourceClaimSpec represents the desired state of a composite
 * resource claim.
 */
export type CompositeResourceClaimSpec = {
  __typename?: 'CompositeResourceClaimSpec';
  /** The composition this composite resource claim uses to compose resources. */
  composition?: Maybe<Composition>;
  /** The compositionRef this composite resource claim uses to compose resources. */
  compositionRef?: Maybe<LocalObjectReference>;
  /**
   * A composition selector is used to select this composite resource claims's
   * (composite resource's) composition by matching on labels.
   */
  compositionSelector?: Maybe<LabelSelector>;
  /** The secret this composite resource claim writes its connection details to. */
  connectionSecret?: Maybe<Secret>;
  /** The composite resource to which this composite resource claim is bound. */
  resource?: Maybe<CompositeResource>;
  /** A reference to the composite resource to which this composite resource claim is bound. */
  resourceRef?: Maybe<ObjectReference>;
  /** Reference to the secret this composite resource writes its connection details to */
  writeConnectionSecretToReference?: Maybe<SecretReference>;
};

/**
 * A CompositeResourceClaimStatus represents the observed status of a composite
 * resource claim.
 */
export type CompositeResourceClaimStatus = ConditionedStatus & {
  __typename?: 'CompositeResourceClaimStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /** The status of this composite resource's connection details. */
  connectionDetails?: Maybe<CompositeResourceClaimConnectionDetails>;
};

/** A CompositeResourceConnection represents a connection to composite resources. */
export type CompositeResourceConnection = {
  __typename?: 'CompositeResourceConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<CompositeResource>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * CompositeResourceConnectionDetails represents the observed status of a composite
 * resource's connection details.
 */
export type CompositeResourceConnectionDetails = {
  __typename?: 'CompositeResourceConnectionDetails';
  /**
   * The time at which the composite resource's connection details were last
   * published.
   */
  lastPublishedTime?: Maybe<Scalars['Time']>;
};

/**
 * A CompositeResourceDefinition (or XRD) defines a new kind of resource. The new
 * resource is composed of other composite or managed resources.
 */
export type CompositeResourceDefinition = KubernetesResource &
  Node & {
    __typename?: 'CompositeResourceDefinition';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The human/short name of the `CompositeResourceDefinition`s claim */
    claimName?: Maybe<Scalars['String']>;
    /** The generated `CustomResourceDefinition` for this XRD */
    compositeResourceCRD?: Maybe<CustomResourceDefinition>;
    /** The generated `CustomResourceDefinition` of this XRDs `CompositeClaim` if defined */
    compositeResourceClaimCRD?: Maybe<CustomResourceDefinition>;
    /** Composite resource claims (XRCs) defined by this XRD. */
    definedCompositeResourceClaims: CompositeResourceClaimConnection;
    /** Composite resources (XRs) defined by this XRD. */
    definedCompositeResources: CompositeResourceConnection;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** The name of the group this `CompositeResourceDefinition` is a part of */
    groupName: Scalars['String'];
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The human/short name of the `CompositeResourceDefinition` */
    name: Scalars['String'];
    /** The package this `CompositeResourceDefinition` comes from if it belongs to a package */
    package?: Maybe<Configuration>;
    /** The desired state of this resource. */
    spec: CompositeResourceDefinitionSpec;
    /** The observed state of this resource. */
    status?: Maybe<CompositeResourceDefinitionStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/**
 * A CompositeResourceDefinition (or XRD) defines a new kind of resource. The new
 * resource is composed of other composite or managed resources.
 */
export type CompositeResourceDefinitionDefinedCompositeResourceClaimsArgs = {
  namespace?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<DefinedCompositeResourceClaimOptionsInput>;
  version?: InputMaybe<Scalars['String']>;
};

/**
 * A CompositeResourceDefinition (or XRD) defines a new kind of resource. The new
 * resource is composed of other composite or managed resources.
 */
export type CompositeResourceDefinitionDefinedCompositeResourcesArgs = {
  options?: InputMaybe<DefinedCompositeResourceOptionsInput>;
  version?: InputMaybe<Scalars['String']>;
};

/**
 * A CompositeResourceDefinitionConnection represents a connection to composite
 * resource definitions (XRDs).
 */
export type CompositeResourceDefinitionConnection = {
  __typename?: 'CompositeResourceDefinitionConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<CompositeResourceDefinition>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * A CompositeResourceDefinitionControllerStatus shows the observed state of the
 * controllers that power the definition.
 */
export type CompositeResourceDefinitionControllerStatus = {
  __typename?: 'CompositeResourceDefinitionControllerStatus';
  /**
   * The CompositeResourceClaimTypeRef is the type of composite resource claim
   * that Crossplane is currently reconciling for this definition. Its version
   * will eventually become consistent with the definition's referenceable version.
   * Note that clients may interact with any served type; this is simply the type
   * that Crossplane interacts with.
   */
  compositeResourceClaimType?: Maybe<TypeReference>;
  /**
   * The CompositeResourceTypeRef is the type of composite resource that Crossplane
   * is currently reconciling for this definition. Its version will eventually
   * become consistent with the definition's referenceable version. Note that
   * clients may interact with any served type; this is simply the type that
   * Crossplane interacts with.
   */
  compositeResourceType?: Maybe<TypeReference>;
};

/**
 * CompositeResourceDefinitionNames specifies the resource and kind names of the
 * defined composite resource or claim.
 */
export type CompositeResourceDefinitionNames = {
  __typename?: 'CompositeResourceDefinitionNames';
  /**
   * A list of grouped resources this custom resource belongs to (e.g. 'all'). This
   * is published in API discovery documents, and used by clients to support
   * invocations like `kubectl get all`.
   */
  categories?: Maybe<Array<Scalars['String']>>;
  /** The Kubernetes API kind of the defined resource. */
  kind: Scalars['String'];
  /** The Kubernetes API kind of a list of the defined resource. */
  listKind?: Maybe<Scalars['String']>;
  /**
   * The plural name of the resource to serve. Composite resources are served by
   * the Kuberntes API under `/apis/<group>/<version>/.../<plural>`.
   */
  plural: Scalars['String'];
  /**
   * Short names for the resource, exposed in API discovery documents, and used by
   * clients to support invocations like `kubectl get <shortname>`.
   */
  shortNames?: Maybe<Array<Scalars['String']>>;
  /** The singular name of the resource. */
  singular?: Maybe<Scalars['String']>;
};

/**
 * A CompositeResourceDefinitionSpec represents the desired state of a composite
 * resource definition.
 */
export type CompositeResourceDefinitionSpec = {
  __typename?: 'CompositeResourceDefinitionSpec';
  /**
   * ClaimNames specifies the names of an optional composite resource claim. When
   * claim names are specified Crossplane will create a namespaced 'composite
   * resource claim' CRD that corresponds to the defined composite resource. This
   * composite resource claim acts as a namespaced proxy for the composite
   * resource; creating, updating, or deleting the claim will create, update, or
   * delete a corresponding composite resource. You may add claim names to an
   * existing CompositeResourceDefinition, but they cannot be changed or removed
   * once they have been set.
   */
  claimNames?: Maybe<CompositeResourceDefinitionNames>;
  /**
   * ConnectionSecretKeys is the list of keys that will be exposed to the end user
   *  of the defined kind.
   */
  connectionSecretKeys?: Maybe<Array<Scalars['String']>>;
  /**
   * DefaultComposition is the Composition resource that will be used in case no
   *  composition selector is given.
   */
  defaultComposition?: Maybe<Composition>;
  /**
   * EnforcedComposition is the Composition resource that will be used by all
   *  composite instances whose schema is defined by this definition.
   */
  enforcedComposition?: Maybe<Composition>;
  /**
   * Group specifies the API group of the defined composite resource. Composite
   * resources are served under `/apis/<group>/...`. Must match the name of the XRD
   * (in the form `<names.plural>.<group>`).
   */
  group: Scalars['String'];
  /** Names specifies the resource and kind names of the defined composite resource. */
  names: CompositeResourceDefinitionNames;
  /**
   * Versions is the list of all API versions of the defined composite resource.
   * Version names are used to compute the order in which served versions are
   * listed in API discovery. If the version string is "kube-like", it will sort
   * above non "kube-like" version strings, which are ordered lexicographically.
   * "Kube-like" versions start with a "v", then are followed by a number (the
   * major version), then optionally the string "alpha" or "beta" and another
   * number (the minor version). These are sorted first by GA > beta > alpha (where
   * GA is a version with no suffix such as beta or alpha), and then by comparing
   * major version, then minor version. An example sorted list of versions: v10,
   * v2, v1, v11beta2, v10beta3, v3beta1, v12alpha1, v11alpha2, foo1, foo10. Note
   * that all versions must have identical schemas; Crossplane does not currently
   * support conversion between different version schemas.
   */
  versions?: Maybe<Array<CompositeResourceDefinitionVersion>>;
};

/**
 * A CompositeResourceDefinitionStatus represents the observed state of a composite
 * resource definition.
 */
export type CompositeResourceDefinitionStatus = ConditionedStatus & {
  __typename?: 'CompositeResourceDefinitionStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /**
   * Controllers represents the status of the controllers that power this
   * composite resource definition.
   */
  controllers?: Maybe<CompositeResourceDefinitionControllerStatus>;
};

/**
 * A CompositeResourceDefinitionVersion describes a version of a composite
 * resource.
 */
export type CompositeResourceDefinitionVersion = {
  __typename?: 'CompositeResourceDefinitionVersion';
  /**
   * Name of this version, e.g. “v1”, “v2beta1”, etc. Composite resources are
   * served under this version at `/apis/<group>/<version>/...` if `served` is
   * true.
   */
  name: Scalars['String'];
  /**
   * Referenceable specifies that this version may be referenced by a Composition
   * in order to configure which resources an XR may be composed of. Exactly one
   * version must be marked as referenceable; all Compositions must target only the
   * referenceable version. The referenceable version must be served.
   */
  referenceable: Scalars['Boolean'];
  /**
   * Schema describes the schema used for validation, pruning, and defaulting of
   * this version of the defined composite resource. Fields required by all
   * composite resources are injected into this schema automatically, and override
   * equivalently named fields in this schema.
   */
  schema?: Maybe<CompositeResourceValidation>;
  /** Served specifies that this version should be served via Kubernetes REST APIs. */
  served: Scalars['Boolean'];
};

/** A CompositeResourceSpec represents the desired state of a composite resource. */
export type CompositeResourceSpec = {
  __typename?: 'CompositeResourceSpec';
  /** The composite resource claim that claims this composite resource. */
  claim?: Maybe<CompositeResourceClaim>;
  /** The `ObjectReference` for the composite resource claim that claims this composite resource */
  claimRef?: Maybe<ObjectReference>;
  /** The composition this composite resource uses to compose resources. */
  composition?: Maybe<Composition>;
  /** The compositionRef this composite resource uses to compose resources. */
  compositionRef?: Maybe<LocalObjectReference>;
  /**
   * A composition selector is used to select this composite resource's composition
   * by matching on labels.
   */
  compositionSelector?: Maybe<LabelSelector>;
  /** The secret this composite resource writes its connection details to. */
  connectionSecret?: Maybe<Secret>;
  /** The resources of which this composite resource is composed. */
  resources?: Maybe<KubernetesResourceConnection>;
  /** Reference to the secret this composite resource writes its connection details to */
  writeConnectionSecretToReference?: Maybe<SecretReference>;
};

/**
 * A CompositeResourceClaimStatus represents the observed state of a composite
 * resource.
 */
export type CompositeResourceStatus = ConditionedStatus & {
  __typename?: 'CompositeResourceStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /** The status of this composite resource's connection details. */
  connectionDetails?: Maybe<CompositeResourceConnectionDetails>;
};

/**
 * A CompositeResourceValidation is a list of validation methods for a composite
 * resource.
 */
export type CompositeResourceValidation = {
  __typename?: 'CompositeResourceValidation';
  /** OpenAPIV3Schema is the OpenAPI v3 schema to use for validation and pruning. */
  openAPIV3Schema?: Maybe<Scalars['JSON']>;
};

/**
 * A Composition defines the group of resources to be created when a compatible
 * type of composite resource is created with reference to the composition.
 */
export type Composition = KubernetesResource &
  Node & {
    __typename?: 'Composition';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The desired state of this resource. */
    spec: CompositionSpec;
    /** The observed state of this resource. */
    status?: Maybe<CompositionStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A CompositionConnection represents a connection to compositions. */
export type CompositionConnection = {
  __typename?: 'CompositionConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<Composition>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** A CompositionSpec represents the desired state of a composition. */
export type CompositionSpec = {
  __typename?: 'CompositionSpec';
  /**
   * CompositeTypeRef specifies the type of composite resource that this
   * composition is compatible with.
   */
  compositeTypeRef: TypeReference;
  /**
   * WriteConnectionSecretsToNamespace specifies the namespace in which the
   * connection secrets of composite resource dynamically provisioned using this
   * composition will be created.
   */
  writeConnectionSecretsToNamespace?: Maybe<Scalars['String']>;
};

/** A CompositionStatus represents the observed state of a composition. */
export type CompositionStatus = ConditionedStatus & {
  __typename?: 'CompositionStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
};

/**
 * A condition that may apply to a resource.
 *
 * Note that type and reason are intentionally not enums; Crossplane does not limit
 * the allowed values at the API level.
 */
export type Condition = {
  __typename?: 'Condition';
  /**
   * LastTransitionTime is the last time this condition transitioned from one
   * status to another.
   */
  lastTransitionTime: Scalars['Time'];
  /**
   * A Message containing details about this condition's last transition from one
   * status to another, if any.
   */
  message?: Maybe<Scalars['String']>;
  /** A Reason for this condition's last transition from one status to another. */
  reason: Scalars['String'];
  /** Status of this condition; is it currently True, False, or Unknown? */
  status: ConditionStatus;
  /**
   * Type of this condition. At most one of each condition type may apply to a
   * resource at any point in time.
   */
  type: Scalars['String'];
};

/** A ConditionStatus represensts the status of a condition. */
export enum ConditionStatus {
  /** The condition is false. */
  False = 'FALSE',
  /** The condition is true. */
  True = 'TRUE',
  /** The status of the condition is unknown. */
  Unknown = 'UNKNOWN',
}

/**
 * A ConditionedStatus represents the observed state of a Kubernetes resource that
 * exposes status conditions.
 */
export type ConditionedStatus = {
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
};

/** A ConfigMap holds configuration data. */
export type ConfigMap = KubernetesResource &
  Node & {
    __typename?: 'ConfigMap';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The data stored in this config map. */
    data?: Maybe<Scalars['StringMap']>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A ConfigMap holds configuration data. */
export type ConfigMapDataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/** A Configuration extends Crossplane with support for new composite resources. */
export type Configuration = KubernetesResource &
  Node & {
    __typename?: 'Configuration';
    /** The active revision of this configuration. */
    activeRevision?: Maybe<ConfigurationRevision>;
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Calculated `PackageStatus` of the `Configuration` */
    calculatedStatus: PackageStatus;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** The icon for this `Configuration` */
    icon?: Maybe<Scalars['String']>;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The human/short name of the `Configuration` */
    name: Scalars['String'];
    /** The `CompositeResourceDefinition` that this `Configuration` contains */
    resourceDefinitions?: Maybe<Array<CompositeResourceDefinition>>;
    /** Revisions of this configuration. */
    revisions: ConfigurationRevisionConnection;
    /** The desired state of this resource. */
    spec: ConfigurationSpec;
    /** The observed state of this resource. */
    status?: Maybe<ConfigurationStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
    /** The version of the `Configuration` */
    version: Scalars['String'];
  };

/** A ConfigurationConnection represents a connection to configurations. */
export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<Configuration>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** Either a `Configuration` or a `Provider` */
export type ConfigurationOrProvider = Configuration | Provider;

/** A ConfigurationRevision represents a revision or 'version' of a configuration. */
export type ConfigurationRevision = KubernetesResource &
  Node & {
    __typename?: 'ConfigurationRevision';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The desired state of this resource. */
    spec: ConfigurationRevisionSpec;
    /** The observed state of this resource. */
    status?: Maybe<ConfigurationRevisionStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/**
 * A ConfigurationRevisionConnection represents a connection to configuration
 * revisions.
 */
export type ConfigurationRevisionConnection = {
  __typename?: 'ConfigurationRevisionConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<ConfigurationRevision>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * A ConfigurationRevisionSpec represents the desired state of a configuration
 * revision.
 */
export type ConfigurationRevisionSpec = {
  __typename?: 'ConfigurationRevisionSpec';
  /** Desired state of the configuration revision. */
  desiredState: PackageRevisionDesiredState;
  /**
   * IgnoreCrossplaneConstraints indicates to the package manager whether to honor
   * Crossplane version constrains specified by the package.
   */
  ignoreCrossplaneConstraints?: Maybe<Scalars['Boolean']>;
  /** Package image used by the install pod to extract package contents. */
  package: Scalars['String'];
  /** PackagePullPolicy defines the pull policy for the package.. */
  packagePullPolicy?: Maybe<PackagePullPolicy>;
  /**
   * Revision number. Indicates when the revision will be garbage collected based
   * on the configuration's RevisionHistoryLimit.
   */
  revision: Scalars['Int'];
  /**
   * SkipDependencyResolution indicates to the package manager whether to skip
   * resolving dependencies for a package.
   */
  skipDependencyResolution?: Maybe<Scalars['Boolean']>;
};

/**
 * A ConfigurationRevisionStatus represents the observed state of a configuration
 * revision.
 */
export type ConfigurationRevisionStatus = ConditionedStatus & {
  __typename?: 'ConfigurationRevisionStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /** The number of known dependencies. */
  foundDependencies?: Maybe<Scalars['Int']>;
  /** The number of installed dependencies. */
  installedDependencies?: Maybe<Scalars['Int']>;
  /** The number of invalid dependencies. */
  invalidDependencies?: Maybe<Scalars['Int']>;
  /**
   * Objects owned by this configuration revision - i.e. objects that were created
   * by this configuration revision or that would have been created if they did
   * not already exist.
   *
   * In practice these objects are currently always a CompositeResourceDefinition
   * or a Composition. Crossplane lints the content of configuration packages to
   * enforce this, but it's not enforced at the Kubernetes API level. We return an
   * array of KubernetesResource here because doing so allows us to package
   * different types in future without a breaking GraphQL schema change.
   */
  objects: KubernetesResourceConnection;
  /** Permissions requested by this configuration revision. */
  permissionRequests?: Maybe<Array<PolicyRule>>;
};

/** A ConfigurationSpec represents the desired state of a configuration. */
export type ConfigurationSpec = {
  __typename?: 'ConfigurationSpec';
  /**
   * IgnoreCrossplaneConstraints indicates to the package manager whether to honor
   * Crossplane version constraints specified by the package.
   */
  ignoreCrossplaneConstraints?: Maybe<Scalars['Boolean']>;
  /** The name of the configuration package to pull from an OCI registry. */
  package: Scalars['String'];
  /** PackagePullPolicy defines the pull policy for the package. */
  packagePullPolicy?: Maybe<PackagePullPolicy>;
  /**
   * RevisionActivationPolicy specifies how the package controller should update
   * from one revision to the next.
   */
  revisionActivationPolicy?: Maybe<RevisionActivationPolicy>;
  /**
   * RevisionHistoryLimit dictates how the package controller cleans up old
   * inactive package revisions. Defaults to 1. Can be disabled by explicitly
   * setting to 0.
   */
  revisionHistoryLimit?: Maybe<Scalars['Int']>;
  /**
   * SkipDependencyResolution indicates to the package manager whether to skip
   * resolving dependencies for a package.
   */
  skipDependencyResolution?: Maybe<Scalars['Boolean']>;
};

/** A ConfigurationRevisionStatus represents the observed state of a configuration. */
export type ConfigurationStatus = ConditionedStatus & {
  __typename?: 'ConfigurationStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /**
   * CurrentIdentifier is the most recent package source that was used to produce a
   * revision. The package manager uses this field to determine whether to check
   * for package updates for a given source when packagePullPolicy is set to
   * IfNotPresent.
   */
  currentIdentifier?: Maybe<Scalars['String']>;
  /**
   * CurrentRevision is the name of the current package revision. It will reflect
   * the most up to date revision, whether it has been activated or not.
   */
  currentRevision?: Maybe<Scalars['String']>;
};

/** A `ControlPlane` */
export type ControlPlane = {
  __typename?: 'ControlPlane';
  /** The `Timestamp` for when this `ControlPlane` was assigned */
  assignedAt?: Maybe<Scalars['Timestamp']>;
  /** The `ControlPlaneConfigurationInstallation` for this `ControlPlane` */
  controlPlaneConfigurationInstallation?: Maybe<ControlPlaneConfigurationInstallation>;
  /** The `Timestamp` when this `ControlPlane` was created */
  createdAt: Scalars['Timestamp'];
  /** The description for this `ControlPlane` */
  description?: Maybe<Scalars['String']>;
  /** The `Timestamp` when this `ControlPlane` will expire */
  expiresAt?: Maybe<Scalars['Timestamp']>;
  /** The unique id for this `ControlPlane` */
  id: Scalars['ID'];
  /** If this ControlPlane should be considered view only */
  isViewOnly: Scalars['Boolean'];
  /** The cluster ID, if known */
  kubeClusterId?: Maybe<Scalars['String']>;
  /** Fetch a `McpResource` by `id`. Note: Can be `NULL` if the MCP data stream is unhealthy. */
  mcpResource?: Maybe<McpResource>;
  /** Fetch a `McpResource`s by MCP `id` optionally with page, order, and filter options. Note: Can be `NULL` if the MCP data stream is unhealthy */
  mcpResources?: Maybe<McpResourceNode>;
  /** The display name for this `ControlPlane` */
  name: Scalars['String'];
  /** If the Control Plane is self-hosted */
  selfHosted?: Maybe<Scalars['Boolean']>;
};

/** A `ControlPlane` */
export type ControlPlaneMcpResourceArgs = {
  id: Scalars['ID'];
};

/** A `ControlPlane` */
export type ControlPlaneMcpResourcesArgs = {
  options?: InputMaybe<McpResourcesOptions>;
};

/** A Configuration that can be installed in an MCP (`ControlPlane`) */
export type ControlPlaneConfiguration = {
  __typename?: 'ControlPlaneConfiguration';
  /** The name of a git branch this new `ControlPlaneConfiguration` pulls from */
  branch: Scalars['String'];
  /** The context (e.g. GitHub account) of the repository the `ControlPlaneConfiguration` pulls from */
  context: Scalars['String'];
  /** A timestamp for when this `ControlPlaneConfiguration` was created */
  createdAt: Scalars['String'];
  /** The `ID` of the `User` that created this `ControlPlaneConfiguration` */
  creatorId: Scalars['ID'];
  /** The unique id for this `ControlPlaneConfiguration` */
  id: Scalars['ID'];
  /** The ControlPlane installations of this `ControlPlaneConfiguration` */
  installations: Array<ControlPlaneConfigurationInstallation>;
  /** The latest version of this `ControlPlaneConfiguration` */
  latestVersion?: Maybe<Scalars['String']>;
  /** The name of this `ControlPlaneConfiguration` */
  name: Scalars['String'];
  /** The `ControlPlaneConfigurationProvider` for this `ControlPlaneConfiguration` */
  provider: ControlPlaneConfigurationProvider;
  /** The name of the repository this `ControlPlaneConfiguration` pulls from */
  repositoryName: Scalars['String'];
  /** A timestamp for when this `ControlPlaneConfiguration` was last synced with its source repository */
  syncedAt?: Maybe<Scalars['String']>;
  /** The id of a template of this `ControlPlaneConfiguration` */
  templateId: Scalars['ID'];
  /** A timestamp for when this `ControlPlaneConfiguration` was last updated */
  updatedAt?: Maybe<Scalars['String']>;
};

/** The installation of a `ControlPlaneConfiguration` in a `ControlPlane` */
export type ControlPlaneConfigurationInstallation = {
  __typename?: 'ControlPlaneConfigurationInstallation';
  /** The connected `ControlPlaneConfiguration` */
  configuration: ControlPlaneConfiguration;
  /** The `ControlPlane` for this `ControlPlaneConfigurationInstallation` */
  controlPlane: ControlPlane;
  /** The current version of the `ControlPlaneConfiguration` applied in the `ControlPlane` */
  currentVersion?: Maybe<Scalars['String']>;
  /** The timestamp of when the `currentVersion` was installed */
  deployedAt?: Maybe<Scalars['String']>;
  /** The desired version to upgrade the `ControlPlaneConfiguration` to in the `ControlPlane` */
  desiredVersion?: Maybe<Scalars['String']>;
  /** The status of the connection */
  status: Scalars['String'];
};

/** A provider source for a `ControlPlaneConfiguration` */
export enum ControlPlaneConfigurationProvider {
  /** The GitHub provider */
  Github = 'GITHUB',
}

/** The failure response from `createControlPlane` that indicates that a limit was reached */
export type ControlPlaneLimitReached = {
  __typename?: 'ControlPlaneLimitReached';
  /** The `ControlPlaneLimitScope` that caused the limit */
  limitScope: ControlPlaneLimitScope;
  /** The error message */
  message: Scalars['String'];
};

/** The scope that caused a `ControlPlaneLimitReached` */
export enum ControlPlaneLimitScope {
  /** The account limit scope */
  Account = 'ACCOUNT',
  /** The global limit scope */
  Global = 'GLOBAL',
  /** The organization limit scope */
  Organization = 'ORGANIZATION',
  /** The user limit scope */
  User = 'USER',
}

/** The `ControlPlanePermission` of a `ControlPlane` */
export enum ControlPlanePermission {
  /** `EDIT` `ControlPlanePermission` */
  Editor = 'EDITOR',
  /** `NONE` `ControlPlanePermission` */
  None = 'NONE',
  /** `OWNER` `ControlPlanePermission` */
  Owner = 'OWNER',
  /** `VIEW` `ControlPlanePermission` */
  Viewer = 'VIEWER',
}

/** The `ControlPlaneStatus` of a `ControlPlane` */
export enum ControlPlaneStatus {
  /** `DELETING` `ControlPlaneStatus` */
  Deleting = 'DELETING',
  /** `PROVISIONING` `ControlPlaneStatus` */
  Provisioning = 'PROVISIONING',
  /** `READY` `ControlPlaneStatus` */
  Ready = 'READY',
  /** `UNREACHABLE` `ControlPlaneStatus` */
  Unreachable = 'UNREACHABLE',
  /** `UPDATING` `ControlPlaneStatus` */
  Updating = 'UPDATING',
}

/** The payload for creating an `ControlPlaneConfiguration` */
export type CreateControlPlaneConfigurationPayload = {
  /** The `Account` to create this `ControlPlaneConfiguration` in */
  account: Scalars['ID'];
  /** The context (e.g. GitHub account) of the repository this new `ControlPlaneConfiguration` will pull from */
  context: Scalars['String'];
  /** The name of this new `ControlPlaneConfiguration` */
  name: Scalars['String'];
  /** Whether the Git repository to clone to is private */
  private?: InputMaybe<Scalars['Boolean']>;
  /** The `ControlPlaneConfigurationProvider` for this new `ControlPlaneConfiguration` */
  provider: ControlPlaneConfigurationProvider;
  /** The name of a git repository this new `ControlPlaneConfiguration` will pull from */
  repositoryName: Scalars['String'];
  /** The id of a template to use to pre-populate the new repository with */
  templateId: Scalars['String'];
};

/** The payload to pass to `createControlPlane` */
export type CreateControlPlanePayload = {
  /** The account of this `ControlPlane` */
  account: Scalars['ID'];
  /** The `id` of an `ControlPlaneConfiguration` for this `ControlPlane`. Optional for a bare `ControlPlane` concept. */
  configurationId?: InputMaybe<Scalars['ID']>;
  /** The description of this `ControlPlane` */
  description?: InputMaybe<Scalars['String']>;
  /** The `name` of the `Group` to create the `ControlPlane` in. Optional for legacy space `ControlPlane`s. */
  groupName?: InputMaybe<Scalars['String']>;
  /** The display name of this `ControlPlane` */
  name: Scalars['String'];
  /** The `name` of the `Space` to create the `ControlPlane` in. Optional for legacy space `ControlPlane`s. */
  spaceName?: InputMaybe<Scalars['String']>;
};

/** The response from `createControlPlane` */
export type CreateControlPlaneResponse = AccountControlPlane | ControlPlaneLimitReached;

/**
 * CreateKubernetesResourceInput is the input required to create a Kubernetes
 * resource.
 */
export type CreateKubernetesResourceInput = {
  /** Patches that should be applied to the Kubernetes resource before creation. */
  patches?: InputMaybe<Array<Patch>>;
  /** The Kubernetes resource to be created, as raw JSON. */
  unstructured: Scalars['JSON'];
};

/** CreateKubernetesResourcePayload is the result of creating a Kubernetes resource. */
export type CreateKubernetesResourcePayload = {
  __typename?: 'CreateKubernetesResourcePayload';
  /** The created Kubernetes resource. Null if the create failed. */
  resource?: Maybe<KubernetesResource>;
};

/** The payload of `createOrUpdateRepository` */
export type CreateOrUpdateRepositoryPayload = {
  /** The `Account` name of the `Repository` to create or update */
  account: Scalars['String'];
  /** If the new or updated `Repository` should be public */
  isPublic: Scalars['Boolean'];
  /** The name of the `Repository` to create or update */
  repository: Scalars['String'];
};

/** The payload for creating an `OrgInvite` */
export type CreateOrgInvitePayload = {
  /** The email of the `OrgInvite` */
  email: Scalars['String'];
  /** The permission to create the `OrgInvite` with */
  permission: OrganizationPermission;
};

/** The payload for creating a `Organization` */
export type CreateOrganizationPayload = {
  /** The display name of the `Organization` to create */
  displayName: Scalars['String'];
  /** The name of the `Organization` to create */
  name: Scalars['String'];
};

/** The payload to pass to `createRobot` */
export type CreateRobotPayload = {
  /** The description of this `Robot` */
  description?: Scalars['String'];
  /** The name of this `Robot` */
  name: Scalars['String'];
  /** The `id` of the `Organization` to create the `Robot` in */
  organizationId: Scalars['ID'];
};

/** The payload for creating a `Team` */
export type CreateTeamPayload = {
  /** The name of the `Team` */
  name: Scalars['String'];
  /** The `Organization` `id` this team should be created in */
  organizationId: Scalars['ID'];
};

/** The payload for creating a `Token` */
export type CreateTokenPayload = {
  /** The name for the new `Token` */
  name: Scalars['String'];
  /** The `id` of the owner for the new `Token` */
  ownerId: Scalars['ID'];
  /** The type of the owner of the new `Token` */
  type: TokenType;
};

export type Crossplane = {
  __typename?: 'Crossplane';
  /** The `CustomResourceDefinition`s for CompositeResource Claims */
  claimCRDs: Array<CustomResourceDefinition>;
  /** Get a `ClaimInstanceDetails` by `id` and `crdId` */
  claimInstanceDetails: ClaimInstanceDetails;
  /** Composite Resource Definitions (XRDs) that currently exist. */
  compositeResourceDefinitions: CompositeResourceDefinitionConnection;
  /** Compositions that currently exist. */
  compositions: CompositionConnection;
  /** A Kubernetes config map. */
  configMap?: Maybe<ConfigMap>;
  /** Configuration revisions that currently exist. */
  configurationRevisions: ConfigurationRevisionConnection;
  /** Configurations that are currently installed. */
  configurations: ConfigurationConnection;
  /** Versions of components in the Upbound Distribution */
  crossplaneInfo?: Maybe<CrossplaneInfo>;
  /**
   * Get an `KubernetesResource` and its descendants which form a tree. The two
   * `KubernetesResource`s that have descendants are `CompositeResourceClaim` (its
   * `CompositeResource`) and `CompositeResource` (the `KubernetesResource`s it
   * composes via a `Composition`).
   */
  crossplaneResourceTree: CrossplaneResourceTreeConnection;
  /** Custom Resource Definitions (CRDs) that currently exist. */
  customResourceDefinitions: CustomResourceDefinitionConnection;
  /** Kubernetes events. */
  events: EventConnection;
  /** The `id` of the `Crossplane`/`ControlPlane` */
  id: Scalars['ID'];
  /**
   * An arbitrary Kubernetes resource. Types that are known to xgql will be
   * returned appropriately (e.g. a Crossplane provider will be of the GraphQL
   * Provider type). Types that are not known to xgql will be returned as a
   * GenericResource.
   */
  kubernetesResource?: Maybe<KubernetesResource>;
  /**
   * All extant Kubernetes resources of an arbitrary type. Types that are known to
   * xgql will be returned appropriately (e.g. a Crossplane provider will be of the
   * GraphQL Provider type). Types that are not known to xgql will be returned as a
   * GenericResource.
   */
  kubernetesResources: KubernetesResourceConnection;
  /** Provider revisions that currently exist. */
  providerRevisions: ProviderRevisionConnection;
  /** Providers that are currently installed. */
  providers: ProviderConnection;
  /** Get a `ResourceInstanceDetails` by `id` and `crdId` */
  resourceInstanceDetails: ResourceInstanceDetails;
  /** A Kubernetes secret. */
  secret?: Maybe<Secret>;
};

export type CrossplaneClaimInstanceDetailsArgs = {
  crdId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CrossplaneCompositeResourceDefinitionsArgs = {
  dangling?: InputMaybe<Scalars['Boolean']>;
  revision?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneCompositionsArgs = {
  dangling?: InputMaybe<Scalars['Boolean']>;
  revision?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneConfigMapArgs = {
  name: Scalars['String'];
  namespace: Scalars['String'];
};

export type CrossplaneConfigurationRevisionsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  configuration?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneCrossplaneResourceTreeArgs = {
  id: Scalars['ID'];
};

export type CrossplaneCustomResourceDefinitionsArgs = {
  revision?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneEventsArgs = {
  involved?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneKubernetesResourceArgs = {
  id: Scalars['ID'];
};

export type CrossplaneKubernetesResourcesArgs = {
  apiVersion: Scalars['String'];
  kind: Scalars['String'];
  listKind?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Scalars['String']>;
};

export type CrossplaneProviderRevisionsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['ID']>;
};

export type CrossplaneResourceInstanceDetailsArgs = {
  crdId: Scalars['ID'];
  id: Scalars['ID'];
};

export type CrossplaneSecretArgs = {
  name: Scalars['String'];
  namespace: Scalars['String'];
};

/** Versions of components in the Upbound Distribution */
export type CrossplaneInfo = {
  __typename?: 'CrossplaneInfo';
  /** Version of the Upbound Agent component */
  agentVersion?: Maybe<Scalars['String']>;
  /** Version of the crossplane component */
  crossplaneVersion?: Maybe<Scalars['String']>;
  /** Version of the Xgql component */
  graphQLVersion?: Maybe<Scalars['String']>;
  /** Version of Universal Crossplane */
  uxpVersion?: Maybe<Scalars['String']>;
};

export type CrossplaneMutation = {
  __typename?: 'CrossplaneMutation';
  /** Create a Kubernetes resource. */
  createKubernetesResource: CreateKubernetesResourcePayload;
  /** Delete a Kubernetes resource. */
  deleteKubernetesResource: DeleteKubernetesResourcePayload;
  /** The `id` of the `Crossplane`/`ControlPlane` */
  id: Scalars['ID'];
  /** Update a Kubernetes resource. */
  updateKubernetesResource: UpdateKubernetesResourcePayload;
};

export type CrossplaneMutationCreateKubernetesResourceArgs = {
  input: CreateKubernetesResourceInput;
};

export type CrossplaneMutationDeleteKubernetesResourceArgs = {
  id: Scalars['ID'];
};

export type CrossplaneMutationUpdateKubernetesResourceArgs = {
  id: Scalars['ID'];
  input: UpdateKubernetesResourceInput;
};

/** A `CrossplaneResourceTreeConnection` represents a connection to `CrossplaneResourceTreeNode`s */
export type CrossplaneResourceTreeConnection = {
  __typename?: 'CrossplaneResourceTreeConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<CrossplaneResourceTreeNode>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * An `CrossplaneResourceTreeNode` is an `KubernetesResource` with a `ID` of its parent
 * `CrossplaneResource`.
 *
 * Note: A `NULL` `parentId` represents the root of the descendant tree.
 */
export type CrossplaneResourceTreeNode = {
  __typename?: 'CrossplaneResourceTreeNode';
  /** The `ID` of the parent `KubernetesResource` (`NULL` is the root of the tree) */
  parentId?: Maybe<Scalars['ID']>;
  /** The `KubernetesResource` object of this `CrossplaneResourceTreeNode` */
  resource: KubernetesResource;
};

/** The `CurrentUser` */
export type CurrentUser = BaseUser & {
  __typename?: 'CurrentUser';
  /** A short description for this `CurrentUser` */
  biography?: Maybe<Scalars['String']>;
  /** The `Timestamp` when this `CurrentUser` was created */
  createdAt: Scalars['Timestamp'];
  /** The `Timestamp` when this `CurrentUser` was triggered for deletion */
  deletedAt?: Maybe<Scalars['Timestamp']>;
  /** The email for this `CurrentUser` */
  email: Scalars['String'];
  /** The list of features a `User` has */
  features: Array<Scalars['String']>;
  /** The first name for this `CurrentUser` */
  firstName?: Maybe<Scalars['String']>;
  /** The unique id for this `CurrentUser` */
  id: Scalars['ID'];
  /** The last name for this `CurrentUser` */
  lastName?: Maybe<Scalars['String']>;
  /** The location for this `CurrentUser` */
  location?: Maybe<Scalars['String']>;
  /** The `LoginProvider`s of the `CurrentUser` */
  loginProviders: Array<LoginProvider>;
  /** The auth `Token`s for this `CurrentUser` */
  tokens: Array<Token>;
  /** The `Timestamp` when this `CurrentUser` was last updated */
  updatedAt?: Maybe<Scalars['Timestamp']>;
  /** The username for this `CurrentUser` */
  username: Scalars['String'];
};

/**
 * A CustomResourceDefinition defines a type of custom resource that extends the
 * set of resources supported by the Kubernetes API.
 */
export type CustomResourceDefinition = KubernetesResource &
  Node & {
    __typename?: 'CustomResourceDefinition';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Custom resources defined by this CRD */
    definedResources: KubernetesResourceConnection;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** The name of the group this `CustomResourceDefinition` is a part of */
    groupName: Scalars['String'];
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The human/short name of the `CustomResourceDefinition` */
    name: Scalars['String'];
    /** The package this `CustomResourceDefinition` comes from if it belongs to a package */
    package?: Maybe<ConfigurationOrProvider>;
    /** The type of resource instances of this `CustomResourceDefinition` are */
    resourceType: CustomResourceType;
    /** The desired state of this resource. */
    spec: CustomResourceDefinitionSpec;
    /** The observed state of this resource. */
    status?: Maybe<CustomResourceDefinitionStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
    /** The `CompositeResourceDefinition` that owns this `CustomResourceDefinition` if it is owned by one */
    xrd?: Maybe<CompositeResourceDefinition>;
  };

/**
 * A CustomResourceDefinition defines a type of custom resource that extends the
 * set of resources supported by the Kubernetes API.
 */
export type CustomResourceDefinitionDefinedResourcesArgs = {
  version?: InputMaybe<Scalars['String']>;
};

/**
 * A CustomResourceDefinitionConnection represents a connection to custom
 * resource definitions (CRDs).
 */
export type CustomResourceDefinitionConnection = {
  __typename?: 'CustomResourceDefinitionConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<CustomResourceDefinition>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * CustomResourceDefinitionNames specifies the resource and kind names of the
 * defined custom resource.
 */
export type CustomResourceDefinitionNames = {
  __typename?: 'CustomResourceDefinitionNames';
  /**
   * A list of grouped resources this custom resource belongs to (e.g. 'all'). This
   * is published in API discovery documents, and used by clients to support
   * invocations like `kubectl get all`.
   */
  categories?: Maybe<Array<Scalars['String']>>;
  /** The Kubernetes API kind of the defined resource. */
  kind: Scalars['String'];
  /** The Kubernetes API kind of a list of the defined resource. */
  listKind?: Maybe<Scalars['String']>;
  /**
   * The plural name of the resource to serve. Custom resources are served by
   *  the Kuberntes API under `/apis/<group>/<version>/.../<plural>`.
   */
  plural: Scalars['String'];
  /**
   * Short names for the resource, exposed in API discovery documents, and used by
   * clients to support invocations like `kubectl get <shortname>`.
   */
  shortNames?: Maybe<Array<Scalars['String']>>;
  /** The singular name of the resource. */
  singular?: Maybe<Scalars['String']>;
};

/**
 * A CustomResourceDefinitionSpec represents the desired state of a custom resource
 * definition.
 */
export type CustomResourceDefinitionSpec = {
  __typename?: 'CustomResourceDefinitionSpec';
  /**
   * Group specifies the API group of the defined custom resource. Custom resources
   * are served under `/apis/<group>/...`. Must match the name of the CRD (in the
   * form `<names.plural>.<group>`).
   */
  group: Scalars['String'];
  /** Names specifies the resource and kind names of the defined custom resource. */
  names: CustomResourceDefinitionNames;
  /** Scope of the defined custom resource. */
  scope: ResourceScope;
  /**
   * Versions is the list of all API versions of the defined custom resource.
   * Version names are used to compute the order in which served versions are
   * listed in API discovery. If the version string is "kube-like", it will sort
   * above non "kube-like" version strings, which are ordered lexicographically.
   * "Kube-like" versions start with a "v", then are followed by a number (the
   * major version), then optionally the string "alpha" or "beta" and another
   * number (the minor version). These are sorted first by GA > beta > alpha (where
   * GA is a version with no suffix such as beta or alpha), and then by comparing
   * major version, then minor version. An example sorted list of versions: v10,
   * v2, v1, v11beta2, v10beta3, v3beta1, v12alpha1, v11alpha2, foo1, foo10.
   */
  versions?: Maybe<Array<CustomResourceDefinitionVersion>>;
};

/**
 * A CustomResourceDefinitionStatus represents the observed state of a custom
 * resource definition.
 */
export type CustomResourceDefinitionStatus = ConditionedStatus & {
  __typename?: 'CustomResourceDefinitionStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
};

/** A CustomResourceDefinitionVersion describes a version of a custom resource. */
export type CustomResourceDefinitionVersion = {
  __typename?: 'CustomResourceDefinitionVersion';
  /**
   * Name of this version, e.g. “v1”, “v2beta1”, etc. Custom resources are served
   * under this version at `/apis/<group>/<version>/...` if `served` istrue.
   */
  name: Scalars['String'];
  /**
   * Schema describes the schema used for validation, pruning, and defaulting of
   * this version of the defined custom resource.
   */
  schema?: Maybe<CustomResourceValidation>;
  /** Served specifies that this version should be served via Kubernetes REST APIs. */
  served: Scalars['Boolean'];
};

/** The type of resource a CRD represents */
export enum CustomResourceType {
  /** A `CompositeResourceClaim` from a `Configuration` */
  Claim = 'CLAIM',
  /** A `CompositeResource` from a `Configuration` */
  Composite = 'COMPOSITE',
  /** A `ManagedResource` from a `Provider` */
  Managed = 'MANAGED',
  /** A `BasicResource` */
  Other = 'OTHER',
}

/**
 * A CustomResourceValidation is a list of validation methods for a custom
 * resource.
 */
export type CustomResourceValidation = {
  __typename?: 'CustomResourceValidation';
  /** OpenAPIV3Schema is the OpenAPI v3 schema to use for validation and pruning. */
  openAPIV3Schema?: Maybe<Scalars['JSON']>;
};

/** Options to filter or limit the defined composite claim resources */
export type DefinedCompositeResourceClaimOptionsInput = {
  /** Return resources in this namespace. */
  namespace?: InputMaybe<Scalars['String']>;
  /**
   * Optionally limit the results to XRCs.
   * If `true` return resources that have `Condition` `Ready` `True`.
   * If `false` return resources that have `Condition` `Ready` `False` or `Condition` `Ready` not present
   */
  ready?: InputMaybe<Scalars['Boolean']>;
  /** Return resources of this version. */
  version?: InputMaybe<Scalars['String']>;
};

/** Options to filter or limit the defined composite resources */
export type DefinedCompositeResourceOptionsInput = {
  /**
   * Optionally limit the results to XRCs.
   * If `true` return resources that have `Condition` `Ready` `True`.
   * If `false` return resources that have `Condition` `Ready` `False` or `Condition` `Ready` not present
   */
  ready?: InputMaybe<Scalars['Boolean']>;
  /** Return resources of this version. */
  version?: InputMaybe<Scalars['String']>;
};

/** DeleteKubernetesResourcePayload is the result of deleting a Kubernetes resource. */
export type DeleteKubernetesResourcePayload = {
  __typename?: 'DeleteKubernetesResourcePayload';
  /** The deleted Kubernetes resource. Null if the delete failed. */
  resource?: Maybe<KubernetesResource>;
};

/** The payload of `deleteRepository` */
export type DeleteRepositoryPayload = {
  /** The `Account` name of the `Repository` to delete` */
  account: Scalars['String'];
  /** The name of the `Repository` to delete */
  repository: Scalars['String'];
};

/** The payload of `deleteRepositoryVersion` */
export type DeleteRepositoryVersionPayload = {
  /** The `Account` name of the `RepositoryVersion` to delete` */
  account: Scalars['String'];
  /** The name of the `Repository` of the `RepositoryVersion` to delete */
  repository: Scalars['String'];
  /** The version of the `Repository` to delete */
  version: Scalars['String'];
};

/**
 * A DeletionPolicy specifies what will happen to the underlying external resource
 * when this managed resource is deleted - either "Delete" or "Orphan" the external
 * resource.
 */
export enum DeletionPolicy {
  /**
   * Delete the resource from the external system when the managed resource is
   * deleted.
   */
  Delete = 'DELETE',
  /**
   * Leave the resource in the external system when the managed resource is
   * deleted.
   */
  Orphan = 'ORPHAN',
}

/** An union to surface Resources as well as calculated ResourceInstanceDetails next to them */
export type DetailsResource = CompositeResource | CompositeResourceClaim | ManagedResource;

/** The payload for `disconnectLoginProvider` */
export type DisconnectLoginProviderPayload = {
  /** The `LoginProviderType` of the `LoginProvider` to disconnect */
  type: LoginProviderType;
};

/** An event pertaining to a Kubernetes resource. */
export type Event = Node & {
  __typename?: 'Event';
  /** The underlying Kubernetes API version of this resource. */
  apiVersion: Scalars['String'];
  /** The number of times this event has occurred. */
  count?: Maybe<Scalars['Int']>;
  /** The time at which this event was first recorded. */
  firstTime?: Maybe<Scalars['Time']>;
  /** An opaque identifier that is unique across all types. */
  id: Scalars['ID'];
  /** The Kubernetes resource this event pertains to. */
  involvedObject: KubernetesResource;
  /** The underlying Kubernetes API kind of this resource. */
  kind: Scalars['String'];
  /** The time at which this event was most recently recorded. */
  lastTime?: Maybe<Scalars['Time']>;
  /** Details about the event, if any. */
  message?: Maybe<Scalars['String']>;
  /** Metadata that is common to all Kubernetes API resources. */
  metadata: ObjectMeta;
  /** The reason the event was emitted. */
  reason?: Maybe<Scalars['String']>;
  /** The source of the event - e.g. a controller. */
  source?: Maybe<EventSource>;
  /** The type of event. */
  type?: Maybe<EventType>;
  /** An unstructured JSON representation of the event. */
  unstructured: Scalars['JSON'];
};

/** An EventConnection represents a connection to events. */
export type EventConnection = {
  __typename?: 'EventConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<Event>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/**
 * An EventSource is the source of an event. Note that in this context 'source'
 * indicates the software or system that emitted the event, not the Kubernetes
 * resource it pertains to.
 */
export type EventSource = {
  __typename?: 'EventSource';
  /** The software component that emitted the event. */
  component?: Maybe<Scalars['String']>;
};

/** An EventType indicates the type of an event. */
export enum EventType {
  /** A normal, informational event. */
  Normal = 'NORMAL',
  /** A warning that something suboptimal has occurred. */
  Warning = 'WARNING',
}

/** The `FidoChallenge` that is returned upon 2fa enrollment */
export type FidoChallenge = {
  __typename?: 'FidoChallenge';
  /** The ID of the app */
  appId: Scalars['String'];
  /** The requests for registration */
  registerRequests: Array<RegisterRequest>;
  /** List of registered FIDO keys */
  registeredKeys?: Maybe<Array<RegisteredKey>>;
};

/** The payload for `enrollFidoChallenge` */
export type FidoEnrollPayload = {
  /** Challenge string for 2fa registration with FIDO */
  challenge: Scalars['String'];
  /** Encrypted client data */
  clientData: Scalars['String'];
  /** Encrypted registration data */
  registrationData: Scalars['String'];
  /** U2F version */
  version: Scalars['String'];
};

/** A user's FIDO token */
export type FidoKey = {
  __typename?: 'FidoKey';
  /** Creation `Timestamp` for this token */
  createdAt: Scalars['String'];
  /** The `id` for this token */
  fidoId: Scalars['String'];
  /** The name of this token */
  name: Scalars['String'];
  /** `Timestamp` for when this token was last updated */
  updatedAt: Scalars['String'];
  /** The `User`'s `id` */
  userId: Scalars['String'];
};

/**
 * A GenericResource represents a kind of Kubernetes resource that does not
 * correspond to a kind or class of resources that is more specifically modelled
 * by xgql.
 */
export type GenericResource = KubernetesResource &
  Node & {
    __typename?: 'GenericResource';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A single `Highlight` in a `RegistrySearchResult` */
export type Highlight = {
  __typename?: 'Highlight';
  /** Array of strings that match search query under the context of `account` */
  account?: Maybe<Array<Scalars['String']>>;
  /** Array of strings that match search query under the context of `content` */
  content?: Maybe<Array<Scalars['String']>>;
  /** Array of strings that match search query under the context of `group` */
  group?: Maybe<Array<Scalars['String']>>;
  /** Array of strings that match search query under the context of `kind` */
  kind?: Maybe<Array<Scalars['String']>>;
  /** Array of strings that match search query under the context of `name` */
  name?: Maybe<Array<Scalars['String']>>;
  /** Array of strings that match search query under the context of `repository` */
  repository?: Maybe<Array<Scalars['String']>>;
};

/** Data on an Org Invite */
export type InviteData = {
  __typename?: 'InviteData';
  /** The email address this invite was sent to */
  email: Scalars['String'];
  /** The `Organization` `ID` of the invite */
  organizationId: Scalars['ID'];
  /** The `Organization` `name` of this invite */
  organizationName: Scalars['String'];
};

/** An object that corresponds to a Kubernetes API resource. */
export type KubernetesResource = {
  /** The underlying Kubernetes API version of this resource. */
  apiVersion: Scalars['String'];
  /** Events pertaining to this resource. */
  events: EventConnection;
  /** An opaque identifier that is unique across all types. */
  id: Scalars['ID'];
  /** The underlying Kubernetes API kind of this resource. */
  kind: Scalars['String'];
  /** Metadata that is common to all Kubernetes API resources. */
  metadata: ObjectMeta;
  /** An unstructured JSON representation of the underlying Kubernetes resource. */
  unstructured: Scalars['JSON'];
};

/** A KubernetesResourceConnection represents a connection to Kubernetes resources. */
export type KubernetesResourceConnection = {
  __typename?: 'KubernetesResourceConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<KubernetesResource>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** A LabelSelector matches a Kubernetes resource by labels. */
export type LabelSelector = {
  __typename?: 'LabelSelector';
  /** The labels to match on. */
  matchLabels?: Maybe<Scalars['StringMap']>;
};

/**
 * `LocalObjectReference` contains a name to to let you inspect or modify the
 * locally referred object.
 */
export type LocalObjectReference = {
  __typename?: 'LocalObjectReference';
  /** Name of the referent. */
  name: Scalars['String'];
};

/** A `LoginProvider` */
export type LoginProvider = {
  __typename?: 'LoginProvider';
  /** The name of the account of this `LoginProvider` */
  accountName: Scalars['String'];
  /** A unique id for this `LoginProvider` */
  id: Scalars['ID'];
  /** The status of this `LoginProvider` */
  status: LoginProviderStatus;
  /** The type of this `LoginProvider` */
  type: LoginProviderType;
};

/** The status of a `LoginProvider` */
export enum LoginProviderStatus {
  /** Active `LoginProviderStatus` */
  Active = 'ACTIVE',
  /** Inactive `LoginProviderStatus` */
  Inactive = 'INACTIVE',
}

/** The type of a login provider */
export enum LoginProviderType {
  /** Email `LoginProviderType` */
  Email = 'EMAIL',
  /** Github `LoginProviderType` */
  Github = 'GITHUB',
  /** Google `LoginProviderType` */
  Google = 'GOOGLE',
  /** SSO `LoginProviderType` */
  Sso = 'SSO',
}

/**
 * A ManagedResource is a Kubernetes API representation of a resource in an
 * external system, such as a cloud provider's API. Crossplane providers add
 * support for new kinds of managed resource.
 */
export type ManagedResource = KubernetesResource &
  Node & {
    __typename?: 'ManagedResource';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The definition of this resource. */
    definition?: Maybe<ManagedResourceDefinition>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The Ready status for this `ManagedResource`. Ready means that there is a `StatusCondition` of type `Ready` that has a status of `True`. */
    ready?: Maybe<Scalars['Boolean']>;
    /** The desired state of this resource. */
    spec: ManagedResourceSpec;
    /** The observed state of this resource. */
    status?: Maybe<ManagedResourceStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/**
 * A ManagedResourceDefinition defines a managed resource.
 *
 * At the time of writing a ManagedResourceDefinition will always be a
 * CustomResourceDefinition. We use a union because this may change in future per
 * https://github.com/crossplane/crossplane/issues/2262
 */
export type ManagedResourceDefinition = CustomResourceDefinition;

/** A ManagedResourceSpec represents the desired state of a managed resource. */
export type ManagedResourceSpec = {
  __typename?: 'ManagedResourceSpec';
  /** The secret this managed resource writes its connection details to. */
  connectionSecret?: Maybe<Secret>;
  /**
   * The deletion policy specifies what will happen to the underlying external
   * resource when this managed resource is deleted.
   */
  deletionPolicy?: Maybe<DeletionPolicy>;
  /**
   * The provider configuration configures how this managed resource interacts
   * with an external system.
   */
  providerConfigRef?: Maybe<ProviderConfigReference>;
};

/** A ManagedResourceStatus represents the observed state of a managed resource. */
export type ManagedResourceStatus = ConditionedStatus & {
  __typename?: 'ManagedResourceStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
};

/** The Claim type of an `McpResource` */
export type McpClaim = McpResource & {
  __typename?: 'McpClaim';
  /** The timestamp when this `McpClaim` was created */
  creationTimestamp: Scalars['Timestamp'];
  /** The api portion of the k8s apiVersion */
  group: Scalars['String'];
  /** The unique id of this `McpClaim` */
  id: Scalars['ID'];
  /** The kubernetes `kind` of this `McpClaim` */
  kind: Scalars['String'];
  /** The kubernetes `name` of this `McpClaim` */
  name: Scalars['String'];
  /** The kubernetes `namespace` of this `McpClaim` */
  namespace: Scalars['String'];
  /** The `id` of the parent (if any) of this this `McpClaim` */
  parentId?: Maybe<Scalars['ID']>;
  /** The `ready` state of this `McpClaim` */
  ready?: Maybe<Scalars['Boolean']>;
  /** The `syched` state of this `McpClaim` */
  synced?: Maybe<Scalars['Boolean']>;
  /** The version portion of the k8s apiVersion */
  version: Scalars['String'];
};

/** The Composite type of an `McpResource` */
export type McpComposite = McpResource & {
  __typename?: 'McpComposite';
  /** The timestamp when this `McpComposite` was created */
  creationTimestamp: Scalars['Timestamp'];
  /** The api portion of the k8s apiVersion */
  group: Scalars['String'];
  /** The unique id of this `McpComposite` */
  id: Scalars['ID'];
  /** The kubernetes `kind` of this `McpComposite` */
  kind: Scalars['String'];
  /** The kubernetes `name` of this `McpComposite` */
  name: Scalars['String'];
  /** The `id` of the parent (if any) of this this `McpComposite` */
  parentId?: Maybe<Scalars['ID']>;
  /** The ready state of this `McpComposite` */
  ready?: Maybe<Scalars['Boolean']>;
  /** The `syched` state of this `McpComposite` */
  synced?: Maybe<Scalars['Boolean']>;
  /** The version portion of the k8s apiVersion */
  version: Scalars['String'];
};

/** The Managed Resource type of an `McpResource` */
export type McpManagedResource = McpResource & {
  __typename?: 'McpManagedResource';
  /** The timestamp when this `McpManagedResource` was created */
  creationTimestamp: Scalars['Timestamp'];
  /** The api portion of the k8s apiVersion */
  group: Scalars['String'];
  /** The unique id of this `McpManagedResource` */
  id: Scalars['ID'];
  /** The kubernetes `kind` of this `McpManagedResource` */
  kind: Scalars['String'];
  /** The kubernetes `name` of this `McpManagedResource` */
  name: Scalars['String'];
  /** The `id` of the parent (if any) of this this `McpManagedResource` */
  parentId?: Maybe<Scalars['ID']>;
  /** The name of the provider config that is used by this `McpManagedResource` (if it has one) */
  providerConfigName?: Maybe<Scalars['String']>;
  /** The ready state of this `McpManagedResource` */
  ready?: Maybe<Scalars['Boolean']>;
  /** The `syched` state of this `McpManagedResource` */
  synced?: Maybe<Scalars['Boolean']>;
  /** The version portion of the k8s apiVersion */
  version: Scalars['String'];
};

/** A resource in an MCP `ControlPlane` */
export type McpResource = {
  /** The timestamp when this `McpResource` was created */
  creationTimestamp: Scalars['Timestamp'];
  /** The api portion of the k8s apiVersion */
  group: Scalars['String'];
  /** The unique id of this `McpResource` */
  id: Scalars['ID'];
  /** The kubernetes `kind` of this `McpResource` */
  kind: Scalars['String'];
  /** The kubernetes `name` of this `McpResource` */
  name: Scalars['String'];
  /** The `id` of the parent (if any) of this this `McpResource` */
  parentId?: Maybe<Scalars['ID']>;
  /** The ready state of this `McpResource` */
  ready?: Maybe<Scalars['Boolean']>;
  /** The `syched` state of this `McpResource` */
  synced?: Maybe<Scalars['Boolean']>;
  /** The version portion of the k8s apiVersion */
  version: Scalars['String'];
};

/** Filters to apply to fetching `McpResource`s */
export type McpResourceFilters = {
  /** Filter for only resources with the given k8s group */
  group?: InputMaybe<Scalars['String']>;
  /** Filter for only resources with the given k8s kind */
  kind?: InputMaybe<Scalars['String']>;
  /** The `id` of an `McpResource` to list it and its descendants */
  selfAndDescendants?: InputMaybe<Scalars['ID']>;
  /** The type of a `MCPResource` to filter by */
  type?: InputMaybe<McpResourceType>;
  /** Filter for only resources with the given k8s version */
  version?: InputMaybe<Scalars['String']>;
};

/** Node with page and aggregate info for listing `McpResource`s */
export type McpResourceNode = {
  __typename?: 'McpResourceNode';
  /** The number of items in the full, non-paged, result set */
  count: Scalars['Int'];
  /** The `McpResource`s for this page */
  items: Array<McpResource>;
  /** The page that is being returned */
  page: Scalars['Int'];
  /** The number of `McpResource`s per page */
  size: Scalars['Int'];
};

/** Order by for `McpResource`s */
export enum McpResourceOrderBy {
  /** Order by `McpResource` `creationTimestamp` */
  CreationTimestamp = 'CREATION_TIMESTAMP',
  /** Order by `McpResource` `name` */
  Name = 'NAME',
  /** Order by `McpResource` type (`McpResourceClaim`, `McpResourceComposite`, `McpResourceManagedResource) */
  Type = 'TYPE',
}

/** Order direction for `McpResource`s */
export enum McpResourceOrderDirection {
  /** Order ascending */
  Asc = 'ASC',
  /** Order descending */
  Desc = 'DESC',
}

/** The type of a `McpResource` */
export enum McpResourceType {
  /** The `McpResourceClaim` type */
  Claim = 'CLAIM',
  /** The `McpResourceComposite` type */
  Composite = 'COMPOSITE',
  /** The `McpResourceManagedResource` type */
  Managed = 'MANAGED',
}

/** Page, order, and filter options to apply to fetching `McpResource`s */
export type McpResourcesOptions = {
  /** Optional filters to filter the `McpResource`s by */
  filters?: InputMaybe<McpResourceFilters>;
  /** The order by for `McpResource`s */
  orderBy?: InputMaybe<McpResourceOrderBy>;
  /** The order direction for `McpResource`s */
  orderDirection?: InputMaybe<McpResourceOrderDirection>;
  /** The page to fetch */
  page?: InputMaybe<Scalars['Int']>;
  /** The number of `McpResource`s per page */
  size?: InputMaybe<Scalars['Int']>;
};

/** `Mutation`s */
export type Mutation = {
  __typename?: 'Mutation';
  /** Add a `Robot` to a `Team` */
  addTeamRobot: Robot;
  /** All 2fa `Mutation`s */
  auth: AuthMutation;
  /** Update the password for the `CurrentUser` */
  changePassword: Scalars['Boolean'];
  /** Clear a permission for a `ControlPlane` on a `Team` */
  clearTeamControlPlanePermission?: Maybe<TeamControlPlane>;
  /** Clear a permission for a `Repository` on a `Team` */
  clearTeamRepositoryPermission?: Maybe<TeamRepository>;
  /** Clear a permission for a `User` on a `Team` */
  clearTeamUserPermission?: Maybe<TeamMember>;
  /** Create a `ControlPlane` */
  createControlPlane: CreateControlPlaneResponse;
  /** Create an `ControlPlaneConfiguration` */
  createControlPlaneConfiguration: ControlPlaneConfiguration;
  /** Create or update a `Repository` */
  createOrUpdateRepository: Repository;
  /** Create an `Organization` */
  createOrganization: Organization;
  /** Invite a user to an `Organization` */
  createOrganizationInvite: OrgInvite;
  /** Create a `Robot` */
  createRobot: Robot;
  /** Create a `Team` */
  createTeam: Team;
  /** Create a `Token` */
  createToken: Token;
  /** Access to the mutations for a given `ControlPlane` */
  crossplane: CrossplaneMutation;
  /** Delete a `ControlPlane` */
  deleteControlPlane: AccountControlPlane;
  /** Delete an `ControlPlaneConfiguration` */
  deleteControlPlaneConfiguration: ControlPlaneConfiguration;
  /** Delete an `Organization` */
  deleteOrganization: Organization;
  /** Delete an `OrgInvite` */
  deleteOrganizationInvite: OrgInvite;
  /** Delete a `Repository` */
  deleteRepository: Repository;
  /** Delete a `RepositoryVersion` */
  deleteRepositoryVersion: RepositoryVersion;
  /** Delete a `Robot` */
  deleteRobot: Robot;
  /** Delete a `Team` */
  deleteTeam: Team;
  /** Delate a `Token` */
  deleteToken: Token;
  /** Disconnect a `LoginProvider` */
  disconnectLoginProvider: LoginProvider;
  /** Join an `Organization` given a token. True on success, false otherwise */
  joinOrgByToken: Scalars['Boolean'];
  /** Remove a `OrgMember` from an `Organization` */
  removeOrganizationMember: OrgMember;
  /** Remove a `Robot` from a `Team` */
  removeTeamRobot: Robot;
  /** Update the `Organization` to have completed the FRE given the `OrgAccount` id */
  setOrgAccountHasCompletedFRE: OrgAccount;
  /** Update the `Organization` to not have completed the FRE given the `OrgAccount` id */
  setOrgAccountHasNotCompletedFRE: OrgAccount;
  /** Set a permission for a `ControlPlane` on a `Team` */
  setTeamControlPlanePermission: TeamControlPlane;
  /** Set a permission for a `Repository` on a `Team` */
  setTeamRepositoryPermission: TeamRepository;
  /** Set a permission for a `User` on a `Team` */
  setTeamUserPermission: TeamMember;
  /** Begin a trial for an `Organization` with the given `OrgAccount` `id` */
  startOrgAccountTrial: OrgAccount;
  /** Update a `ControlPlane` */
  updateControlPlane: AccountControlPlane;
  /** Update an `ControlPlaneConfiguration` */
  updateControlPlaneConfiguration: ControlPlaneConfiguration;
  /** Update a `ControlPlaneConfigurationInstall` */
  updateControlPlaneConfigurationInstallation: ControlPlaneConfigurationInstallation;
  /** Update an `Organization` */
  updateOrganization: Organization;
  /** Update an `OrgMember` */
  updateOrganizationMember: OrgMember;
  /** Update a `Robot` */
  updateRobot: Robot;
  /** Update a `Team` */
  updateTeam: Team;
  /** Update the `CurrentUser` */
  updateUserInfo: CurrentUser;
  /** Update a user's settings on a `Repository */
  updateUserSettings: Scalars['Boolean'];
};

/** `Mutation`s */
export type MutationAddTeamRobotArgs = {
  payload: AddTeamRobotPayload;
};

/** `Mutation`s */
export type MutationChangePasswordArgs = {
  payload: ChangePasswordPayload;
};

/** `Mutation`s */
export type MutationClearTeamControlPlanePermissionArgs = {
  payload: ClearTeamControlPlanePermissionPayload;
};

/** `Mutation`s */
export type MutationClearTeamRepositoryPermissionArgs = {
  payload: ClearTeamRepositoryPermissionPayload;
};

/** `Mutation`s */
export type MutationClearTeamUserPermissionArgs = {
  payload: ClearTeamUserPermissionPayload;
};

/** `Mutation`s */
export type MutationCreateControlPlaneArgs = {
  payload: CreateControlPlanePayload;
};

/** `Mutation`s */
export type MutationCreateControlPlaneConfigurationArgs = {
  payload: CreateControlPlaneConfigurationPayload;
};

/** `Mutation`s */
export type MutationCreateOrUpdateRepositoryArgs = {
  payload: CreateOrUpdateRepositoryPayload;
};

/** `Mutation`s */
export type MutationCreateOrganizationArgs = {
  payload: CreateOrganizationPayload;
};

/** `Mutation`s */
export type MutationCreateOrganizationInviteArgs = {
  organizationId: Scalars['ID'];
  payload: CreateOrgInvitePayload;
};

/** `Mutation`s */
export type MutationCreateRobotArgs = {
  payload: CreateRobotPayload;
};

/** `Mutation`s */
export type MutationCreateTeamArgs = {
  payload: CreateTeamPayload;
};

/** `Mutation`s */
export type MutationCreateTokenArgs = {
  payload: CreateTokenPayload;
};

/** `Mutation`s */
export type MutationCrossplaneArgs = {
  cpId: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteControlPlaneArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteControlPlaneConfigurationArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteOrganizationInviteArgs = {
  inviteId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteRepositoryArgs = {
  payload: DeleteRepositoryPayload;
};

/** `Mutation`s */
export type MutationDeleteRepositoryVersionArgs = {
  payload: DeleteRepositoryVersionPayload;
};

/** `Mutation`s */
export type MutationDeleteRobotArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteTeamArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDeleteTokenArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationDisconnectLoginProviderArgs = {
  payload: DisconnectLoginProviderPayload;
};

/** `Mutation`s */
export type MutationJoinOrgByTokenArgs = {
  token: Scalars['String'];
};

/** `Mutation`s */
export type MutationRemoveOrganizationMemberArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** `Mutation`s */
export type MutationRemoveTeamRobotArgs = {
  payload: RemoveTeamRobotPayload;
};

/** `Mutation`s */
export type MutationSetOrgAccountHasCompletedFreArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationSetOrgAccountHasNotCompletedFreArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationSetTeamControlPlanePermissionArgs = {
  payload: SetTeamControlPlanePermissionPayload;
};

/** `Mutation`s */
export type MutationSetTeamRepositoryPermissionArgs = {
  payload: SetTeamRepositoryPermissionPayload;
};

/** `Mutation`s */
export type MutationSetTeamUserPermissionArgs = {
  payload: SetTeamUserPermissionPayload;
};

/** `Mutation`s */
export type MutationStartOrgAccountTrialArgs = {
  id: Scalars['ID'];
};

/** `Mutation`s */
export type MutationUpdateControlPlaneArgs = {
  id: Scalars['ID'];
  payload: UpdateControlPlanePayload;
};

/** `Mutation`s */
export type MutationUpdateControlPlaneConfigurationArgs = {
  id: Scalars['ID'];
  payload: UpdateControlPlaneConfigurationPayload;
};

/** `Mutation`s */
export type MutationUpdateControlPlaneConfigurationInstallationArgs = {
  controlPlaneId: Scalars['ID'];
  payload: UpdateControlPlaneConfigurationInstallationPayload;
};

/** `Mutation`s */
export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  payload: UpdateOrganizationPayload;
};

/** `Mutation`s */
export type MutationUpdateOrganizationMemberArgs = {
  organizationId: Scalars['ID'];
  payload: UpdateOrganizationMemberPayload;
  userId: Scalars['ID'];
};

/** `Mutation`s */
export type MutationUpdateRobotArgs = {
  id: Scalars['ID'];
  payload: UpdateRobotPayload;
};

/** `Mutation`s */
export type MutationUpdateTeamArgs = {
  id: Scalars['ID'];
  payload: UpdateTeamPayload;
};

/** `Mutation`s */
export type MutationUpdateUserInfoArgs = {
  payload: UpdateUserInfoPayload;
};

/** `Mutation`s */
export type MutationUpdateUserSettingsArgs = {
  payload: UpdateUserSettingsPayload;
};

/** An object with an ID. */
export type Node = {
  /** An opaque identifier that is unique across all types. */
  id: Scalars['ID'];
};

/**
 * ObjectMeta is metadata that is common to all Kubernetes API resources.
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.20/#objectmeta-v1-meta
 */
export type ObjectMeta = {
  __typename?: 'ObjectMeta';
  /**
   * A map of string keys and values that may be set by external tools to store and
   * retrieve arbitrary metadata.
   *
   * More info: http://kubernetes.io/docs/user-guide/annotations
   */
  annotations?: Maybe<Scalars['StringMap']>;
  /**
   * The controller of this resource, if any. In Kubernetes exactly one owner of a
   * resource may be its "controller" - the resource that manages its lifecycle. A
   * Crossplane Provider creates and manipulates ProviderRevisions and each
   * ProviderRevision creates and manipulates CustomResourceDefinitions in turn. In
   * this example the Provider would be the controller of its ProviderRevisions,
   * and the ProviderRevisions would be the controller of their CRDs.
   */
  controller?: Maybe<KubernetesResource>;
  /** The time the underlying Kubernetes resource was created in the API server. */
  creationTime: Scalars['Time'];
  /**
   * The time at which the underlying Kubernetes resource will be (or was) deleted.
   * Resources may exist past their deletion time while their controllers handle
   * any required cleanup.
   */
  deletionTime?: Maybe<Scalars['Time']>;
  /**
   * An optional prefix used by the Kubernetes API server to generate a unique
   * name at creation time if a name was not provided.
   */
  generateName?: Maybe<Scalars['String']>;
  /** A sequence number representing the specific generation of the desired state. */
  generation: Scalars['Int'];
  /**
   * A map of string keys and values that can be used to organize and categorize
   * (scope and select) objects. May match selectors of replication controllers
   * and services.
   *
   *  More info: http://kubernetes.io/docs/user-guide/labels
   */
  labels?: Maybe<Scalars['StringMap']>;
  /**
   * The name of this resource. Unique within its API group and version for
   * cluster scoped resources, and also within its namespace for namespaced
   * resources.
   */
  name: Scalars['String'];
  /**
   * The space within each name must be unique, for namespaced resources. An empty
   * namespace is equivalent to the 'default' namespace.
   */
  namespace?: Maybe<Scalars['String']>;
  /**
   * Resources depended by this resource. If ALL resources in the list have been
   * deleted, this resource will be garbage collected. If this resource is managed
   * by a controller, then an entry in this list will point to this controller,
   * with the controller field set to true. There cannot be more than one managing
   * controller.
   */
  owners: OwnerConnection;
  /**
   * An opaque version that changes whenever the underlying resource changes in the
   * API server. Used for change detection and optimistic concurrency.
   */
  resourceVersion: Scalars['String'];
  /** An opaque identifier of this resource that is unique across time. */
  uid: Scalars['String'];
};

/**
 * ObjectMeta is metadata that is common to all Kubernetes API resources.
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.20/#objectmeta-v1-meta
 */
export type ObjectMetaAnnotationsArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * ObjectMeta is metadata that is common to all Kubernetes API resources.
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.20/#objectmeta-v1-meta
 */
export type ObjectMetaLabelsArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/** `ObjectReference` contains enough information to let you inspect or modify the referred object. */
export type ObjectReference = {
  __typename?: 'ObjectReference';
  /** Kind of the referent. */
  kind?: Maybe<Scalars['String']>;
  /** Name of the referent. */
  name?: Maybe<Scalars['String']>;
  /** Namespace of the referent. */
  namespace?: Maybe<Scalars['String']>;
};

/** An `OrgAccount` */
export type OrgAccount = Account & {
  __typename?: 'OrgAccount';
  /** The `ControlPlaneConfiguration`s owned by this `OrgAccount` */
  controlPlaneConfigurations: Array<ControlPlaneConfiguration>;
  /** Get a `OrgAccount`s `AccountControlPlane`s */
  controlPlanes: Array<AccountControlPlane>;
  /** The unique id for this `OrgAccount` */
  id: Scalars['ID'];
  /** The `Organization` for this `OrgAccount` */
  organization: Organization;
  /** Get a `OrgAccount`s `Repository`s */
  repositories: AccountRepositoriesResult;
  /** Get a `Repository` by `id` within a `OrgAccount` */
  repository: Repository;
  /** Spaces owned by this `OrgAccount` */
  spaces: Array<Space>;
};

/** An `OrgAccount` */
export type OrgAccountRepositoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** An `OrgAccount` */
export type OrgAccountRepositoryArgs = {
  name: Scalars['String'];
};

/** An `OrgAccount` */
export type OrgAccountSpacesArgs = {
  spaceType?: InputMaybe<SpaceType>;
};

/** An invitation to an `Organization` */
export type OrgInvite = {
  __typename?: 'OrgInvite';
  /** The `Timestamp` when this `OrgInvite` was created */
  createdAt: Scalars['Timestamp'];
  /** The email of the `OrgInvite` */
  email: Scalars['String'];
  /** The unique id of this `OrgInvite` */
  id: Scalars['ID'];
  /** The permission of the `OrgInvite` */
  permission: OrganizationPermission;
  /** The The `Timestamp` when this `OrgInvite` was last updated */
  updatedAt: Scalars['Timestamp'];
};

/** An `OrgMember` */
export type OrgMember = {
  __typename?: 'OrgMember';
  /** The `OrganizationPermission` for this `OrgMember` */
  permission: OrganizationPermission;
  /** The `OrgMemberUser` for this `OrgMember` */
  user: OrgMemberUser;
};

/** An `OrgMemberUser` */
export type OrgMemberUser = {
  __typename?: 'OrgMemberUser';
  /** The unique id for this `OrgMemberUser` */
  id: Scalars['ID'];
  /** The full name for this `OrgMemberUser` */
  name: Scalars['String'];
};

/** An `Organization` */
export type Organization = {
  __typename?: 'Organization';
  /** Set to false if the Organization has not yet started it's trial because of capacity rate increase limiting */
  allowAccess: Scalars['Boolean'];
  /** The `Timestamp` when this `Organization` was created */
  createdAt?: Maybe<Scalars['Timestamp']>;
  /** The `ID` of the `User` who created this `Organization` */
  creatorId: Scalars['ID'];
  /** The `Timestamp` when this `Organization` will be deleted */
  deleteAt?: Maybe<Scalars['Timestamp']>;
  /** The display name for this `Organization` */
  displayName: Scalars['String'];
  /** The `Timestamp` when this `Organization` will expire */
  expiresAt?: Maybe<Scalars['Timestamp']>;
  /** Determines if an `Organization` has gone through the FRE yet */
  hasCompletedFRE: Scalars['Boolean'];
  /** The unique id for this `Organization` */
  id: Scalars['ID'];
  /** The `OrgInvite`s for this `Organization` */
  invites?: Maybe<Array<OrgInvite>>;
  /** The license limits for this `Organization` */
  licenseLimits: Array<OrganizationLicenseLimit>;
  /** The list of `OrgMember`s in this `Organization` */
  members: Array<OrgMember>;
  /** The unique name for this `Organization` */
  name: Scalars['String'];
  /** The number of `ControlPlane`s this `Organization` has reserved */
  reservedControlPlanes: Scalars['Int'];
  /** The list of `Robot`s in this `Organization` */
  robots: Array<Robot>;
  /** The `OrganizationPermission` for the `CurrentUser` in this `Organization` */
  role: OrganizationPermission;
  /** Determines if this `Organization` has Directory Sync enabled */
  ssoDSLinked?: Maybe<Scalars['Boolean']>;
  /** The `ID` of this `Organization` in the context of our IAM workspace */
  ssoOrgID?: Maybe<Scalars['String']>;
  /** The list of `Team`s in this `Organization` along with the `TeamMemberPermission` this `User` has on that `Team` */
  teams: Array<UserTeam>;
  /** The pricing tier this `Organization` is in */
  tier: Scalars['String'];
  /** The timestamp when this `Organization` will end or has ended or NULL if it hasn't started a trail */
  trialEndsAt?: Maybe<Scalars['String']>;
  /** The timestamp when this `Organization` started a trial or NULL if it hasn't started a trial */
  trialStartedAt?: Maybe<Scalars['String']>;
};

/** A limit on an `Organization` */
export type OrganizationLicenseLimit = {
  __typename?: 'OrganizationLicenseLimit';
  /** The current value of the limited kind */
  count: Scalars['Int'];
  /** The kind of limit */
  kind: OrganizationLicenseLimitKind;
  /** The max count of the limited kind */
  max: Scalars['Int'];
};

/** The kind of a `LicenseLimit` */
export enum OrganizationLicenseLimitKind {
  /** A limit on the number of `Admin`s an `Organization` can have */
  Admins = 'ADMINS',
  /** A limit on the number of `ControlPlane`s that can be created */
  ControlPlanes = 'CONTROL_PLANES',
}

/** The `OrganizationPermission` of an `Organization` or `OrgMember` */
export enum OrganizationPermission {
  /** `MEMBER` `OrganizationPermission` */
  Member = 'MEMBER',
  /** `OWNER` `OrganizationPermission` */
  Owner = 'OWNER',
}

/** An owner of a Kubernetes resource. */
export type Owner = {
  __typename?: 'Owner';
  /** Whether the owner is the controller of the owned Kubernetes resource. */
  controller?: Maybe<Scalars['Boolean']>;
  /** The owner. */
  resource: KubernetesResource;
};

/** An OwnerConnection represents a connection to an owner. */
export type OwnerConnection = {
  __typename?: 'OwnerConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<Owner>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** A dependency of a package */
export type PackageDependency = {
  __typename?: 'PackageDependency';
  /** The name to display for this `PackageDependency` */
  displayName: Scalars['String'];
  /** An optional icon for this `PackageDependency` */
  icon?: Maybe<Scalars['String']>;
  /** The unique id for this `PackageDependency` */
  id: Scalars['ID'];
  /** The type of package this `PackageDependency` represents */
  packageType: RegistryPackageType;
  /** The version this `PackageDependency` requires */
  version: Scalars['String'];
};

/** A PackagePullPolicy represents when to pull a package OCI image from a registry. */
export enum PackagePullPolicy {
  /** Always pull the package image, even if it is already present. */
  Always = 'ALWAYS',
  /** Only pull the package image if it is not present. */
  IfNotPresent = 'IF_NOT_PRESENT',
  /** Never pull the package image. */
  Never = 'NEVER',
}

/**
 * A PackageRevisionDesiredState represents the desired state of a provider or
 * configuration revision.
 */
export enum PackageRevisionDesiredState {
  /** The revision should be active. */
  Active = 'ACTIVE',
  /** The revision should be inactive. */
  Inactive = 'INACTIVE',
}

/** The `PackageState` of a `RepositoryPackageVersion` */
export enum PackageState {
  /** `ACCEPTED` `PackageState` */
  Accepted = 'ACCEPTED',
  /** `ANALYZING` `PackageState` */
  Analyzing = 'ANALYZING',
  /** `PUBLISHED` `PackageState` */
  Published = 'PUBLISHED',
  /** `RECEIVED` `PackageState` */
  Received = 'RECEIVED',
  /** `REJECTED` `PackageState` */
  Rejected = 'REJECTED',
}

/** The status of a `Configuration` or `Provider` */
export enum PackageStatus {
  /** The package is installed and healthy */
  Healthy = 'HEALTHY',
  /** The package is installing */
  Installing = 'INSTALLING',
  /** The package is unhealthy */
  Unhealthy = 'UNHEALTHY',
  /** The package is uninstalling */
  Uninstalling = 'UNINSTALLING',
  /** The package is in an unknown state */
  Unknown = 'UNKNOWN',
}

/** A Patch that should be applied to an unstructured input before it is submitted. */
export type Patch = {
  /**
   * A field path references a field within a Kubernetes object via a simple
   * string. API conventions describe the syntax as "standard JavaScript syntax for
   * accessing that field, assuming the JSON object was transformed into a
   * JavaScript object, without the leading dot, such as metadata.name".
   *
   * Valid examples:
   *
   * * metadata.name
   * * spec.containers[0].name
   * * data[.config.yml]
   * * metadata.annotations['crossplane.io/external-name']
   * * spec.items[0][8]
   * * apiVersion
   * * [42]
   *
   * Invalid examples:
   *
   * * .metadata.name - Leading period.
   * * metadata..name - Double period.
   * * metadata.name. - Trailing period.
   * * spec.containers[] - Empty brackets.
   * * spec.containers.[0].name - Period before open bracket.
   *
   * https://github.com/kubernetes/community/blob/61f3d0/contributors/devel/sig-architecture/api-conventions.md#selecting-fields
   */
  fieldPath: Scalars['String'];
  /**
   * Unstructured JSON to be patched in at the suppled field path. This could be a
   * string, an object, or any other valid JSON.
   */
  unstructured: Scalars['JSON'];
};

/** A PolicyRule holds information that describes a KubernetesRBAC policy rule. */
export type PolicyRule = {
  __typename?: 'PolicyRule';
  /**
   * APIGroups is the name of the APIGroup that contains the resources. If multiple
   * API groups are specified, any action requested against one of the enumerated
   * resources in any API group will be allowed.
   */
  apiGroups?: Maybe<Array<Scalars['String']>>;
  /**
   * NonResourceURLs is a set of partial urls that a user should have access to.
   * '*' is allowed, but only as the full, final step in the path. Rules can either
   * apply to API resources (such as "pods" or "secrets") or non-resource URL paths
   * (such as "/api"),  but not both.
   */
  nonResourceURLs?: Maybe<Array<Scalars['String']>>;
  /**
   * ResourceNames is a list of names that the rule applies to. An empty set means
   * that everything is allowed.
   */
  resourceNames?: Maybe<Array<Scalars['String']>>;
  /**
   * Resources is a list of resources this rule applies to. '*' represents all
   * resources.
   */
  resources?: Maybe<Array<Scalars['String']>>;
  /**
   * Verbs is a list of verbs that apply to ALL the resources specified by this
   * rule. '*' represents all verbs.
   */
  verbs: Array<Scalars['String']>;
};

/** A column in an `additionalPrinterColumns` list */
export type PrinterColumn = {
  __typename?: 'PrinterColumn';
  /** The description of the column */
  description?: Maybe<Scalars['String']>;
  /** The format of the column */
  format?: Maybe<Scalars['String']>;
  /** The name of the column */
  name: Scalars['String'];
  /** The priority of this column */
  priority?: Maybe<Scalars['Int']>;
  /** The type of the column */
  type: Scalars['String'];
  /** The value of the column */
  value?: Maybe<Scalars['JSON']>;
};

/** A Provider extends Crossplane with support for new managed resources. */
export type Provider = KubernetesResource &
  Node & {
    __typename?: 'Provider';
    /** The active revision of this provider. */
    activeRevision?: Maybe<ProviderRevision>;
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Calculated `PackageStatus` of the `Provider` */
    calculatedStatus: PackageStatus;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** The icon for this `Provider` */
    icon?: Maybe<Scalars['String']>;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** The registry package for this `Provider`, if it exists. Otherwise, null. */
    marketplace?: Maybe<RegistryPackage>;
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The human/short name of the `Provider` */
    name: Scalars['String'];
    /** The `CustomResourceDefinition` that this `Provider` contains */
    resourceDefinitions?: Maybe<Array<CustomResourceDefinition>>;
    /** Revisions of this provider. */
    revisions: ProviderRevisionConnection;
    /** The desired state of this resource. */
    spec: ProviderSpec;
    /** The observed state of this resource. */
    status?: Maybe<ProviderStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
    /** The version of the `Provider` */
    version: Scalars['String'];
  };

/**
 * A ProviderConfig configures a provider, in that it provides configuration that
 * is relevant to all managed resources installed by a provider.
 */
export type ProviderConfig = KubernetesResource &
  Node & {
    __typename?: 'ProviderConfig';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The definition of this resource. */
    definition?: Maybe<ProviderConfigDefinition>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The observed state of this resource. */
    status?: Maybe<ProviderConfigStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/**
 * A ProviderConfigDefinition defines a provider configuration.
 *
 * At the time of writing a ProviderConfigDefinition will always be a
 * CustomResourceDefinition. We use a union because this may change in future per
 * https://github.com/crossplane/crossplane/issues/2262
 */
export type ProviderConfigDefinition = CustomResourceDefinition;

/** A reference to the ProviderConfig used by a particular managed resource. */
export type ProviderConfigReference = {
  __typename?: 'ProviderConfigReference';
  /** Name of the provider config. */
  name: Scalars['String'];
};

/** A ProviderConfigStatus represents the observed state of a provider config. */
export type ProviderConfigStatus = ConditionedStatus & {
  __typename?: 'ProviderConfigStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /** The number of managed resources currently using this provider config. */
  users?: Maybe<Scalars['Int']>;
};

/** A ProviderConnection represents a connection to providers. */
export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<Provider>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** A ProviderRevision represents a revision or 'version' of a provider. */
export type ProviderRevision = KubernetesResource &
  Node & {
    __typename?: 'ProviderRevision';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** The desired state of this resource. */
    spec: ProviderRevisionSpec;
    /** The observed state of this resource. */
    status?: Maybe<ProviderRevisionStatus>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A ProviderRevisionConnection represents a connection to provider revisions. */
export type ProviderRevisionConnection = {
  __typename?: 'ProviderRevisionConnection';
  /** Connected nodes. */
  nodes?: Maybe<Array<ProviderRevision>>;
  /** The total number of connected nodes. */
  totalCount: Scalars['Int'];
};

/** A ProviderRevisionSpec represents the desired state of a provider revision. */
export type ProviderRevisionSpec = {
  __typename?: 'ProviderRevisionSpec';
  /** Desired state of the provider revision. */
  desiredState: PackageRevisionDesiredState;
  /**
   * IgnoreCrossplaneConstraints indicates to the package manager whether to honor
   * Crossplane version constrains specified by the package.
   */
  ignoreCrossplaneConstraints?: Maybe<Scalars['Boolean']>;
  /** Package image used by the install pod to extract package contents. */
  package: Scalars['String'];
  /**
   * PackagePullPolicy defines the pull policy for the package. It is also applied
   * to any images pulled for the package, such as a provider's controller image.
   */
  packagePullPolicy?: Maybe<PackagePullPolicy>;
  /**
   * Revision number. Indicates when the revision will be garbage collected based
   * on the configuration's RevisionHistoryLimit.
   */
  revision: Scalars['Int'];
  /**
   * SkipDependencyResolution indicates to the package manager whether to skip
   * resolving dependencies for a package.
   */
  skipDependencyResolution?: Maybe<Scalars['Boolean']>;
};

/** A ProviderRevisionStatus represents the observed state of a provider revision. */
export type ProviderRevisionStatus = ConditionedStatus & {
  __typename?: 'ProviderRevisionStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /** The number of known dependencies. */
  foundDependencies?: Maybe<Scalars['Int']>;
  /** The number of installed dependencies. */
  installedDependencies?: Maybe<Scalars['Int']>;
  /** The number of invalid dependencies. */
  invalidDependencies?: Maybe<Scalars['Int']>;
  /**
   * Objects owned by this provider revision - i.e. objects that were created by
   * this provider revision or that would have been created if they did not already
   * exist.
   *
   * In practice these objects are currently always a CustomResourceDefinition.
   * Crossplane lints the content of provider packages to enforce this, but it's
   * not enforced at the Kubernetes API level. We return an array of
   * KubernetesResource here because doing so allows us to package different types
   * in future without a breaking GraphQL schema change.
   */
  objects: KubernetesResourceConnection;
  /** Permissions requested by this configuration revision. */
  permissionRequests?: Maybe<Array<PolicyRule>>;
};

/** A ProviderSpec represents the desired state of a provider. */
export type ProviderSpec = {
  __typename?: 'ProviderSpec';
  /**
   * IgnoreCrossplaneConstraints indicates to the package manager whether to honor
   * Crossplane version constraints specified by the package.
   */
  ignoreCrossplaneConstraints?: Maybe<Scalars['Boolean']>;
  /** The name of the provider package to pull from an OCI registry. */
  package: Scalars['String'];
  /** PackagePullPolicy defines the pull policy for the package. */
  packagePullPolicy?: Maybe<PackagePullPolicy>;
  /**
   * RevisionActivationPolicy specifies how the package controller should update
   * from one revision to the next.
   */
  revisionActivationPolicy?: Maybe<RevisionActivationPolicy>;
  /**
   * RevisionHistoryLimit dictates how the package controller cleans up old
   * inactive package revisions. Defaults to 1. Can be disabled by explicitly
   * setting to 0.
   */
  revisionHistoryLimit?: Maybe<Scalars['Int']>;
  /**
   * SkipDependencyResolution indicates to the package manager whether to skip
   * resolving dependencies for a package.
   */
  skipDependencyResolution?: Maybe<Scalars['Boolean']>;
};

/** A ProviderStatus represents the observed state of a provider. */
export type ProviderStatus = ConditionedStatus & {
  __typename?: 'ProviderStatus';
  /** The observed condition of this resource. */
  conditions?: Maybe<Array<Condition>>;
  /**
   * CurrentIdentifier is the most recent package source that was used to produce a
   * revision. The package manager uses this field to determine whether to check
   * for package updates for a given source when packagePullPolicy is set to
   * IfNotPresent.
   */
  currentIdentifier?: Maybe<Scalars['String']>;
  /**
   * CurrentRevision is the name of the current package revision. It will reflect
   * the most up to date revision, whether it has been activated or not.
   */
  currentRevision?: Maybe<Scalars['String']>;
};

/** `Query`s */
export type Query = {
  __typename?: 'Query';
  /** Get a `Account` by `id` */
  account: Account;
  /** Check to see if a account name is available for use */
  accountNameAvailable: Scalars['Boolean'];
  /** Get a list of `Account`s */
  accounts: Array<Account>;
  /** All 2fa `Query`s */
  auth: AuthQuery;
  /** Get a `ControlPlane` by `id` */
  controlPlane: AccountControlPlane;
  /** Get a `ControlPlaneConfiguration` by `id` */
  controlPlaneConfiguration: ControlPlaneConfiguration;
  /** Access to the data for a given `ControlPlane` */
  crossplane?: Maybe<Crossplane>;
  /** Get the `CurrentUser` of an authenticated session */
  currentUser: CurrentUser;
  /** Query the registry */
  registry: RegistryQuery;
  /** Get a `Robot` */
  robot: Robot;
  /** Get a `Space` by `ID` */
  space: Space;
  /** The control plane withing the space. `id` needs to be passed as a `${string}:${string}:${string}:${string}` */
  spaceControlPlane: AccountControlPlane;
  /** Get a `SpaceGroup` by `id` */
  spaceGroup: SpaceGroup;
  /** Get a `Team` by `ID` */
  team: Team;
  /** Validate invite token. True on valid, false otherwise */
  validateInvite?: Maybe<InviteData>;
};

/** `Query`s */
export type QueryAccountArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QueryAccountNameAvailableArgs = {
  name: Scalars['String'];
};

/** `Query`s */
export type QueryControlPlaneArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QueryControlPlaneConfigurationArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QueryCrossplaneArgs = {
  cpId: Scalars['ID'];
};

/** `Query`s */
export type QueryRobotArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QuerySpaceArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QuerySpaceControlPlaneArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QuerySpaceGroupArgs = {
  id: Scalars['ID'];
};

/** `Query`s */
export type QueryTeamArgs = {
  teamId: Scalars['ID'];
};

/** `Query`s */
export type QueryValidateInviteArgs = {
  token: Scalars['String'];
};

/** A request for 2fa registration with FIDO */
export type RegisterRequest = {
  __typename?: 'RegisterRequest';
  /** Challenge string for 2fa registration with FIDO */
  challenge: Scalars['String'];
  /** Version of U2F */
  version: Scalars['String'];
};

/** Data for FIDO keys */
export type RegisteredKey = {
  __typename?: 'RegisteredKey';
  /** ID of the app */
  appId: Scalars['String'];
  /** Public key */
  keyHandle: Scalars['String'];
  /** U2F version */
  version: Scalars['String'];
};

/** A CRD in a `RegistryPackage` */
export type RegistryCrd = RegistryResource & {
  __typename?: 'RegistryCRD';
  /** The name of the `group` this `RegistryCRD` is part of */
  group: Scalars['String'];
  /** The Kubernetes `kind` of this `RegistryCRD` */
  kind: Scalars['String'];
  /** The Kubernetes `storageVersion` of this `RegistryCRD` */
  storageVersion: Scalars['String'];
  /** The Kubernetes `version` of this `RegistryCRD` */
  versions: Array<Scalars['String']>;
};

/** A composition in a `RegistryPackage` */
export type RegistryComposition = {
  __typename?: 'RegistryComposition';
  /** The name of this `RegistryComposition` */
  name: Scalars['String'];
  /** The number of resources this composition composes */
  resourceCount: Scalars['Int'];
  /** The group of the XRD this composition references */
  xrdGroup?: Maybe<Scalars['String']>;
  /** The kind of the XRD this composition references */
  xrdKind?: Maybe<Scalars['String']>;
};

/** The details of a `RegistryComposition` in a `RegistryPackage` */
export type RegistryCompositionDetails = {
  __typename?: 'RegistryCompositionDetails';
  /** The resources that this `RegistryCompositionDetails` composes */
  compositionResources: Array<RegistryCompositionResource>;
  /** The kubernetes definition for this `RegistryCompositionDetails` */
  definition: Scalars['JSON'];
  /** The unique id of this `RegistryCompositionDetails` */
  id: Scalars['ID'];
  /** The name of this `RegistryCompositionDetails` */
  name: Scalars['String'];
  /** The group of the XRD this composition references */
  xrdGroup: Scalars['String'];
  /** The name of the XRD this composition references */
  xrdKind: Scalars['String'];
};

/** The payload of `RegistryQuery#compositionDetails` */
export type RegistryCompositionDetailsPayload = {
  /** The `Account` name of the `RegistryPackage` */
  account: Scalars['String'];
  /** The `name` of the composition */
  name: Scalars['String'];
  /** The `Repository` name of the `RegistryPackage` */
  repository: Scalars['String'];
  /** The optional version of the `RegistryPackage` */
  version?: InputMaybe<Scalars['String']>;
  /** The XRD Group that the composition references */
  xrdGroup: Scalars['String'];
  /** The XRD Kind that the composition references */
  xrdKind: Scalars['String'];
};

/** The GKV of a `RegistryResource` that a `RegistryComposition` composes */
export type RegistryCompositionResource = {
  __typename?: 'RegistryCompositionResource';
  /** The group of this `RegistryCompositionResource` */
  group: Scalars['String'];
  /** The `kind` of this `RegistryCompositionResource` */
  kind: Scalars['String'];
  /** The version of this `RegistryCompositionResource` */
  version: Scalars['String'];
};

/** A package in the registry */
export type RegistryPackage = {
  __typename?: 'RegistryPackage';
  /** The account of this `RegistryPackage` */
  account: Scalars['String'];
  /** The company of this `RegistryPackage` */
  company?: Maybe<Scalars['String']>;
  /** If this is a 'Configuration` `RegistryPackage` this is the list of contained `Composition`s */
  compositions?: Maybe<Array<RegistryComposition>>;
  /** The raw definition of this `RegistryPackage` */
  definition: Scalars['JSON'];
  /** The dependencies of this `RegistryPackage` */
  dependsOn: Array<PackageDependency>;
  /** The description of this `RegistryPackage` */
  description?: Maybe<Scalars['String']>;
  /** The display name of this `RegistryPackage` */
  displayName: Scalars['String'];
  /** The table of contents and metadata for the docs of this `RegistryPackage` */
  docs?: Maybe<RegistryPackageDocs>;
  /** The id of this package's family repository */
  familyRepositoryId?: Maybe<Scalars['ID']>;
  /** The icon of this `RegistryPackage` */
  icon?: Maybe<Scalars['String']>;
  /** The unique id of this `RegistryPackage` */
  id: Scalars['ID'];
  /** The keywords of this `RegistryPackage` */
  keywords: Array<Scalars['String']>;
  /** The license of this `RegistryPackage` */
  license?: Maybe<Scalars['String']>;
  /** The maintainer of this `RegistryPackage` */
  maintainer?: Maybe<Scalars['String']>;
  /** The name of this `RegistryPackage` */
  name: Scalars['String'];
  /** The type of this `RegistryPackage` */
  packageType: RegistryPackageType;
  /** If this `RegistryPackage` is public */
  public: Scalars['Boolean'];
  /** The readme of this `RegistryPackage` */
  readme?: Maybe<Scalars['String']>;
  /** The id of this package's repository */
  repositoryId: Scalars['ID'];
  /** The contents of a `RegistryPackage` (either a XRD or a managed resource) */
  resources: Array<RegistryResource>;
  /** The source of this `RegistryPackage` */
  source?: Maybe<Scalars['String']>;
  /** The `RepositoryTier` for this `Repository` */
  tier: RepositoryTier;
  /** The user settings on this `RegistryPackage` */
  userSettings?: Maybe<UserSettings>;
  /** The version of this `RegistryPackage` */
  version: Scalars['String'];
  /** The list of versions of this `RegistryPackage` */
  versions?: Maybe<Array<Scalars['String']>>;
};

/** The docs for a package in the registry */
export type RegistryPackageDocs = {
  __typename?: 'RegistryPackageDocs';
  /** The metadata of this `RegistryPackageDocs` */
  metadata: Scalars['JSON'];
  /** The table of contents of this `RegistryPackageDocs` */
  tableOfContents: Array<RegistryPackageDocsPage>;
};

/** The page of docs for a package in the registry */
export type RegistryPackageDocsPage = {
  __typename?: 'RegistryPackageDocsPage';
  /** The location of this `RegistryPackageDocsPage` */
  location: Scalars['String'];
  /** The name of this `RegistryPackageDocsPage` */
  name: Scalars['String'];
};

/** The payload of `RegistryQuery#package` */
export type RegistryPackagePayload = {
  /** The `Account` name of a `RegistryPackage` */
  account: Scalars['String'];
  /** The `Repository` name of the `RegistryPackage` */
  repository: Scalars['String'];
  /** The optional version of the `RegistryPackage` */
  version?: InputMaybe<Scalars['String']>;
};

/** The type of a registry package */
export enum RegistryPackageType {
  /** A Configuration */
  Configuration = 'CONFIGURATION',
  /** A Function */
  Function = 'FUNCTION',
  /** A Provider */
  Provider = 'PROVIDER',
}

/** Query the registry */
export type RegistryQuery = {
  __typename?: 'RegistryQuery';
  /** Get a `RegistryPackage` */
  compositionDetails: RegistryCompositionDetails;
  /** Get a `RegistryPackage` */
  package: RegistryPackage;
  /** Get a `RegistryResourceDetails` */
  resourceDetails: RegistryResourceDetails;
  /** Search the registry */
  search: RegistrySearchResults;
};

/** Query the registry */
export type RegistryQueryCompositionDetailsArgs = {
  payload: RegistryCompositionDetailsPayload;
};

/** Query the registry */
export type RegistryQueryPackageArgs = {
  payload: RegistryPackagePayload;
};

/** Query the registry */
export type RegistryQueryResourceDetailsArgs = {
  payload: RegistryResourceDetailsPayload;
};

/** Query the registry */
export type RegistryQuerySearchArgs = {
  payload: RegistrySearchPayload;
};

/** Common properties between a `RegistryCRD` and `RegistryCRD` */
export type RegistryResource = {
  /** The name of the `group` this resource` is part of */
  group: Scalars['String'];
  /** The Kubernetes `kind` of this resource */
  kind: Scalars['String'];
  /** The Kubernetes `versions` of this resource */
  versions: Array<Scalars['String']>;
};

/** The details of a `RegistryResource` in a `RegistryPackage` */
export type RegistryResourceDetails = {
  __typename?: 'RegistryResourceDetails';
  /** The `Account` name of the `RegistryResource` */
  account: Scalars['String'];
  /** The kubernetes definition for this `RegistryResource` */
  definition: Scalars['JSON'];
  /** Examples of the `RegistryResource` in YAML format */
  examples: Array<Scalars['String']>;
  /** The `group` of the `RegistryResource` */
  group: Scalars['String'];
  /** The unique id of this `RegistryResourceDetails` */
  id: Scalars['ID'];
  /** The `kind` of the `RegistryResource` */
  kind: Scalars['String'];
  /** The `Repository` name of the `RegistryResource` */
  repository: Scalars['String'];
  /** The type of resource of the `RegistryResourceDetails` */
  resourceType: RegistryResourceType;
  /** The source of this `RegistryResourceDetails` */
  source?: Maybe<Scalars['String']>;
};

/** The payload of `RegistryQuery#resourceDetails` */
export type RegistryResourceDetailsPayload = {
  /** The `Account` name of the `RegistryPackage` */
  account: Scalars['String'];
  /** The `group` of the `RegistryResourceDetails` */
  group: Scalars['String'];
  /** The `kind` of the `RegistryResourceDetails` */
  kind: Scalars['String'];
  /** The `Repository` name of the `RegistryPackage` */
  repository: Scalars['String'];
  /** The optional version of the `RegistryResourceDetails` */
  version?: InputMaybe<Scalars['String']>;
};

/** The type of a `RegistryResource` */
export enum RegistryResourceType {
  /** The `CROSSPLANE_RESOURCE_DEFINITION` `RegistryResourceType` */
  CrossplaneResourceDefinition = 'CROSSPLANE_RESOURCE_DEFINITION',
  /** The `CUSTOM_RESOURCE_DEFINITION` `RegistryResourceType` */
  CustomResourceDefinition = 'CUSTOM_RESOURCE_DEFINITION',
}

/** The payload of `RegistryQuery#search` */
export type RegistrySearchPayload = {
  /** An optional account name filter */
  accountName?: InputMaybe<Scalars['String']>;
  /** An optional filter to omit child packages from search. Defaults to `false` if `family` param is provided. */
  excludeFamily?: InputMaybe<Scalars['Boolean']>;
  /** An optional filter to search within a family based on that family's repository ID. */
  family?: InputMaybe<Scalars['ID']>;
  /** An optional `RegistryPackageType` to limit the search to */
  packageType?: InputMaybe<RegistryPackageType>;
  /** An optional page of the search results */
  page?: InputMaybe<Scalars['Int']>;
  /** An optional visibility filter */
  public?: InputMaybe<Scalars['Boolean']>;
  /** An optional query string */
  query?: InputMaybe<Scalars['String']>;
  /** An optional filter for querying `resource` types */
  resources?: InputMaybe<Scalars['Boolean']>;
  /** An optional limit to the number of results */
  size?: InputMaybe<Scalars['Int']>;
  /** An optional `starred` filter */
  starred?: InputMaybe<Scalars['Boolean']>;
  /** An optional filter on the packages `RepositoryTier` */
  tier?: InputMaybe<RepositoryTier>;
};

/** A single search result */
export type RegistrySearchResult = {
  __typename?: 'RegistrySearchResult';
  /** The account of this `RegistrySearchResult` */
  account: Scalars['String'];
  /** The description of this `RegistrySearchResult` */
  description?: Maybe<Scalars['String']>;
  /** The name specified in the package metadata for this `RegistrySearchResult` */
  displayName: Scalars['String'];
  /** The id of this package's family repository */
  familyRepositoryId?: Maybe<Scalars['ID']>;
  /** The `group` of this `RegistrySearchResult` (`resource` types only) */
  group?: Maybe<Scalars['String']>;
  /** The `hash` of this `RegistrySearchResult` (`resource` types only) */
  hash?: Maybe<Scalars['String']>;
  /** Key-value pairs of fields in elasticsearch that match the query */
  highlight?: Maybe<Highlight>;
  /** An icon URI for a `RegistrySearchResult` */
  icon: Scalars['String'];
  /** The unique id of this `RegistrySearchResult` */
  id: Scalars['ID'];
  /** The `kind` of this `RegistrySearchResult` (`resource` types only) */
  kind?: Maybe<Scalars['String']>;
  /** The `repository` name of this `RegistrySearchResult` */
  name: Scalars['String'];
  /** The type of this `RegistrySearchResult` */
  packageType: RegistryPackageType;
  /** If this `RegistrySearchResult` is public */
  public: Scalars['Boolean'];
  /** The id of this package's repository */
  repositoryId: Scalars['ID'];
  /** The `RepositoryTier` for this `Repository` */
  tier: RepositoryTier;
  /** The `type` of this `RegistrySearchResult` (`resource` or `package`) */
  type?: Maybe<RegistrySearchResultType>;
  /** The user settings of the user on this `RegistrySearchResult` */
  userSettings?: Maybe<UserSettings>;
  /** The `version` of this `RegistrySearchResult` */
  version: Scalars['String'];
};

/** The tier of a `Repository` */
export enum RegistrySearchResultType {
  /** This is `RegistrySearchResult is `package` */
  Package = 'PACKAGE',
  /** This is `RegistrySearchResult is `resource` */
  Resource = 'RESOURCE',
}

/** The response of a registry `search` */
export type RegistrySearchResults = {
  __typename?: 'RegistrySearchResults';
  /** The number of search results */
  count: Scalars['Int'];
  /** The current page in the results */
  page: Scalars['Int'];
  /** The detailed breakdown of search hits */
  results: Array<RegistrySearchResult>;
  /** The number or results per page */
  size: Scalars['Int'];
};

/** An XRD in a `RegistryPackage` */
export type RegistryXrd = RegistryResource & {
  __typename?: 'RegistryXRD';
  /** The name of the `group` this `RegistryXRD` is part of */
  group: Scalars['String'];
  /** The Kubernetes `kind` of this `RegistryXRD` */
  kind: Scalars['String'];
  /** The Kubernetes `referenceableVersion` of this `RegistryXRD` */
  referenceableVersion: Scalars['String'];
  /** The Kubernetes `version` of this `RegistryXRD` */
  versions: Array<Scalars['String']>;
};

/** The payload for removing a `Robot` from a `Team` */
export type RemoveTeamRobotPayload = {
  /** The `id` of the `Robot` to add to the `Team` */
  robotId: Scalars['ID'];
  /** The `id` of the `Team` to add the `Robot` to */
  teamId: Scalars['ID'];
};

/** A `Repository` */
export type Repository = {
  __typename?: 'Repository';
  /** The `Timestamp` when this `Repository` was created */
  createdAt?: Maybe<Scalars['Timestamp']>;
  /** The current version for a `Repository` */
  currentVersion?: Maybe<Scalars['String']>;
  /** An icon URI for a `Repository` */
  icon: Scalars['String'];
  /** The unique id for this `Repository` */
  id: Scalars['ID'];
  /** The name for this `Repository` */
  name: Scalars['String'];
  /** The package type if a successfully indexed package has been pushed */
  packageType?: Maybe<RegistryPackageType>;
  /** A `Boolean` to identify this `Repository` as being public */
  public: Scalars['Boolean'];
  /** The `RepositoryTier` for this `Repository` */
  tier: RepositoryTier;
  /** The `Timestamp` when this `Repository` was last updated */
  updatedAt?: Maybe<Scalars['Timestamp']>;
  /** The user settings of the user on this `Repository` */
  userSettings?: Maybe<UserSettings>;
  /** The list of `RepositoryVersion`s for a `Repository` */
  versions?: Maybe<Array<RepositoryVersion>>;
};

/** The `RepositoryPermission` of a `Repository` */
export enum RepositoryPermission {
  /** `ADMIN` `RepositoryPermission` */
  Admin = 'ADMIN',
  /** `READ` `RepositoryPermission` */
  Read = 'READ',
  /** `VIEW` `RepositoryPermission` */
  View = 'VIEW',
  /** `WRITE` `RepositoryPermission` */
  Write = 'WRITE',
}

/** The tier of a `Repository` */
export enum RepositoryTier {
  /** This is a community `Repository` */
  Community = 'COMMUNITY',
  /** This is an official `Repository` */
  Official = 'OFFICIAL',
  /** This is a partners `Repository` */
  Partner = 'PARTNER',
}

/** The version info for a `Repository` */
export type RepositoryVersion = {
  __typename?: 'RepositoryVersion';
  /** The `Timestamp` when this `RepositoryVersion` was created */
  createdAt: Scalars['Timestamp'];
  /** The SHA digest for this `RepositoryVersion` */
  digest: Scalars['String'];
  /** The unique id of this `RepositoryVersion` */
  id: Scalars['ID'];
  /** The name for this `RepositoryVersion` */
  name: Scalars['String'];
  /** The `PackageState` for this `RepositoryVersion` */
  state: PackageState;
  /** The `PackageState` for this `RepositoryVersion` */
  stateDetail?: Maybe<Scalars['String']>;
};

/** An any supported `DetailsResource` and its `CustomResourceDefinition` as well as calculated `additionalPrinterColumns` */
export type ResourceInstanceDetails = {
  __typename?: 'ResourceInstanceDetails';
  /** calculated `additionalPrinterColumns` */
  additionalPrinterColumns: Array<PrinterColumn>;
  /** The `CustomResourceDefinition` for the `CompositeResourceClaim` */
  crd: CustomResourceDefinition;
  /** A `DetailsResource` */
  instance: DetailsResource;
};

/** ResourceScope defines the scopes available to custom resources. */
export enum ResourceScope {
  /**
   * Cluster scoped resources exist outside any namespace. The combination of their
   * API version, kind, and name must be unique within a cluster.
   */
  ClusterScoped = 'CLUSTER_SCOPED',
  /**
   * Namespace scoped resources exist within a particular namespace. The
   * combination of their API version, kind, and name must be unique only within
   * their namespace.
   */
  NamespaceScoped = 'NAMESPACE_SCOPED',
}

/**
 * A RevisionActivationPolicy indicates how a provider or configuration package
 * should activate its revisions.
 */
export enum RevisionActivationPolicy {
  /** Automatically activate package revisions. */
  Automatic = 'AUTOMATIC',
  /** Require a user to manually activate revisions. */
  Manual = 'MANUAL',
}

/** A `Robot` account */
export type Robot = {
  __typename?: 'Robot';
  /** The creation timestamp for this `Robot` */
  createdAt: Scalars['Timestamp'];
  /** The `description` for this `Robot` */
  description: Scalars['String'];
  /** The unique id for this `Robot` */
  id: Scalars['ID'];
  /** The `name` for this `Robot` */
  name: Scalars['String'];
  /** The data for each team this `Robot` belongs to */
  teams: Array<TeamRobotRelationship>;
  /** The auth `Token`s for this `Robot` */
  tokens: Array<Token>;
};

/** A Secret holds secret data. */
export type Secret = KubernetesResource &
  Node & {
    __typename?: 'Secret';
    /** The underlying Kubernetes API version of this resource. */
    apiVersion: Scalars['String'];
    /** The data stored in this secret. Values are not base64 encoded. */
    data?: Maybe<Scalars['StringMap']>;
    /** Events pertaining to this resource. */
    events: EventConnection;
    /** An opaque identifier that is unique across all types. */
    id: Scalars['ID'];
    /** The underlying Kubernetes API kind of this resource. */
    kind: Scalars['String'];
    /** Metadata that is common to all Kubernetes API resources. */
    metadata: ObjectMeta;
    /** Type of this secret. */
    type?: Maybe<Scalars['String']>;
    /** An unstructured JSON representation of the underlying Kubernetes resource. */
    unstructured: Scalars['JSON'];
  };

/** A Secret holds secret data. */
export type SecretDataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/** A `SecretReference` is a reference to a secret in an arbitrary namespace. */
export type SecretReference = {
  __typename?: 'SecretReference';
  /** Name of the `Secret`. */
  name: Scalars['String'];
  /** Namespace of the `Secret`. */
  namespace: Scalars['String'];
};

/** The payload for setting a `ControlPlane` permission on a `Team` */
export type SetTeamControlPlanePermissionPayload = {
  /** The `id` of the `ControlPlane` to set the permission on the `Team` */
  controlPlaneId: Scalars['ID'];
  /** The permission to give the `User` on the `Team` */
  permission: ControlPlanePermission;
  /** The `id` of the `Team` to set the `ControlPlane` permission on */
  teamId: Scalars['ID'];
};

/** The payload for setting a `Repository` permission on a `Team` */
export type SetTeamRepositoryPermissionPayload = {
  /** The permission to give the `Repository` on the `Team` */
  permission: RepositoryPermission;
  /** The `id` of the `Repository` to set the permission on the `Team` */
  repositoryId: Scalars['ID'];
  /** The `id` of the `Team` to set the `Repository` permission on */
  teamId: Scalars['ID'];
};

/** The payload for setting a `User` permission on a `Team` */
export type SetTeamUserPermissionPayload = {
  /** The permission to give the `User` on the `Team` */
  permission: TeamMemberPermission;
  /** The `id` of the `Team` to set the `User` permission on */
  teamId: Scalars['ID'];
  /** The `id` of the `User` to set the permission on the `Team` */
  userId: Scalars['ID'];
};

/** A Space */
export type Space = {
  __typename?: 'Space';
  /** Cross-group control planes */
  controlPlanes?: Maybe<Array<AccountControlPlane>>;
  /** The groups of the space */
  groups?: Maybe<Array<SpaceGroup>>;
  /** The id of the space */
  id: Scalars['ID'];
  /** The labels of the space */
  labels: Scalars['JSON'];
  /** The display name of the space */
  name: Scalars['String'];
  /** The cloud provider of the space */
  provider?: Maybe<SpaceProvider>;
  /** The cloud provider region of the space */
  region?: Maybe<SpaceRegion>;
  /** The status of the space */
  status: SpaceStatus;
  /** The type of the space */
  type: SpaceType;
  /** The version of the space */
  version?: Maybe<Scalars['String']>;
};

/** Space Connection Status */
export enum SpaceConnectionStatus {
  /** The space is connected */
  Connected = 'CONNECTED',
  /** The space is unknown */
  Unknown = 'UNKNOWN',
  /** The space is unreachable */
  Unreachable = 'UNREACHABLE',
}

/** Space Group */
export type SpaceGroup = {
  __typename?: 'SpaceGroup';
  /** The control planes of the group */
  controlPlanes?: Maybe<Array<AccountControlPlane>>;
  /** The name of the group */
  groupName: Scalars['String'];
  /** The id of the group */
  id: Scalars['ID'];
  /** The labels of the group */
  labels: Scalars['JSON'];
};

/** Space Provider */
export enum SpaceProvider {
  /** Amazon Web Services */
  Aws = 'AWS',
  /** Google cloud */
  Gcp = 'GCP',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** Space Region */
export enum SpaceRegion {
  /** us-central-1 */
  UsCentral_1 = 'US_CENTRAL_1',
  /** us-east-1 */
  UsEast_1 = 'US_EAST_1',
  /** us-west-1 */
  UsWest_1 = 'US_WEST_1',
}

/** Space Status object */
export type SpaceStatus = {
  __typename?: 'SpaceStatus';
  /** The timestamp of the last time the space was queried */
  lastQueried?: Maybe<Scalars['Timestamp']>;
  /** The timestamp of the last time the space was seen */
  lastSeen?: Maybe<Scalars['Timestamp']>;
  /** The connection status of the space */
  status?: Maybe<SpaceConnectionStatus>;
};

/** Space Type */
export enum SpaceType {
  /** A self-hosted space */
  Connected = 'CONNECTED',
  /** A legacy space */
  Legacy = 'LEGACY',
  /** A SaaS space */
  Managed = 'MANAGED',
}

/** A `Team` */
export type Team = {
  __typename?: 'Team';
  /** The `ControlPlanes` this `Team` has access to */
  controlPlanes: Array<TeamControlPlane>;
  /** The creation timestamp for this `Team` */
  createdAt: Scalars['Timestamp'];
  /** The unique id for this `Team` */
  id: Scalars['ID'];
  /** The `members` for this `Team` */
  members: Array<TeamMember>;
  /** The `name` for this `Team` */
  name: Scalars['String'];
  /** The `Repositories` this `Team` has access to */
  repositories: TeamRepositoriesResult;
  /** The `Robot`s that are a part of this `Team` */
  robots: Array<Robot>;
};

/** A `Team` */
export type TeamRepositoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** The relationship of a `Team` to a `ControlPlane` with a `permission` */
export type TeamControlPlane = {
  __typename?: 'TeamControlPlane';
  /** The `ControlPlane` the `Team` has access to */
  controlPlane: ControlPlane;
  /** The permission this `Team` has on the `ControlPlane` */
  permission: ControlPlanePermission;
  /** The `Team` `id` */
  teamId: Scalars['ID'];
};

/** A `TeamMember` */
export type TeamMember = {
  __typename?: 'TeamMember';
  /** The `TeamMemberPermission` for this `TeamMember` */
  permission: TeamMemberPermission;
  /** The `TeamMemberUser` for this `TeamMember` */
  user: TeamMemberUser;
};

/** The `TeamMemberPermission` of a `UserTeam` */
export enum TeamMemberPermission {
  /** `MEMBER` TeamMemberPermission */
  Member = 'MEMBER',
  /** `OWNER` TeamMemberPermission */
  Owner = 'OWNER',
}

/** A `TeamMemberUser` */
export type TeamMemberUser = {
  __typename?: 'TeamMemberUser';
  /** The unique id for this `TeamMemberUser` */
  id: Scalars['ID'];
  /** The full name for this `TeamMemberUser` */
  name: Scalars['String'];
};

/** Result type for `Repositories` on a `Team` */
export type TeamRepositoriesResult = {
  __typename?: 'TeamRepositoriesResult';
  /** The total count of `Repositories` */
  count: Scalars['Int'];
  /** The `Repositories` */
  nodes: Array<TeamRepository>;
  /** The current page */
  page: Scalars['Int'];
  /** The number of `Repositories` in a page */
  size: Scalars['Int'];
};

/** A `Repository` on a `Team` with a permission */
export type TeamRepository = {
  __typename?: 'TeamRepository';
  /** The permission this `Team` has to the `Repository` */
  permission: RepositoryPermission;
  /** The `Repository` the `Team` has access to */
  repository: Repository;
  /** The id of the `Team` */
  teamId: Scalars['ID'];
};

/** A representation of the relationship between a `Team` and a `Robot` */
export type TeamRobotRelationship = {
  __typename?: 'TeamRobotRelationship';
  /** The `Timestamp` when this `Team` was created */
  createdAt: Scalars['String'];
  /** The id of the `Team` this `Robot` belongs to */
  id: Scalars['String'];
  /** The name of the `Team` this `Robot` belongs to */
  name: Scalars['String'];
  /** The `Timestamp` when the relationship was created for this `Team` and `Robot` */
  relationshipCreatedAt: Scalars['String'];
};

/** An auth `Token` */
export type Token = {
  __typename?: 'Token';
  /** When the `Token` was created */
  createdAt: Scalars['Timestamp'];
  /** The unique `id` for this `Token` */
  id: Scalars['ID'];
  /** The actual JWT token string. Note: Only returned during a `createToken` mutation. */
  jwt?: Maybe<Scalars['String']>;
  /** The time this `Token` was last used */
  lastUsedAt?: Maybe<Scalars['Timestamp']>;
  /** The name for this `Token` */
  name: Scalars['String'];
};

/** The type of a `Token` */
export enum TokenType {
  /** A Robot `Token` */
  Robot = 'ROBOT',
  /** A User `Token` */
  User = 'USER',
}

/** A token for TOTP 2fa auth */
export type TotpApplication = {
  __typename?: 'TotpApplication';
  /** Creation `Timestamp` for this token */
  createdAt: Scalars['String'];
  /** The name of this token */
  name: Scalars['String'];
  /** The `id` for this token */
  totpId: Scalars['String'];
  /** `Timestamp` for when this token was last updated */
  updatedAt: Scalars['String'];
  /** The `User`'s `id` */
  userId: Scalars['String'];
};

/** A list of a `User`'s FIDO tokens and TOTP applications */
export type TwoFactorKeys = {
  __typename?: 'TwoFactorKeys';
  /** A list of FIDO tokens */
  fido?: Maybe<Array<FidoKey>>;
  /** A list of TOTP applications */
  totp?: Maybe<Array<TotpApplication>>;
};

/**
 * A TypeReference references a type of Kubernetes resource by API version and
 * kind.
 */
export type TypeReference = {
  __typename?: 'TypeReference';
  /** The Kubernetes API version of the referenced resource. */
  apiVersion: Scalars['String'];
  /** The Kubernetes API kind of the referenced resource. */
  kind: Scalars['String'];
};

/** The payload for updating a `ControlPlaneConfigurationInstallation` */
export type UpdateControlPlaneConfigurationInstallationPayload = {
  /** The version of the `ControlPlaneConfiguration` that should be installed */
  desiredVersion: Scalars['String'];
};

/** The payload for updating an `ControlPlaneConfiguration` */
export type UpdateControlPlaneConfigurationPayload = {
  /** The new name of a `ControlPlaneConfiguration` */
  name: Scalars['String'];
};

/** The payload to pass to `updateControlPlane` */
export type UpdateControlPlanePayload = {
  /** The new description for this `ControlPlane` */
  description?: InputMaybe<Scalars['String']>;
  /** The new name for this `ControlPlane` */
  name?: InputMaybe<Scalars['String']>;
};

/**
 * UpdateKubernetesResourceInput is the input required to update a Kubernetes
 * resource.
 */
export type UpdateKubernetesResourceInput = {
  /** Patches that should be applied to the Kubernetes resource before updating. */
  patches?: InputMaybe<Array<Patch>>;
  /** The Kubernetes resource to be updated, as raw JSON. */
  unstructured: Scalars['JSON'];
};

/** UpdateKubernetesResourcePayload is the result of updating a Kubernetes resource. */
export type UpdateKubernetesResourcePayload = {
  __typename?: 'UpdateKubernetesResourcePayload';
  /** The updated Kubernetes resource. Null if the update failed. */
  resource?: Maybe<KubernetesResource>;
};

/** The payload for updating an `OrgMember` */
export type UpdateOrganizationMemberPayload = {
  /** The new permission for this `OrgMember` */
  permission: OrganizationPermission;
};

/** The payload for updating an `Organization` */
export type UpdateOrganizationPayload = {
  /** The new display name for the updated `Organization` */
  displayName: Scalars['String'];
};

/** The payload to pass to to `updateRobot` */
export type UpdateRobotPayload = {
  /** The new description for this `Robot` */
  description?: InputMaybe<Scalars['String']>;
  /** The new name for this `Robot` */
  name: Scalars['String'];
};

/** The payload for updating a `Team` */
export type UpdateTeamPayload = {
  /** The new name for this `Team` */
  name: Scalars['String'];
};

/** The payload for updating a `CurrentUser` */
export type UpdateUserInfoPayload = {
  /** A short description for this `CurrentUser` */
  biography?: InputMaybe<Scalars['String']>;
  /** The first name for this `CurrentUser` */
  firstName?: InputMaybe<Scalars['String']>;
  /** The last name for this `CurrentUser` */
  lastName?: InputMaybe<Scalars['String']>;
  /** The location for this `CurrentUser` */
  location?: InputMaybe<Scalars['String']>;
  /** A new password for this `CurrentUser` */
  password?: InputMaybe<Scalars['String']>;
};

/** The payload of `UpdateUserSettings` */
export type UpdateUserSettingsPayload = {
  /** The `account` name of a `Repository` */
  account: Scalars['String'];
  /** The `name` name of the `Repository` */
  name: Scalars['String'];
  /** The `starred` field in the user's settings */
  starred: Scalars['Boolean'];
};

/** A `User` */
export type User = BaseUser & {
  __typename?: 'User';
  /** A short description for this `User` */
  biography?: Maybe<Scalars['String']>;
  /** The `Timestamp` when this `User` was created */
  createdAt: Scalars['Timestamp'];
  /** The `Timestamp` when this `User` was triggered for deletion */
  deletedAt?: Maybe<Scalars['Timestamp']>;
  /** The email for this `User` */
  email: Scalars['String'];
  /** The first name for this `User` */
  firstName?: Maybe<Scalars['String']>;
  /** The unique id for this `User` */
  id: Scalars['ID'];
  /** The last name for this `User` */
  lastName?: Maybe<Scalars['String']>;
  /** The location for this `User` */
  location?: Maybe<Scalars['String']>;
  /** The auth `Token`s for this `User` */
  tokens: Array<Token>;
  /** The `Timestamp` when this `User` was last updated */
  updatedAt?: Maybe<Scalars['Timestamp']>;
  /** The unique username for this `User` */
  username: Scalars['String'];
};

/** A `UserAccount` */
export type UserAccount = Account & {
  __typename?: 'UserAccount';
  /** The `ControlPlaneConfiguration`s owned by this `UserAccount` */
  controlPlaneConfigurations: Array<ControlPlaneConfiguration>;
  /** Get a `UserAccount`s `AccountControlPlane`s */
  controlPlanes: Array<AccountControlPlane>;
  /** The unique id for this `UserAccount` */
  id: Scalars['ID'];
  /** Get a `UserAccount`s `Repository`s */
  repositories: AccountRepositoriesResult;
  /** Get a `Repository` by `id` within a `UserAccount` */
  repository: Repository;
  /** Spaces owned by this `UserAccount` */
  spaces: Array<Space>;
  /** The `User` for this `UserAccount` */
  user: User;
};

/** A `UserAccount` */
export type UserAccountRepositoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** A `UserAccount` */
export type UserAccountRepositoryArgs = {
  name: Scalars['String'];
};

/** A `UserAccount` */
export type UserAccountSpacesArgs = {
  spaceType?: InputMaybe<SpaceType>;
};

/** The user settings of a user on a `Repository` */
export type UserSettings = {
  __typename?: 'UserSettings';
  /** `starred` represents if a repository is starred */
  starred: Scalars['Boolean'];
};

/** A `UserTeam` */
export type UserTeam = {
  __typename?: 'UserTeam';
  /** The unique id for this `UserTeam` */
  id: Scalars['ID'];
  /** The `TeamMemberPermission` for the `CurrentUser` - for this `UserTeam`s `Team`. If permission is `NULL` then the `CurrentUser` is not a member of this `UserTeam`s `Team`. */
  permission?: Maybe<TeamMemberPermission>;
  /** The `Team` associated to this `UserTeam` */
  team: Team;
};

export type SearchResultFragment = {
  __typename: 'RegistrySearchResult';
  account: string;
  description?: string | null;
  displayName: string;
  familyRepositoryId?: string | null;
  group?: string | null;
  hash?: string | null;
  icon: string;
  id: string;
  kind?: string | null;
  name: string;
  tier: RepositoryTier;
  type?: RegistrySearchResultType | null;
  packageType: RegistryPackageType;
  public: boolean;
  repositoryId: string;
  version: string;
  userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
};

export type SearchResultsFragment = {
  __typename: 'RegistrySearchResults';
  count: number;
  size: number;
  page: number;
  results: Array<{
    __typename: 'RegistrySearchResult';
    account: string;
    description?: string | null;
    displayName: string;
    familyRepositoryId?: string | null;
    group?: string | null;
    hash?: string | null;
    icon: string;
    id: string;
    kind?: string | null;
    name: string;
    tier: RepositoryTier;
    type?: RegistrySearchResultType | null;
    packageType: RegistryPackageType;
    public: boolean;
    repositoryId: string;
    version: string;
    userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
  }>;
};

export type XrdFragment = {
  __typename: 'RegistryXRD';
  group: string;
  kind: string;
  referenceableVersion: string;
  versions: Array<string>;
};

export type CrdFragment = {
  __typename: 'RegistryCRD';
  group: string;
  kind: string;
  storageVersion: string;
  versions: Array<string>;
};

export type PackageFragment = {
  __typename: 'RegistryPackage';
  account: string;
  id: string;
  keywords: Array<string>;
  license?: string | null;
  maintainer?: string | null;
  familyRepositoryId?: string | null;
  name: string;
  packageType: RegistryPackageType;
  public: boolean;
  readme?: string | null;
  repositoryId: string;
  tier: RepositoryTier;
  source?: string | null;
  version: string;
  versions?: Array<string> | null;
  company?: string | null;
  definition: any;
  description?: string | null;
  displayName: string;
  icon?: string | null;
  docs?: {
    __typename?: 'RegistryPackageDocs';
    metadata: any;
    tableOfContents: Array<{ __typename?: 'RegistryPackageDocsPage'; name: string; location: string }>;
  } | null;
  resources: Array<
    | { __typename: 'RegistryCRD'; group: string; kind: string; storageVersion: string; versions: Array<string> }
    | { __typename: 'RegistryXRD'; group: string; kind: string; referenceableVersion: string; versions: Array<string> }
  >;
  userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
  compositions?: Array<{
    __typename: 'RegistryComposition';
    name: string;
    resourceCount: number;
    xrdGroup?: string | null;
    xrdKind?: string | null;
  }> | null;
  dependsOn: Array<{
    __typename: 'PackageDependency';
    displayName: string;
    icon?: string | null;
    id: string;
    packageType: RegistryPackageType;
    version: string;
  }>;
};

export type ResourceDetailsFragment = {
  __typename: 'RegistryResourceDetails';
  account: string;
  definition: any;
  examples: Array<string>;
  group: string;
  id: string;
  kind: string;
  repository: string;
  resourceType: RegistryResourceType;
  source?: string | null;
};

export type CompositionDetailsFragment = {
  __typename: 'RegistryCompositionDetails';
  definition: any;
  id: string;
  name: string;
  xrdGroup: string;
  xrdKind: string;
  compositionResources: Array<{
    __typename: 'RegistryCompositionResource';
    group: string;
    kind: string;
    version: string;
  }>;
};

type Account_OrgAccount_Fragment = {
  __typename?: 'OrgAccount';
  id: string;
  organization: {
    __typename?: 'Organization';
    deleteAt?: string | null;
    expiresAt?: string | null;
    id: string;
    displayName: string;
    name: string;
    role: OrganizationPermission;
    licenseLimits: Array<{
      __typename?: 'OrganizationLicenseLimit';
      kind: OrganizationLicenseLimitKind;
      count: number;
      max: number;
    }>;
  };
};

type Account_UserAccount_Fragment = {
  __typename?: 'UserAccount';
  id: string;
  user: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email: string;
  };
};

export type AccountFragment = Account_OrgAccount_Fragment | Account_UserAccount_Fragment;

export type RepositoryFragment = {
  __typename?: 'Repository';
  icon: string;
  currentVersion?: string | null;
  id: string;
  name: string;
  public: boolean;
  tier: RepositoryTier;
  packageType?: RegistryPackageType | null;
  versions?: Array<{
    __typename?: 'RepositoryVersion';
    createdAt: string;
    id: string;
    name: string;
    state: PackageState;
    stateDetail?: string | null;
    digest: string;
  }> | null;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  payload: UpdateUserSettingsPayload;
}>;

export type UpdateUserSettingsMutation = { __typename?: 'Mutation'; userSettings: boolean };

export type CreateOrUpdateRepositoryMutationVariables = Exact<{
  payload: CreateOrUpdateRepositoryPayload;
}>;

export type CreateOrUpdateRepositoryMutation = {
  __typename?: 'Mutation';
  repository: {
    __typename?: 'Repository';
    icon: string;
    currentVersion?: string | null;
    id: string;
    name: string;
    public: boolean;
    tier: RepositoryTier;
    packageType?: RegistryPackageType | null;
    versions?: Array<{
      __typename?: 'RepositoryVersion';
      createdAt: string;
      id: string;
      name: string;
      state: PackageState;
      stateDetail?: string | null;
      digest: string;
    }> | null;
  };
};

export type DeleteRepositoryMutationVariables = Exact<{
  payload: DeleteRepositoryPayload;
}>;

export type DeleteRepositoryMutation = {
  __typename?: 'Mutation';
  repository: { __typename?: 'Repository'; id: string };
};

export type DeleteRepositoryVersionMutationVariables = Exact<{
  payload: DeleteRepositoryVersionPayload;
}>;

export type DeleteRepositoryVersionMutation = {
  __typename?: 'Mutation';
  repositoryVersion: { __typename?: 'RepositoryVersion'; id: string };
};

export type SearchQueryVariables = Exact<{
  packageType: RegistryPackageType;
  packageQuery?: InputMaybe<Scalars['String']>;
  packageTier?: InputMaybe<RepositoryTier>;
  packageVisibility?: InputMaybe<Scalars['Boolean']>;
  searchPage?: InputMaybe<Scalars['Int']>;
  searchSize?: InputMaybe<Scalars['Int']>;
  starred?: InputMaybe<Scalars['Boolean']>;
  accountName?: InputMaybe<Scalars['String']>;
  excludeFamily?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['ID']>;
  resources?: InputMaybe<Scalars['Boolean']>;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    search: {
      __typename: 'RegistrySearchResults';
      count: number;
      size: number;
      page: number;
      results: Array<{
        __typename: 'RegistrySearchResult';
        account: string;
        description?: string | null;
        displayName: string;
        familyRepositoryId?: string | null;
        group?: string | null;
        hash?: string | null;
        icon: string;
        id: string;
        kind?: string | null;
        name: string;
        tier: RepositoryTier;
        type?: RegistrySearchResultType | null;
        packageType: RegistryPackageType;
        public: boolean;
        repositoryId: string;
        version: string;
        userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      }>;
    };
  };
};

export type FeaturedPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type FeaturedPackagesQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    configurations: {
      __typename: 'RegistrySearchResults';
      count: number;
      size: number;
      page: number;
      results: Array<{
        __typename: 'RegistrySearchResult';
        account: string;
        description?: string | null;
        displayName: string;
        familyRepositoryId?: string | null;
        group?: string | null;
        hash?: string | null;
        icon: string;
        id: string;
        kind?: string | null;
        name: string;
        tier: RepositoryTier;
        type?: RegistrySearchResultType | null;
        packageType: RegistryPackageType;
        public: boolean;
        repositoryId: string;
        version: string;
        userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      }>;
    };
    providers: {
      __typename: 'RegistrySearchResults';
      count: number;
      size: number;
      page: number;
      results: Array<{
        __typename: 'RegistrySearchResult';
        account: string;
        description?: string | null;
        displayName: string;
        familyRepositoryId?: string | null;
        group?: string | null;
        hash?: string | null;
        icon: string;
        id: string;
        kind?: string | null;
        name: string;
        tier: RepositoryTier;
        type?: RegistrySearchResultType | null;
        packageType: RegistryPackageType;
        public: boolean;
        repositoryId: string;
        version: string;
        userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      }>;
    };
  };
};

export type GetPackageQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
}>;

export type GetPackageQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: {
      __typename: 'RegistryPackage';
      account: string;
      id: string;
      keywords: Array<string>;
      license?: string | null;
      maintainer?: string | null;
      familyRepositoryId?: string | null;
      name: string;
      packageType: RegistryPackageType;
      public: boolean;
      readme?: string | null;
      repositoryId: string;
      tier: RepositoryTier;
      source?: string | null;
      version: string;
      versions?: Array<string> | null;
      company?: string | null;
      definition: any;
      description?: string | null;
      displayName: string;
      icon?: string | null;
      docs?: {
        __typename?: 'RegistryPackageDocs';
        metadata: any;
        tableOfContents: Array<{ __typename?: 'RegistryPackageDocsPage'; name: string; location: string }>;
      } | null;
      resources: Array<
        | { __typename: 'RegistryCRD'; group: string; kind: string; storageVersion: string; versions: Array<string> }
        | {
            __typename: 'RegistryXRD';
            group: string;
            kind: string;
            referenceableVersion: string;
            versions: Array<string>;
          }
      >;
      userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      compositions?: Array<{
        __typename: 'RegistryComposition';
        name: string;
        resourceCount: number;
        xrdGroup?: string | null;
        xrdKind?: string | null;
      }> | null;
      dependsOn: Array<{
        __typename: 'PackageDependency';
        displayName: string;
        icon?: string | null;
        id: string;
        packageType: RegistryPackageType;
        version: string;
      }>;
    };
  };
};

export type GetPackageUserSettingsQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
}>;

export type GetPackageUserSettingsQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: {
      __typename: 'RegistryPackage';
      id: string;
      userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
    };
  };
};

export type GetPackageVersionQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
}>;

export type GetPackageVersionQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: { __typename?: 'RegistryPackage'; version: string; packageType: RegistryPackageType };
  };
};

export type GetPackageResourcesQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
}>;

export type GetPackageResourcesQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: {
      __typename?: 'RegistryPackage';
      resources: Array<
        | { __typename: 'RegistryCRD'; group: string; kind: string; storageVersion: string; versions: Array<string> }
        | {
            __typename: 'RegistryXRD';
            group: string;
            kind: string;
            referenceableVersion: string;
            versions: Array<string>;
          }
      >;
    };
  };
};

export type GetResourceDetailsQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  resourceGroup: Scalars['String'];
  resourceKind: Scalars['String'];
}>;

export type GetResourceDetailsQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: {
      __typename: 'RegistryPackage';
      account: string;
      id: string;
      keywords: Array<string>;
      license?: string | null;
      maintainer?: string | null;
      familyRepositoryId?: string | null;
      name: string;
      packageType: RegistryPackageType;
      public: boolean;
      readme?: string | null;
      repositoryId: string;
      tier: RepositoryTier;
      source?: string | null;
      version: string;
      versions?: Array<string> | null;
      company?: string | null;
      definition: any;
      description?: string | null;
      displayName: string;
      icon?: string | null;
      docs?: {
        __typename?: 'RegistryPackageDocs';
        metadata: any;
        tableOfContents: Array<{ __typename?: 'RegistryPackageDocsPage'; name: string; location: string }>;
      } | null;
      resources: Array<
        | { __typename: 'RegistryCRD'; group: string; kind: string; storageVersion: string; versions: Array<string> }
        | {
            __typename: 'RegistryXRD';
            group: string;
            kind: string;
            referenceableVersion: string;
            versions: Array<string>;
          }
      >;
      userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      compositions?: Array<{
        __typename: 'RegistryComposition';
        name: string;
        resourceCount: number;
        xrdGroup?: string | null;
        xrdKind?: string | null;
      }> | null;
      dependsOn: Array<{
        __typename: 'PackageDependency';
        displayName: string;
        icon?: string | null;
        id: string;
        packageType: RegistryPackageType;
        version: string;
      }>;
    };
    resourceDetails: {
      __typename: 'RegistryResourceDetails';
      account: string;
      definition: any;
      examples: Array<string>;
      group: string;
      id: string;
      kind: string;
      repository: string;
      resourceType: RegistryResourceType;
      source?: string | null;
    };
  };
};

export type GetCompositionDetailsQueryVariables = Exact<{
  packageAccount: Scalars['String'];
  packageName: Scalars['String'];
  packageVersion: Scalars['String'];
  compositionName: Scalars['String'];
  compositionXrdGroup: Scalars['String'];
  compositionXrdKind: Scalars['String'];
}>;

export type GetCompositionDetailsQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    package: {
      __typename: 'RegistryPackage';
      account: string;
      id: string;
      keywords: Array<string>;
      license?: string | null;
      maintainer?: string | null;
      familyRepositoryId?: string | null;
      name: string;
      packageType: RegistryPackageType;
      public: boolean;
      readme?: string | null;
      repositoryId: string;
      tier: RepositoryTier;
      source?: string | null;
      version: string;
      versions?: Array<string> | null;
      company?: string | null;
      definition: any;
      description?: string | null;
      displayName: string;
      icon?: string | null;
      docs?: {
        __typename?: 'RegistryPackageDocs';
        metadata: any;
        tableOfContents: Array<{ __typename?: 'RegistryPackageDocsPage'; name: string; location: string }>;
      } | null;
      resources: Array<
        | { __typename: 'RegistryCRD'; group: string; kind: string; storageVersion: string; versions: Array<string> }
        | {
            __typename: 'RegistryXRD';
            group: string;
            kind: string;
            referenceableVersion: string;
            versions: Array<string>;
          }
      >;
      userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      compositions?: Array<{
        __typename: 'RegistryComposition';
        name: string;
        resourceCount: number;
        xrdGroup?: string | null;
        xrdKind?: string | null;
      }> | null;
      dependsOn: Array<{
        __typename: 'PackageDependency';
        displayName: string;
        icon?: string | null;
        id: string;
        packageType: RegistryPackageType;
        version: string;
      }>;
    };
    compositionDetails: {
      __typename: 'RegistryCompositionDetails';
      definition: any;
      id: string;
      name: string;
      xrdGroup: string;
      xrdKind: string;
      compositionResources: Array<{
        __typename: 'RegistryCompositionResource';
        group: string;
        kind: string;
        version: string;
      }>;
    };
  };
};

export type SitemapQueryVariables = Exact<{ [key: string]: never }>;

export type SitemapQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    search: {
      __typename?: 'RegistrySearchResults';
      results: Array<{
        __typename?: 'RegistrySearchResult';
        account: string;
        name: string;
        packageType: RegistryPackageType;
        version: string;
      }>;
    };
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'CurrentUser';
    username: string;
    email: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type GetPrivatePackagesQueryVariables = Exact<{
  searchPage?: InputMaybe<Scalars['Int']>;
  searchSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetPrivatePackagesQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'RegistryQuery';
    search: {
      __typename: 'RegistrySearchResults';
      count: number;
      size: number;
      page: number;
      results: Array<{
        __typename: 'RegistrySearchResult';
        account: string;
        description?: string | null;
        displayName: string;
        familyRepositoryId?: string | null;
        group?: string | null;
        hash?: string | null;
        icon: string;
        id: string;
        kind?: string | null;
        name: string;
        tier: RepositoryTier;
        type?: RegistrySearchResultType | null;
        packageType: RegistryPackageType;
        public: boolean;
        repositoryId: string;
        version: string;
        userSettings?: { __typename?: 'UserSettings'; starred: boolean } | null;
      }>;
    };
  };
};

export type GetAllAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAccountsQuery = {
  __typename?: 'Query';
  accounts: Array<
    | {
        __typename?: 'OrgAccount';
        id: string;
        organization: {
          __typename?: 'Organization';
          deleteAt?: string | null;
          expiresAt?: string | null;
          id: string;
          displayName: string;
          name: string;
          role: OrganizationPermission;
          licenseLimits: Array<{
            __typename?: 'OrganizationLicenseLimit';
            kind: OrganizationLicenseLimitKind;
            count: number;
            max: number;
          }>;
        };
      }
    | {
        __typename?: 'UserAccount';
        id: string;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          username: string;
          email: string;
        };
      }
  >;
};

export type GetRepositoryQueryVariables = Exact<{
  account: Scalars['ID'];
  repoName: Scalars['String'];
}>;

export type GetRepositoryQuery = {
  __typename?: 'Query';
  account:
    | {
        __typename?: 'OrgAccount';
        id: string;
        repository: {
          __typename?: 'Repository';
          icon: string;
          currentVersion?: string | null;
          id: string;
          name: string;
          public: boolean;
          tier: RepositoryTier;
          packageType?: RegistryPackageType | null;
          versions?: Array<{
            __typename?: 'RepositoryVersion';
            createdAt: string;
            id: string;
            name: string;
            state: PackageState;
            stateDetail?: string | null;
            digest: string;
          }> | null;
        };
      }
    | {
        __typename?: 'UserAccount';
        id: string;
        repository: {
          __typename?: 'Repository';
          icon: string;
          currentVersion?: string | null;
          id: string;
          name: string;
          public: boolean;
          tier: RepositoryTier;
          packageType?: RegistryPackageType | null;
          versions?: Array<{
            __typename?: 'RepositoryVersion';
            createdAt: string;
            id: string;
            name: string;
            state: PackageState;
            stateDetail?: string | null;
            digest: string;
          }> | null;
        };
      };
};

export type GetRepositoriesQueryVariables = Exact<{
  account: Scalars['ID'];
}>;

export type GetRepositoriesQuery = {
  __typename?: 'Query';
  account:
    | {
        __typename?: 'OrgAccount';
        id: string;
        repositories: {
          __typename?: 'AccountRepositoriesResult';
          nodes: Array<{
            __typename?: 'Repository';
            createdAt?: string | null;
            currentVersion?: string | null;
            icon: string;
            id: string;
            name: string;
            tier: RepositoryTier;
            packageType?: RegistryPackageType | null;
            public: boolean;
          }>;
        };
      }
    | {
        __typename?: 'UserAccount';
        id: string;
        repositories: {
          __typename?: 'AccountRepositoriesResult';
          nodes: Array<{
            __typename?: 'Repository';
            createdAt?: string | null;
            currentVersion?: string | null;
            icon: string;
            id: string;
            name: string;
            tier: RepositoryTier;
            packageType?: RegistryPackageType | null;
            public: boolean;
          }>;
        };
      };
};

export const SearchResultFragmentDoc = gql`
  fragment searchResult on RegistrySearchResult {
    __typename
    account
    description
    displayName
    familyRepositoryId
    group
    hash
    icon
    id
    kind
    name
    tier
    type
    packageType
    public
    repositoryId
    version
    userSettings {
      starred
    }
  }
`;
export const SearchResultsFragmentDoc = gql`
  fragment searchResults on RegistrySearchResults {
    __typename
    count
    size
    page
    results {
      ...searchResult
    }
  }
  ${SearchResultFragmentDoc}
`;
export const CrdFragmentDoc = gql`
  fragment crd on RegistryCRD {
    __typename
    group
    kind
    storageVersion
    versions
  }
`;
export const XrdFragmentDoc = gql`
  fragment xrd on RegistryXRD {
    __typename
    group
    kind
    referenceableVersion
    versions
  }
`;
export const PackageFragmentDoc = gql`
  fragment package on RegistryPackage {
    __typename
    account
    id
    keywords
    license
    maintainer
    docs {
      tableOfContents {
        name
        location
      }
      metadata
    }
    familyRepositoryId
    name
    packageType
    public
    readme
    repositoryId
    tier
    resources {
      ... on RegistryCRD {
        ...crd
      }
      ... on RegistryXRD {
        ...xrd
      }
    }
    source
    version
    versions
    userSettings {
      starred
    }
    company
    compositions {
      __typename
      name
      resourceCount
      xrdGroup
      xrdKind
    }
    definition
    dependsOn {
      __typename
      displayName
      icon
      id
      packageType
      version
    }
    description
    displayName
    icon
  }
  ${CrdFragmentDoc}
  ${XrdFragmentDoc}
`;
export const ResourceDetailsFragmentDoc = gql`
  fragment resourceDetails on RegistryResourceDetails {
    __typename
    account
    definition
    examples
    group
    id
    kind
    repository
    resourceType
    source
  }
`;
export const CompositionDetailsFragmentDoc = gql`
  fragment compositionDetails on RegistryCompositionDetails {
    __typename
    definition
    id
    name
    xrdGroup
    xrdKind
    compositionResources {
      __typename
      group
      kind
      version
    }
  }
`;
export const AccountFragmentDoc = gql`
  fragment account on Account {
    id
    ... on UserAccount {
      user {
        id
        firstName
        lastName
        username
        email
      }
    }
    ... on OrgAccount {
      organization {
        deleteAt
        expiresAt
        id
        displayName
        name
        role
        licenseLimits {
          kind
          count
          max
        }
      }
    }
  }
`;
export const RepositoryFragmentDoc = gql`
  fragment repository on Repository {
    icon
    currentVersion
    versions {
      createdAt
      id
      name
      state
      stateDetail
      digest
    }
    id
    name
    public
    tier
    packageType
  }
`;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings($payload: UpdateUserSettingsPayload!) {
    userSettings: updateUserSettings(payload: $payload)
  }
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UpdateUserSettingsDocument,
    options,
  );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const CreateOrUpdateRepositoryDocument = gql`
  mutation CreateOrUpdateRepository($payload: CreateOrUpdateRepositoryPayload!) {
    repository: createOrUpdateRepository(payload: $payload) {
      ...repository
    }
  }
  ${RepositoryFragmentDoc}
`;
export type CreateOrUpdateRepositoryMutationFn = Apollo.MutationFunction<
  CreateOrUpdateRepositoryMutation,
  CreateOrUpdateRepositoryMutationVariables
>;

/**
 * __useCreateOrUpdateRepositoryMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateRepositoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateRepositoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateRepositoryMutation, { data, loading, error }] = useCreateOrUpdateRepositoryMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrUpdateRepositoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateRepositoryMutation, CreateOrUpdateRepositoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrUpdateRepositoryMutation, CreateOrUpdateRepositoryMutationVariables>(
    CreateOrUpdateRepositoryDocument,
    options,
  );
}
export type CreateOrUpdateRepositoryMutationHookResult = ReturnType<typeof useCreateOrUpdateRepositoryMutation>;
export type CreateOrUpdateRepositoryMutationResult = Apollo.MutationResult<CreateOrUpdateRepositoryMutation>;
export type CreateOrUpdateRepositoryMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateRepositoryMutation,
  CreateOrUpdateRepositoryMutationVariables
>;
export const DeleteRepositoryDocument = gql`
  mutation DeleteRepository($payload: DeleteRepositoryPayload!) {
    repository: deleteRepository(payload: $payload) {
      id
    }
  }
`;
export type DeleteRepositoryMutationFn = Apollo.MutationFunction<
  DeleteRepositoryMutation,
  DeleteRepositoryMutationVariables
>;

/**
 * __useDeleteRepositoryMutation__
 *
 * To run a mutation, you first call `useDeleteRepositoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRepositoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRepositoryMutation, { data, loading, error }] = useDeleteRepositoryMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useDeleteRepositoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRepositoryMutation, DeleteRepositoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRepositoryMutation, DeleteRepositoryMutationVariables>(
    DeleteRepositoryDocument,
    options,
  );
}
export type DeleteRepositoryMutationHookResult = ReturnType<typeof useDeleteRepositoryMutation>;
export type DeleteRepositoryMutationResult = Apollo.MutationResult<DeleteRepositoryMutation>;
export type DeleteRepositoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteRepositoryMutation,
  DeleteRepositoryMutationVariables
>;
export const DeleteRepositoryVersionDocument = gql`
  mutation DeleteRepositoryVersion($payload: DeleteRepositoryVersionPayload!) {
    repositoryVersion: deleteRepositoryVersion(payload: $payload) {
      id
    }
  }
`;
export type DeleteRepositoryVersionMutationFn = Apollo.MutationFunction<
  DeleteRepositoryVersionMutation,
  DeleteRepositoryVersionMutationVariables
>;

/**
 * __useDeleteRepositoryVersionMutation__
 *
 * To run a mutation, you first call `useDeleteRepositoryVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRepositoryVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRepositoryVersionMutation, { data, loading, error }] = useDeleteRepositoryVersionMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useDeleteRepositoryVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRepositoryVersionMutation, DeleteRepositoryVersionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRepositoryVersionMutation, DeleteRepositoryVersionMutationVariables>(
    DeleteRepositoryVersionDocument,
    options,
  );
}
export type DeleteRepositoryVersionMutationHookResult = ReturnType<typeof useDeleteRepositoryVersionMutation>;
export type DeleteRepositoryVersionMutationResult = Apollo.MutationResult<DeleteRepositoryVersionMutation>;
export type DeleteRepositoryVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRepositoryVersionMutation,
  DeleteRepositoryVersionMutationVariables
>;
export const SearchDocument = gql`
  query Search(
    $packageType: RegistryPackageType!
    $packageQuery: String
    $packageTier: RepositoryTier
    $packageVisibility: Boolean
    $searchPage: Int
    $searchSize: Int
    $starred: Boolean
    $accountName: String
    $excludeFamily: Boolean
    $family: ID
    $resources: Boolean
  ) {
    registry {
      search(
        payload: {
          packageType: $packageType
          query: $packageQuery
          tier: $packageTier
          public: $packageVisibility
          page: $searchPage
          size: $searchSize
          starred: $starred
          accountName: $accountName
          excludeFamily: $excludeFamily
          family: $family
          resources: $resources
        }
      ) {
        ...searchResults
      }
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      packageType: // value for 'packageType'
 *      packageQuery: // value for 'packageQuery'
 *      packageTier: // value for 'packageTier'
 *      packageVisibility: // value for 'packageVisibility'
 *      searchPage: // value for 'searchPage'
 *      searchSize: // value for 'searchSize'
 *      starred: // value for 'starred'
 *      accountName: // value for 'accountName'
 *      excludeFamily: // value for 'excludeFamily'
 *      family: // value for 'family'
 *      resources: // value for 'resources'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const FeaturedPackagesDocument = gql`
  query FeaturedPackages {
    registry {
      configurations: search(payload: { size: 3, public: true, packageType: CONFIGURATION }) {
        ...searchResults
      }
      providers: search(payload: { size: 3, public: true, packageType: PROVIDER }) {
        ...searchResults
      }
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useFeaturedPackagesQuery__
 *
 * To run a query within a React component, call `useFeaturedPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<FeaturedPackagesQuery, FeaturedPackagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeaturedPackagesQuery, FeaturedPackagesQueryVariables>(FeaturedPackagesDocument, options);
}
export function useFeaturedPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FeaturedPackagesQuery, FeaturedPackagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FeaturedPackagesQuery, FeaturedPackagesQueryVariables>(FeaturedPackagesDocument, options);
}
export type FeaturedPackagesQueryHookResult = ReturnType<typeof useFeaturedPackagesQuery>;
export type FeaturedPackagesLazyQueryHookResult = ReturnType<typeof useFeaturedPackagesLazyQuery>;
export type FeaturedPackagesQueryResult = Apollo.QueryResult<FeaturedPackagesQuery, FeaturedPackagesQueryVariables>;
export const GetPackageDocument = gql`
  query GetPackage($packageAccount: String!, $packageName: String!, $packageVersion: String!) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName, version: $packageVersion }) {
        ...package
      }
    }
  }
  ${PackageFragmentDoc}
`;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *      packageVersion: // value for 'packageVersion'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export function useGetPackageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
}
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;
export const GetPackageUserSettingsDocument = gql`
  query GetPackageUserSettings($packageAccount: String!, $packageName: String!, $packageVersion: String!) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName, version: $packageVersion }) {
        __typename
        id
        userSettings {
          starred
        }
      }
    }
  }
`;

/**
 * __useGetPackageUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetPackageUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageUserSettingsQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *      packageVersion: // value for 'packageVersion'
 *   },
 * });
 */
export function useGetPackageUserSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPackageUserSettingsQuery, GetPackageUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageUserSettingsQuery, GetPackageUserSettingsQueryVariables>(
    GetPackageUserSettingsDocument,
    options,
  );
}
export function useGetPackageUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackageUserSettingsQuery, GetPackageUserSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackageUserSettingsQuery, GetPackageUserSettingsQueryVariables>(
    GetPackageUserSettingsDocument,
    options,
  );
}
export type GetPackageUserSettingsQueryHookResult = ReturnType<typeof useGetPackageUserSettingsQuery>;
export type GetPackageUserSettingsLazyQueryHookResult = ReturnType<typeof useGetPackageUserSettingsLazyQuery>;
export type GetPackageUserSettingsQueryResult = Apollo.QueryResult<
  GetPackageUserSettingsQuery,
  GetPackageUserSettingsQueryVariables
>;
export const GetPackageVersionDocument = gql`
  query GetPackageVersion($packageAccount: String!, $packageName: String!) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName }) {
        version
        packageType
      }
    }
  }
`;

/**
 * __useGetPackageVersionQuery__
 *
 * To run a query within a React component, call `useGetPackageVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageVersionQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *   },
 * });
 */
export function useGetPackageVersionQuery(
  baseOptions: Apollo.QueryHookOptions<GetPackageVersionQuery, GetPackageVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageVersionQuery, GetPackageVersionQueryVariables>(GetPackageVersionDocument, options);
}
export function useGetPackageVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackageVersionQuery, GetPackageVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackageVersionQuery, GetPackageVersionQueryVariables>(
    GetPackageVersionDocument,
    options,
  );
}
export type GetPackageVersionQueryHookResult = ReturnType<typeof useGetPackageVersionQuery>;
export type GetPackageVersionLazyQueryHookResult = ReturnType<typeof useGetPackageVersionLazyQuery>;
export type GetPackageVersionQueryResult = Apollo.QueryResult<GetPackageVersionQuery, GetPackageVersionQueryVariables>;
export const GetPackageResourcesDocument = gql`
  query GetPackageResources($packageAccount: String!, $packageName: String!, $packageVersion: String!) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName, version: $packageVersion }) {
        resources {
          ... on RegistryCRD {
            ...crd
          }
          ... on RegistryXRD {
            ...xrd
          }
        }
      }
    }
  }
  ${CrdFragmentDoc}
  ${XrdFragmentDoc}
`;

/**
 * __useGetPackageResourcesQuery__
 *
 * To run a query within a React component, call `useGetPackageResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageResourcesQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *      packageVersion: // value for 'packageVersion'
 *   },
 * });
 */
export function useGetPackageResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPackageResourcesQuery, GetPackageResourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageResourcesQuery, GetPackageResourcesQueryVariables>(
    GetPackageResourcesDocument,
    options,
  );
}
export function useGetPackageResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPackageResourcesQuery, GetPackageResourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackageResourcesQuery, GetPackageResourcesQueryVariables>(
    GetPackageResourcesDocument,
    options,
  );
}
export type GetPackageResourcesQueryHookResult = ReturnType<typeof useGetPackageResourcesQuery>;
export type GetPackageResourcesLazyQueryHookResult = ReturnType<typeof useGetPackageResourcesLazyQuery>;
export type GetPackageResourcesQueryResult = Apollo.QueryResult<
  GetPackageResourcesQuery,
  GetPackageResourcesQueryVariables
>;
export const GetResourceDetailsDocument = gql`
  query GetResourceDetails(
    $packageAccount: String!
    $packageName: String!
    $packageVersion: String!
    $resourceGroup: String!
    $resourceKind: String!
  ) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName, version: $packageVersion }) {
        ...package
      }
      resourceDetails(
        payload: {
          account: $packageAccount
          repository: $packageName
          version: $packageVersion
          group: $resourceGroup
          kind: $resourceKind
        }
      ) {
        ...resourceDetails
      }
    }
  }
  ${PackageFragmentDoc}
  ${ResourceDetailsFragmentDoc}
`;

/**
 * __useGetResourceDetailsQuery__
 *
 * To run a query within a React component, call `useGetResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceDetailsQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *      packageVersion: // value for 'packageVersion'
 *      resourceGroup: // value for 'resourceGroup'
 *      resourceKind: // value for 'resourceKind'
 *   },
 * });
 */
export function useGetResourceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetResourceDetailsQuery, GetResourceDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResourceDetailsQuery, GetResourceDetailsQueryVariables>(
    GetResourceDetailsDocument,
    options,
  );
}
export function useGetResourceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetResourceDetailsQuery, GetResourceDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResourceDetailsQuery, GetResourceDetailsQueryVariables>(
    GetResourceDetailsDocument,
    options,
  );
}
export type GetResourceDetailsQueryHookResult = ReturnType<typeof useGetResourceDetailsQuery>;
export type GetResourceDetailsLazyQueryHookResult = ReturnType<typeof useGetResourceDetailsLazyQuery>;
export type GetResourceDetailsQueryResult = Apollo.QueryResult<
  GetResourceDetailsQuery,
  GetResourceDetailsQueryVariables
>;
export const GetCompositionDetailsDocument = gql`
  query GetCompositionDetails(
    $packageAccount: String!
    $packageName: String!
    $packageVersion: String!
    $compositionName: String!
    $compositionXrdGroup: String!
    $compositionXrdKind: String!
  ) {
    registry {
      package(payload: { account: $packageAccount, repository: $packageName, version: $packageVersion }) {
        ...package
      }
      compositionDetails(
        payload: {
          account: $packageAccount
          repository: $packageName
          version: $packageVersion
          name: $compositionName
          xrdGroup: $compositionXrdGroup
          xrdKind: $compositionXrdKind
        }
      ) {
        ...compositionDetails
      }
    }
  }
  ${PackageFragmentDoc}
  ${CompositionDetailsFragmentDoc}
`;

/**
 * __useGetCompositionDetailsQuery__
 *
 * To run a query within a React component, call `useGetCompositionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompositionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompositionDetailsQuery({
 *   variables: {
 *      packageAccount: // value for 'packageAccount'
 *      packageName: // value for 'packageName'
 *      packageVersion: // value for 'packageVersion'
 *      compositionName: // value for 'compositionName'
 *      compositionXrdGroup: // value for 'compositionXrdGroup'
 *      compositionXrdKind: // value for 'compositionXrdKind'
 *   },
 * });
 */
export function useGetCompositionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompositionDetailsQuery, GetCompositionDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompositionDetailsQuery, GetCompositionDetailsQueryVariables>(
    GetCompositionDetailsDocument,
    options,
  );
}
export function useGetCompositionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompositionDetailsQuery, GetCompositionDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompositionDetailsQuery, GetCompositionDetailsQueryVariables>(
    GetCompositionDetailsDocument,
    options,
  );
}
export type GetCompositionDetailsQueryHookResult = ReturnType<typeof useGetCompositionDetailsQuery>;
export type GetCompositionDetailsLazyQueryHookResult = ReturnType<typeof useGetCompositionDetailsLazyQuery>;
export type GetCompositionDetailsQueryResult = Apollo.QueryResult<
  GetCompositionDetailsQuery,
  GetCompositionDetailsQueryVariables
>;
export const SitemapDocument = gql`
  query Sitemap {
    registry {
      search(payload: { size: 100 }) {
        results {
          account
          name
          packageType
          version
        }
      }
    }
  }
`;

/**
 * __useSitemapQuery__
 *
 * To run a query within a React component, call `useSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitemapQuery(baseOptions?: Apollo.QueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export function useSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export type SitemapQueryHookResult = ReturnType<typeof useSitemapQuery>;
export type SitemapLazyQueryHookResult = ReturnType<typeof useSitemapLazyQuery>;
export type SitemapQueryResult = Apollo.QueryResult<SitemapQuery, SitemapQueryVariables>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      username
      email
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetPrivatePackagesDocument = gql`
  query GetPrivatePackages($searchPage: Int, $searchSize: Int) {
    registry {
      search(payload: { public: false, page: $searchPage, size: $searchSize }) {
        ...searchResults
      }
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useGetPrivatePackagesQuery__
 *
 * To run a query within a React component, call `useGetPrivatePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivatePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivatePackagesQuery({
 *   variables: {
 *      searchPage: // value for 'searchPage'
 *      searchSize: // value for 'searchSize'
 *   },
 * });
 */
export function useGetPrivatePackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPrivatePackagesQuery, GetPrivatePackagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivatePackagesQuery, GetPrivatePackagesQueryVariables>(
    GetPrivatePackagesDocument,
    options,
  );
}
export function useGetPrivatePackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrivatePackagesQuery, GetPrivatePackagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivatePackagesQuery, GetPrivatePackagesQueryVariables>(
    GetPrivatePackagesDocument,
    options,
  );
}
export type GetPrivatePackagesQueryHookResult = ReturnType<typeof useGetPrivatePackagesQuery>;
export type GetPrivatePackagesLazyQueryHookResult = ReturnType<typeof useGetPrivatePackagesLazyQuery>;
export type GetPrivatePackagesQueryResult = Apollo.QueryResult<
  GetPrivatePackagesQuery,
  GetPrivatePackagesQueryVariables
>;
export const GetAllAccountsDocument = gql`
  query GetAllAccounts {
    accounts {
      ...account
    }
  }
  ${AccountFragmentDoc}
`;

/**
 * __useGetAllAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
}
export function useGetAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountsQuery, GetAllAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAccountsQuery, GetAllAccountsQueryVariables>(GetAllAccountsDocument, options);
}
export type GetAllAccountsQueryHookResult = ReturnType<typeof useGetAllAccountsQuery>;
export type GetAllAccountsLazyQueryHookResult = ReturnType<typeof useGetAllAccountsLazyQuery>;
export type GetAllAccountsQueryResult = Apollo.QueryResult<GetAllAccountsQuery, GetAllAccountsQueryVariables>;
export const GetRepositoryDocument = gql`
  query GetRepository($account: ID!, $repoName: String!) {
    account(id: $account) {
      id
      repository(name: $repoName) {
        ...repository
      }
    }
  }
  ${RepositoryFragmentDoc}
`;

/**
 * __useGetRepositoryQuery__
 *
 * To run a query within a React component, call `useGetRepositoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepositoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepositoryQuery({
 *   variables: {
 *      account: // value for 'account'
 *      repoName: // value for 'repoName'
 *   },
 * });
 */
export function useGetRepositoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetRepositoryQuery, GetRepositoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRepositoryQuery, GetRepositoryQueryVariables>(GetRepositoryDocument, options);
}
export function useGetRepositoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRepositoryQuery, GetRepositoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRepositoryQuery, GetRepositoryQueryVariables>(GetRepositoryDocument, options);
}
export type GetRepositoryQueryHookResult = ReturnType<typeof useGetRepositoryQuery>;
export type GetRepositoryLazyQueryHookResult = ReturnType<typeof useGetRepositoryLazyQuery>;
export type GetRepositoryQueryResult = Apollo.QueryResult<GetRepositoryQuery, GetRepositoryQueryVariables>;
export const GetRepositoriesDocument = gql`
  query GetRepositories($account: ID!) {
    account(id: $account) {
      id
      repositories(size: 100) {
        nodes {
          createdAt
          currentVersion
          icon
          id
          name
          tier
          packageType
          public
        }
      }
    }
  }
`;

/**
 * __useGetRepositoriesQuery__
 *
 * To run a query within a React component, call `useGetRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepositoriesQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetRepositoriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRepositoriesQuery, GetRepositoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRepositoriesQuery, GetRepositoriesQueryVariables>(GetRepositoriesDocument, options);
}
export function useGetRepositoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRepositoriesQuery, GetRepositoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRepositoriesQuery, GetRepositoriesQueryVariables>(GetRepositoriesDocument, options);
}
export type GetRepositoriesQueryHookResult = ReturnType<typeof useGetRepositoriesQuery>;
export type GetRepositoriesLazyQueryHookResult = ReturnType<typeof useGetRepositoriesLazyQuery>;
export type GetRepositoriesQueryResult = Apollo.QueryResult<GetRepositoriesQuery, GetRepositoriesQueryVariables>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolversTypes['OrgAccount'] | ResolversTypes['UserAccount'];
  AccountControlPlane: ResolverTypeWrapper<AccountControlPlane>;
  AccountRepositoriesResult: ResolverTypeWrapper<AccountRepositoriesResult>;
  AddTeamRobotPayload: AddTeamRobotPayload;
  AuthMutation: ResolverTypeWrapper<AuthMutation>;
  AuthQuery: ResolverTypeWrapper<AuthQuery>;
  BaseUser: ResolversTypes['CurrentUser'] | ResolversTypes['User'];
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ChangePasswordPayload: ChangePasswordPayload;
  ClaimInstanceDetails: ResolverTypeWrapper<ClaimInstanceDetails>;
  ClearTeamControlPlanePermissionPayload: ClearTeamControlPlanePermissionPayload;
  ClearTeamRepositoryPermissionPayload: ClearTeamRepositoryPermissionPayload;
  ClearTeamUserPermissionPayload: ClearTeamUserPermissionPayload;
  CompositeResource: ResolverTypeWrapper<CompositeResource>;
  CompositeResourceClaim: ResolverTypeWrapper<CompositeResourceClaim>;
  CompositeResourceClaimConnection: ResolverTypeWrapper<CompositeResourceClaimConnection>;
  CompositeResourceClaimConnectionDetails: ResolverTypeWrapper<CompositeResourceClaimConnectionDetails>;
  CompositeResourceClaimSpec: ResolverTypeWrapper<CompositeResourceClaimSpec>;
  CompositeResourceClaimStatus: ResolverTypeWrapper<CompositeResourceClaimStatus>;
  CompositeResourceConnection: ResolverTypeWrapper<CompositeResourceConnection>;
  CompositeResourceConnectionDetails: ResolverTypeWrapper<CompositeResourceConnectionDetails>;
  CompositeResourceDefinition: ResolverTypeWrapper<CompositeResourceDefinition>;
  CompositeResourceDefinitionConnection: ResolverTypeWrapper<CompositeResourceDefinitionConnection>;
  CompositeResourceDefinitionControllerStatus: ResolverTypeWrapper<CompositeResourceDefinitionControllerStatus>;
  CompositeResourceDefinitionNames: ResolverTypeWrapper<CompositeResourceDefinitionNames>;
  CompositeResourceDefinitionSpec: ResolverTypeWrapper<CompositeResourceDefinitionSpec>;
  CompositeResourceDefinitionStatus: ResolverTypeWrapper<CompositeResourceDefinitionStatus>;
  CompositeResourceDefinitionVersion: ResolverTypeWrapper<CompositeResourceDefinitionVersion>;
  CompositeResourceSpec: ResolverTypeWrapper<CompositeResourceSpec>;
  CompositeResourceStatus: ResolverTypeWrapper<CompositeResourceStatus>;
  CompositeResourceValidation: ResolverTypeWrapper<CompositeResourceValidation>;
  Composition: ResolverTypeWrapper<Composition>;
  CompositionConnection: ResolverTypeWrapper<CompositionConnection>;
  CompositionSpec: ResolverTypeWrapper<CompositionSpec>;
  CompositionStatus: ResolverTypeWrapper<CompositionStatus>;
  Condition: ResolverTypeWrapper<Condition>;
  ConditionStatus: ConditionStatus;
  ConditionedStatus:
    | ResolversTypes['CompositeResourceClaimStatus']
    | ResolversTypes['CompositeResourceDefinitionStatus']
    | ResolversTypes['CompositeResourceStatus']
    | ResolversTypes['CompositionStatus']
    | ResolversTypes['ConfigurationRevisionStatus']
    | ResolversTypes['ConfigurationStatus']
    | ResolversTypes['CustomResourceDefinitionStatus']
    | ResolversTypes['ManagedResourceStatus']
    | ResolversTypes['ProviderConfigStatus']
    | ResolversTypes['ProviderRevisionStatus']
    | ResolversTypes['ProviderStatus'];
  ConfigMap: ResolverTypeWrapper<ConfigMap>;
  Configuration: ResolverTypeWrapper<Configuration>;
  ConfigurationConnection: ResolverTypeWrapper<ConfigurationConnection>;
  ConfigurationOrProvider: ResolversTypes['Configuration'] | ResolversTypes['Provider'];
  ConfigurationRevision: ResolverTypeWrapper<ConfigurationRevision>;
  ConfigurationRevisionConnection: ResolverTypeWrapper<ConfigurationRevisionConnection>;
  ConfigurationRevisionSpec: ResolverTypeWrapper<ConfigurationRevisionSpec>;
  ConfigurationRevisionStatus: ResolverTypeWrapper<ConfigurationRevisionStatus>;
  ConfigurationSpec: ResolverTypeWrapper<ConfigurationSpec>;
  ConfigurationStatus: ResolverTypeWrapper<ConfigurationStatus>;
  ControlPlane: ResolverTypeWrapper<ControlPlane>;
  ControlPlaneConfiguration: ResolverTypeWrapper<ControlPlaneConfiguration>;
  ControlPlaneConfigurationInstallation: ResolverTypeWrapper<ControlPlaneConfigurationInstallation>;
  ControlPlaneConfigurationProvider: ControlPlaneConfigurationProvider;
  ControlPlaneLimitReached: ResolverTypeWrapper<ControlPlaneLimitReached>;
  ControlPlaneLimitScope: ControlPlaneLimitScope;
  ControlPlanePermission: ControlPlanePermission;
  ControlPlaneStatus: ControlPlaneStatus;
  CreateControlPlaneConfigurationPayload: CreateControlPlaneConfigurationPayload;
  CreateControlPlanePayload: CreateControlPlanePayload;
  CreateControlPlaneResponse: ResolversTypes['AccountControlPlane'] | ResolversTypes['ControlPlaneLimitReached'];
  CreateKubernetesResourceInput: CreateKubernetesResourceInput;
  CreateKubernetesResourcePayload: ResolverTypeWrapper<CreateKubernetesResourcePayload>;
  CreateOrUpdateRepositoryPayload: CreateOrUpdateRepositoryPayload;
  CreateOrgInvitePayload: CreateOrgInvitePayload;
  CreateOrganizationPayload: CreateOrganizationPayload;
  CreateRobotPayload: CreateRobotPayload;
  CreateTeamPayload: CreateTeamPayload;
  CreateTokenPayload: CreateTokenPayload;
  Crossplane: ResolverTypeWrapper<Crossplane>;
  CrossplaneInfo: ResolverTypeWrapper<CrossplaneInfo>;
  CrossplaneMutation: ResolverTypeWrapper<CrossplaneMutation>;
  CrossplaneResourceTreeConnection: ResolverTypeWrapper<CrossplaneResourceTreeConnection>;
  CrossplaneResourceTreeNode: ResolverTypeWrapper<CrossplaneResourceTreeNode>;
  CurrentUser: ResolverTypeWrapper<CurrentUser>;
  CustomResourceDefinition: ResolverTypeWrapper<
    Omit<CustomResourceDefinition, 'package'> & { package?: Maybe<ResolversTypes['ConfigurationOrProvider']> }
  >;
  CustomResourceDefinitionConnection: ResolverTypeWrapper<CustomResourceDefinitionConnection>;
  CustomResourceDefinitionNames: ResolverTypeWrapper<CustomResourceDefinitionNames>;
  CustomResourceDefinitionSpec: ResolverTypeWrapper<CustomResourceDefinitionSpec>;
  CustomResourceDefinitionStatus: ResolverTypeWrapper<CustomResourceDefinitionStatus>;
  CustomResourceDefinitionVersion: ResolverTypeWrapper<CustomResourceDefinitionVersion>;
  CustomResourceType: CustomResourceType;
  CustomResourceValidation: ResolverTypeWrapper<CustomResourceValidation>;
  DefinedCompositeResourceClaimOptionsInput: DefinedCompositeResourceClaimOptionsInput;
  DefinedCompositeResourceOptionsInput: DefinedCompositeResourceOptionsInput;
  DeleteKubernetesResourcePayload: ResolverTypeWrapper<DeleteKubernetesResourcePayload>;
  DeleteRepositoryPayload: DeleteRepositoryPayload;
  DeleteRepositoryVersionPayload: DeleteRepositoryVersionPayload;
  DeletionPolicy: DeletionPolicy;
  DetailsResource:
    | ResolversTypes['CompositeResource']
    | ResolversTypes['CompositeResourceClaim']
    | ResolversTypes['ManagedResource'];
  DisconnectLoginProviderPayload: DisconnectLoginProviderPayload;
  Event: ResolverTypeWrapper<Event>;
  EventConnection: ResolverTypeWrapper<EventConnection>;
  EventSource: ResolverTypeWrapper<EventSource>;
  EventType: EventType;
  FidoChallenge: ResolverTypeWrapper<FidoChallenge>;
  FidoEnrollPayload: FidoEnrollPayload;
  FidoKey: ResolverTypeWrapper<FidoKey>;
  GenericResource: ResolverTypeWrapper<GenericResource>;
  Highlight: ResolverTypeWrapper<Highlight>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InviteData: ResolverTypeWrapper<InviteData>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  KubernetesResource:
    | ResolversTypes['CompositeResource']
    | ResolversTypes['CompositeResourceClaim']
    | ResolversTypes['CompositeResourceDefinition']
    | ResolversTypes['Composition']
    | ResolversTypes['ConfigMap']
    | ResolversTypes['Configuration']
    | ResolversTypes['ConfigurationRevision']
    | ResolversTypes['CustomResourceDefinition']
    | ResolversTypes['GenericResource']
    | ResolversTypes['ManagedResource']
    | ResolversTypes['Provider']
    | ResolversTypes['ProviderConfig']
    | ResolversTypes['ProviderRevision']
    | ResolversTypes['Secret'];
  KubernetesResourceConnection: ResolverTypeWrapper<KubernetesResourceConnection>;
  LabelSelector: ResolverTypeWrapper<LabelSelector>;
  LocalObjectReference: ResolverTypeWrapper<LocalObjectReference>;
  LoginProvider: ResolverTypeWrapper<LoginProvider>;
  LoginProviderStatus: LoginProviderStatus;
  LoginProviderType: LoginProviderType;
  ManagedResource: ResolverTypeWrapper<
    Omit<ManagedResource, 'definition'> & { definition?: Maybe<ResolversTypes['ManagedResourceDefinition']> }
  >;
  ManagedResourceDefinition: ResolversTypes['CustomResourceDefinition'];
  ManagedResourceSpec: ResolverTypeWrapper<ManagedResourceSpec>;
  ManagedResourceStatus: ResolverTypeWrapper<ManagedResourceStatus>;
  McpClaim: ResolverTypeWrapper<McpClaim>;
  McpComposite: ResolverTypeWrapper<McpComposite>;
  McpManagedResource: ResolverTypeWrapper<McpManagedResource>;
  McpResource: ResolversTypes['McpClaim'] | ResolversTypes['McpComposite'] | ResolversTypes['McpManagedResource'];
  McpResourceFilters: McpResourceFilters;
  McpResourceNode: ResolverTypeWrapper<McpResourceNode>;
  McpResourceOrderBy: McpResourceOrderBy;
  McpResourceOrderDirection: McpResourceOrderDirection;
  McpResourceType: McpResourceType;
  McpResourcesOptions: McpResourcesOptions;
  Mutation: ResolverTypeWrapper<{}>;
  Node:
    | ResolversTypes['CompositeResource']
    | ResolversTypes['CompositeResourceClaim']
    | ResolversTypes['CompositeResourceDefinition']
    | ResolversTypes['Composition']
    | ResolversTypes['ConfigMap']
    | ResolversTypes['Configuration']
    | ResolversTypes['ConfigurationRevision']
    | ResolversTypes['CustomResourceDefinition']
    | ResolversTypes['Event']
    | ResolversTypes['GenericResource']
    | ResolversTypes['ManagedResource']
    | ResolversTypes['Provider']
    | ResolversTypes['ProviderConfig']
    | ResolversTypes['ProviderRevision']
    | ResolversTypes['Secret'];
  ObjectMeta: ResolverTypeWrapper<ObjectMeta>;
  ObjectReference: ResolverTypeWrapper<ObjectReference>;
  OrgAccount: ResolverTypeWrapper<OrgAccount>;
  OrgInvite: ResolverTypeWrapper<OrgInvite>;
  OrgMember: ResolverTypeWrapper<OrgMember>;
  OrgMemberUser: ResolverTypeWrapper<OrgMemberUser>;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationLicenseLimit: ResolverTypeWrapper<OrganizationLicenseLimit>;
  OrganizationLicenseLimitKind: OrganizationLicenseLimitKind;
  OrganizationPermission: OrganizationPermission;
  Owner: ResolverTypeWrapper<Owner>;
  OwnerConnection: ResolverTypeWrapper<OwnerConnection>;
  PackageDependency: ResolverTypeWrapper<PackageDependency>;
  PackagePullPolicy: PackagePullPolicy;
  PackageRevisionDesiredState: PackageRevisionDesiredState;
  PackageState: PackageState;
  PackageStatus: PackageStatus;
  Patch: Patch;
  PolicyRule: ResolverTypeWrapper<PolicyRule>;
  PrinterColumn: ResolverTypeWrapper<PrinterColumn>;
  Provider: ResolverTypeWrapper<Provider>;
  ProviderConfig: ResolverTypeWrapper<
    Omit<ProviderConfig, 'definition'> & { definition?: Maybe<ResolversTypes['ProviderConfigDefinition']> }
  >;
  ProviderConfigDefinition: ResolversTypes['CustomResourceDefinition'];
  ProviderConfigReference: ResolverTypeWrapper<ProviderConfigReference>;
  ProviderConfigStatus: ResolverTypeWrapper<ProviderConfigStatus>;
  ProviderConnection: ResolverTypeWrapper<ProviderConnection>;
  ProviderRevision: ResolverTypeWrapper<ProviderRevision>;
  ProviderRevisionConnection: ResolverTypeWrapper<ProviderRevisionConnection>;
  ProviderRevisionSpec: ResolverTypeWrapper<ProviderRevisionSpec>;
  ProviderRevisionStatus: ResolverTypeWrapper<ProviderRevisionStatus>;
  ProviderSpec: ResolverTypeWrapper<ProviderSpec>;
  ProviderStatus: ResolverTypeWrapper<ProviderStatus>;
  Query: ResolverTypeWrapper<{}>;
  RegisterRequest: ResolverTypeWrapper<RegisterRequest>;
  RegisteredKey: ResolverTypeWrapper<RegisteredKey>;
  RegistryCRD: ResolverTypeWrapper<RegistryCrd>;
  RegistryComposition: ResolverTypeWrapper<RegistryComposition>;
  RegistryCompositionDetails: ResolverTypeWrapper<RegistryCompositionDetails>;
  RegistryCompositionDetailsPayload: RegistryCompositionDetailsPayload;
  RegistryCompositionResource: ResolverTypeWrapper<RegistryCompositionResource>;
  RegistryPackage: ResolverTypeWrapper<RegistryPackage>;
  RegistryPackageDocs: ResolverTypeWrapper<RegistryPackageDocs>;
  RegistryPackageDocsPage: ResolverTypeWrapper<RegistryPackageDocsPage>;
  RegistryPackagePayload: RegistryPackagePayload;
  RegistryPackageType: RegistryPackageType;
  RegistryQuery: ResolverTypeWrapper<RegistryQuery>;
  RegistryResource: ResolversTypes['RegistryCRD'] | ResolversTypes['RegistryXRD'];
  RegistryResourceDetails: ResolverTypeWrapper<RegistryResourceDetails>;
  RegistryResourceDetailsPayload: RegistryResourceDetailsPayload;
  RegistryResourceType: RegistryResourceType;
  RegistrySearchPayload: RegistrySearchPayload;
  RegistrySearchResult: ResolverTypeWrapper<RegistrySearchResult>;
  RegistrySearchResultType: RegistrySearchResultType;
  RegistrySearchResults: ResolverTypeWrapper<RegistrySearchResults>;
  RegistryXRD: ResolverTypeWrapper<RegistryXrd>;
  RemoveTeamRobotPayload: RemoveTeamRobotPayload;
  Repository: ResolverTypeWrapper<Repository>;
  RepositoryPermission: RepositoryPermission;
  RepositoryTier: RepositoryTier;
  RepositoryVersion: ResolverTypeWrapper<RepositoryVersion>;
  ResourceInstanceDetails: ResolverTypeWrapper<
    Omit<ResourceInstanceDetails, 'instance'> & { instance: ResolversTypes['DetailsResource'] }
  >;
  ResourceScope: ResourceScope;
  RevisionActivationPolicy: RevisionActivationPolicy;
  Robot: ResolverTypeWrapper<Robot>;
  Secret: ResolverTypeWrapper<Secret>;
  SecretReference: ResolverTypeWrapper<SecretReference>;
  SetTeamControlPlanePermissionPayload: SetTeamControlPlanePermissionPayload;
  SetTeamRepositoryPermissionPayload: SetTeamRepositoryPermissionPayload;
  SetTeamUserPermissionPayload: SetTeamUserPermissionPayload;
  Space: ResolverTypeWrapper<Space>;
  SpaceConnectionStatus: SpaceConnectionStatus;
  SpaceGroup: ResolverTypeWrapper<SpaceGroup>;
  SpaceProvider: SpaceProvider;
  SpaceRegion: SpaceRegion;
  SpaceStatus: ResolverTypeWrapper<SpaceStatus>;
  SpaceType: SpaceType;
  String: ResolverTypeWrapper<Scalars['String']>;
  StringMap: ResolverTypeWrapper<Scalars['StringMap']>;
  Team: ResolverTypeWrapper<Team>;
  TeamControlPlane: ResolverTypeWrapper<TeamControlPlane>;
  TeamMember: ResolverTypeWrapper<TeamMember>;
  TeamMemberPermission: TeamMemberPermission;
  TeamMemberUser: ResolverTypeWrapper<TeamMemberUser>;
  TeamRepositoriesResult: ResolverTypeWrapper<TeamRepositoriesResult>;
  TeamRepository: ResolverTypeWrapper<TeamRepository>;
  TeamRobotRelationship: ResolverTypeWrapper<TeamRobotRelationship>;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  Token: ResolverTypeWrapper<Token>;
  TokenType: TokenType;
  TotpApplication: ResolverTypeWrapper<TotpApplication>;
  TwoFactorKeys: ResolverTypeWrapper<TwoFactorKeys>;
  TypeReference: ResolverTypeWrapper<TypeReference>;
  UpdateControlPlaneConfigurationInstallationPayload: UpdateControlPlaneConfigurationInstallationPayload;
  UpdateControlPlaneConfigurationPayload: UpdateControlPlaneConfigurationPayload;
  UpdateControlPlanePayload: UpdateControlPlanePayload;
  UpdateKubernetesResourceInput: UpdateKubernetesResourceInput;
  UpdateKubernetesResourcePayload: ResolverTypeWrapper<UpdateKubernetesResourcePayload>;
  UpdateOrganizationMemberPayload: UpdateOrganizationMemberPayload;
  UpdateOrganizationPayload: UpdateOrganizationPayload;
  UpdateRobotPayload: UpdateRobotPayload;
  UpdateTeamPayload: UpdateTeamPayload;
  UpdateUserInfoPayload: UpdateUserInfoPayload;
  UpdateUserSettingsPayload: UpdateUserSettingsPayload;
  User: ResolverTypeWrapper<User>;
  UserAccount: ResolverTypeWrapper<UserAccount>;
  UserSettings: ResolverTypeWrapper<UserSettings>;
  UserTeam: ResolverTypeWrapper<UserTeam>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: ResolversParentTypes['OrgAccount'] | ResolversParentTypes['UserAccount'];
  AccountControlPlane: AccountControlPlane;
  AccountRepositoriesResult: AccountRepositoriesResult;
  AddTeamRobotPayload: AddTeamRobotPayload;
  AuthMutation: AuthMutation;
  AuthQuery: AuthQuery;
  BaseUser: ResolversParentTypes['CurrentUser'] | ResolversParentTypes['User'];
  Boolean: Scalars['Boolean'];
  ChangePasswordPayload: ChangePasswordPayload;
  ClaimInstanceDetails: ClaimInstanceDetails;
  ClearTeamControlPlanePermissionPayload: ClearTeamControlPlanePermissionPayload;
  ClearTeamRepositoryPermissionPayload: ClearTeamRepositoryPermissionPayload;
  ClearTeamUserPermissionPayload: ClearTeamUserPermissionPayload;
  CompositeResource: CompositeResource;
  CompositeResourceClaim: CompositeResourceClaim;
  CompositeResourceClaimConnection: CompositeResourceClaimConnection;
  CompositeResourceClaimConnectionDetails: CompositeResourceClaimConnectionDetails;
  CompositeResourceClaimSpec: CompositeResourceClaimSpec;
  CompositeResourceClaimStatus: CompositeResourceClaimStatus;
  CompositeResourceConnection: CompositeResourceConnection;
  CompositeResourceConnectionDetails: CompositeResourceConnectionDetails;
  CompositeResourceDefinition: CompositeResourceDefinition;
  CompositeResourceDefinitionConnection: CompositeResourceDefinitionConnection;
  CompositeResourceDefinitionControllerStatus: CompositeResourceDefinitionControllerStatus;
  CompositeResourceDefinitionNames: CompositeResourceDefinitionNames;
  CompositeResourceDefinitionSpec: CompositeResourceDefinitionSpec;
  CompositeResourceDefinitionStatus: CompositeResourceDefinitionStatus;
  CompositeResourceDefinitionVersion: CompositeResourceDefinitionVersion;
  CompositeResourceSpec: CompositeResourceSpec;
  CompositeResourceStatus: CompositeResourceStatus;
  CompositeResourceValidation: CompositeResourceValidation;
  Composition: Composition;
  CompositionConnection: CompositionConnection;
  CompositionSpec: CompositionSpec;
  CompositionStatus: CompositionStatus;
  Condition: Condition;
  ConditionedStatus:
    | ResolversParentTypes['CompositeResourceClaimStatus']
    | ResolversParentTypes['CompositeResourceDefinitionStatus']
    | ResolversParentTypes['CompositeResourceStatus']
    | ResolversParentTypes['CompositionStatus']
    | ResolversParentTypes['ConfigurationRevisionStatus']
    | ResolversParentTypes['ConfigurationStatus']
    | ResolversParentTypes['CustomResourceDefinitionStatus']
    | ResolversParentTypes['ManagedResourceStatus']
    | ResolversParentTypes['ProviderConfigStatus']
    | ResolversParentTypes['ProviderRevisionStatus']
    | ResolversParentTypes['ProviderStatus'];
  ConfigMap: ConfigMap;
  Configuration: Configuration;
  ConfigurationConnection: ConfigurationConnection;
  ConfigurationOrProvider: ResolversParentTypes['Configuration'] | ResolversParentTypes['Provider'];
  ConfigurationRevision: ConfigurationRevision;
  ConfigurationRevisionConnection: ConfigurationRevisionConnection;
  ConfigurationRevisionSpec: ConfigurationRevisionSpec;
  ConfigurationRevisionStatus: ConfigurationRevisionStatus;
  ConfigurationSpec: ConfigurationSpec;
  ConfigurationStatus: ConfigurationStatus;
  ControlPlane: ControlPlane;
  ControlPlaneConfiguration: ControlPlaneConfiguration;
  ControlPlaneConfigurationInstallation: ControlPlaneConfigurationInstallation;
  ControlPlaneLimitReached: ControlPlaneLimitReached;
  CreateControlPlaneConfigurationPayload: CreateControlPlaneConfigurationPayload;
  CreateControlPlanePayload: CreateControlPlanePayload;
  CreateControlPlaneResponse:
    | ResolversParentTypes['AccountControlPlane']
    | ResolversParentTypes['ControlPlaneLimitReached'];
  CreateKubernetesResourceInput: CreateKubernetesResourceInput;
  CreateKubernetesResourcePayload: CreateKubernetesResourcePayload;
  CreateOrUpdateRepositoryPayload: CreateOrUpdateRepositoryPayload;
  CreateOrgInvitePayload: CreateOrgInvitePayload;
  CreateOrganizationPayload: CreateOrganizationPayload;
  CreateRobotPayload: CreateRobotPayload;
  CreateTeamPayload: CreateTeamPayload;
  CreateTokenPayload: CreateTokenPayload;
  Crossplane: Crossplane;
  CrossplaneInfo: CrossplaneInfo;
  CrossplaneMutation: CrossplaneMutation;
  CrossplaneResourceTreeConnection: CrossplaneResourceTreeConnection;
  CrossplaneResourceTreeNode: CrossplaneResourceTreeNode;
  CurrentUser: CurrentUser;
  CustomResourceDefinition: Omit<CustomResourceDefinition, 'package'> & {
    package?: Maybe<ResolversParentTypes['ConfigurationOrProvider']>;
  };
  CustomResourceDefinitionConnection: CustomResourceDefinitionConnection;
  CustomResourceDefinitionNames: CustomResourceDefinitionNames;
  CustomResourceDefinitionSpec: CustomResourceDefinitionSpec;
  CustomResourceDefinitionStatus: CustomResourceDefinitionStatus;
  CustomResourceDefinitionVersion: CustomResourceDefinitionVersion;
  CustomResourceValidation: CustomResourceValidation;
  DefinedCompositeResourceClaimOptionsInput: DefinedCompositeResourceClaimOptionsInput;
  DefinedCompositeResourceOptionsInput: DefinedCompositeResourceOptionsInput;
  DeleteKubernetesResourcePayload: DeleteKubernetesResourcePayload;
  DeleteRepositoryPayload: DeleteRepositoryPayload;
  DeleteRepositoryVersionPayload: DeleteRepositoryVersionPayload;
  DetailsResource:
    | ResolversParentTypes['CompositeResource']
    | ResolversParentTypes['CompositeResourceClaim']
    | ResolversParentTypes['ManagedResource'];
  DisconnectLoginProviderPayload: DisconnectLoginProviderPayload;
  Event: Event;
  EventConnection: EventConnection;
  EventSource: EventSource;
  FidoChallenge: FidoChallenge;
  FidoEnrollPayload: FidoEnrollPayload;
  FidoKey: FidoKey;
  GenericResource: GenericResource;
  Highlight: Highlight;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  InviteData: InviteData;
  JSON: Scalars['JSON'];
  KubernetesResource:
    | ResolversParentTypes['CompositeResource']
    | ResolversParentTypes['CompositeResourceClaim']
    | ResolversParentTypes['CompositeResourceDefinition']
    | ResolversParentTypes['Composition']
    | ResolversParentTypes['ConfigMap']
    | ResolversParentTypes['Configuration']
    | ResolversParentTypes['ConfigurationRevision']
    | ResolversParentTypes['CustomResourceDefinition']
    | ResolversParentTypes['GenericResource']
    | ResolversParentTypes['ManagedResource']
    | ResolversParentTypes['Provider']
    | ResolversParentTypes['ProviderConfig']
    | ResolversParentTypes['ProviderRevision']
    | ResolversParentTypes['Secret'];
  KubernetesResourceConnection: KubernetesResourceConnection;
  LabelSelector: LabelSelector;
  LocalObjectReference: LocalObjectReference;
  LoginProvider: LoginProvider;
  ManagedResource: Omit<ManagedResource, 'definition'> & {
    definition?: Maybe<ResolversParentTypes['ManagedResourceDefinition']>;
  };
  ManagedResourceDefinition: ResolversParentTypes['CustomResourceDefinition'];
  ManagedResourceSpec: ManagedResourceSpec;
  ManagedResourceStatus: ManagedResourceStatus;
  McpClaim: McpClaim;
  McpComposite: McpComposite;
  McpManagedResource: McpManagedResource;
  McpResource:
    | ResolversParentTypes['McpClaim']
    | ResolversParentTypes['McpComposite']
    | ResolversParentTypes['McpManagedResource'];
  McpResourceFilters: McpResourceFilters;
  McpResourceNode: McpResourceNode;
  McpResourcesOptions: McpResourcesOptions;
  Mutation: {};
  Node:
    | ResolversParentTypes['CompositeResource']
    | ResolversParentTypes['CompositeResourceClaim']
    | ResolversParentTypes['CompositeResourceDefinition']
    | ResolversParentTypes['Composition']
    | ResolversParentTypes['ConfigMap']
    | ResolversParentTypes['Configuration']
    | ResolversParentTypes['ConfigurationRevision']
    | ResolversParentTypes['CustomResourceDefinition']
    | ResolversParentTypes['Event']
    | ResolversParentTypes['GenericResource']
    | ResolversParentTypes['ManagedResource']
    | ResolversParentTypes['Provider']
    | ResolversParentTypes['ProviderConfig']
    | ResolversParentTypes['ProviderRevision']
    | ResolversParentTypes['Secret'];
  ObjectMeta: ObjectMeta;
  ObjectReference: ObjectReference;
  OrgAccount: OrgAccount;
  OrgInvite: OrgInvite;
  OrgMember: OrgMember;
  OrgMemberUser: OrgMemberUser;
  Organization: Organization;
  OrganizationLicenseLimit: OrganizationLicenseLimit;
  Owner: Owner;
  OwnerConnection: OwnerConnection;
  PackageDependency: PackageDependency;
  Patch: Patch;
  PolicyRule: PolicyRule;
  PrinterColumn: PrinterColumn;
  Provider: Provider;
  ProviderConfig: Omit<ProviderConfig, 'definition'> & {
    definition?: Maybe<ResolversParentTypes['ProviderConfigDefinition']>;
  };
  ProviderConfigDefinition: ResolversParentTypes['CustomResourceDefinition'];
  ProviderConfigReference: ProviderConfigReference;
  ProviderConfigStatus: ProviderConfigStatus;
  ProviderConnection: ProviderConnection;
  ProviderRevision: ProviderRevision;
  ProviderRevisionConnection: ProviderRevisionConnection;
  ProviderRevisionSpec: ProviderRevisionSpec;
  ProviderRevisionStatus: ProviderRevisionStatus;
  ProviderSpec: ProviderSpec;
  ProviderStatus: ProviderStatus;
  Query: {};
  RegisterRequest: RegisterRequest;
  RegisteredKey: RegisteredKey;
  RegistryCRD: RegistryCrd;
  RegistryComposition: RegistryComposition;
  RegistryCompositionDetails: RegistryCompositionDetails;
  RegistryCompositionDetailsPayload: RegistryCompositionDetailsPayload;
  RegistryCompositionResource: RegistryCompositionResource;
  RegistryPackage: RegistryPackage;
  RegistryPackageDocs: RegistryPackageDocs;
  RegistryPackageDocsPage: RegistryPackageDocsPage;
  RegistryPackagePayload: RegistryPackagePayload;
  RegistryQuery: RegistryQuery;
  RegistryResource: ResolversParentTypes['RegistryCRD'] | ResolversParentTypes['RegistryXRD'];
  RegistryResourceDetails: RegistryResourceDetails;
  RegistryResourceDetailsPayload: RegistryResourceDetailsPayload;
  RegistrySearchPayload: RegistrySearchPayload;
  RegistrySearchResult: RegistrySearchResult;
  RegistrySearchResults: RegistrySearchResults;
  RegistryXRD: RegistryXrd;
  RemoveTeamRobotPayload: RemoveTeamRobotPayload;
  Repository: Repository;
  RepositoryVersion: RepositoryVersion;
  ResourceInstanceDetails: Omit<ResourceInstanceDetails, 'instance'> & {
    instance: ResolversParentTypes['DetailsResource'];
  };
  Robot: Robot;
  Secret: Secret;
  SecretReference: SecretReference;
  SetTeamControlPlanePermissionPayload: SetTeamControlPlanePermissionPayload;
  SetTeamRepositoryPermissionPayload: SetTeamRepositoryPermissionPayload;
  SetTeamUserPermissionPayload: SetTeamUserPermissionPayload;
  Space: Space;
  SpaceGroup: SpaceGroup;
  SpaceStatus: SpaceStatus;
  String: Scalars['String'];
  StringMap: Scalars['StringMap'];
  Team: Team;
  TeamControlPlane: TeamControlPlane;
  TeamMember: TeamMember;
  TeamMemberUser: TeamMemberUser;
  TeamRepositoriesResult: TeamRepositoriesResult;
  TeamRepository: TeamRepository;
  TeamRobotRelationship: TeamRobotRelationship;
  Time: Scalars['Time'];
  Timestamp: Scalars['Timestamp'];
  Token: Token;
  TotpApplication: TotpApplication;
  TwoFactorKeys: TwoFactorKeys;
  TypeReference: TypeReference;
  UpdateControlPlaneConfigurationInstallationPayload: UpdateControlPlaneConfigurationInstallationPayload;
  UpdateControlPlaneConfigurationPayload: UpdateControlPlaneConfigurationPayload;
  UpdateControlPlanePayload: UpdateControlPlanePayload;
  UpdateKubernetesResourceInput: UpdateKubernetesResourceInput;
  UpdateKubernetesResourcePayload: UpdateKubernetesResourcePayload;
  UpdateOrganizationMemberPayload: UpdateOrganizationMemberPayload;
  UpdateOrganizationPayload: UpdateOrganizationPayload;
  UpdateRobotPayload: UpdateRobotPayload;
  UpdateTeamPayload: UpdateTeamPayload;
  UpdateUserInfoPayload: UpdateUserInfoPayload;
  UpdateUserSettingsPayload: UpdateUserSettingsPayload;
  User: User;
  UserAccount: UserAccount;
  UserSettings: UserSettings;
  UserTeam: UserTeam;
};

export type GoFieldDirectiveArgs = {
  forceResolver?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type GoFieldDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = GoFieldDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type GoModelDirectiveArgs = {
  model?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Scalars['String']>>;
};

export type GoModelDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = GoModelDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account'],
> = {
  __resolveType: TypeResolveFn<'OrgAccount' | 'UserAccount', ParentType, ContextType>;
  controlPlaneConfigurations?: Resolver<Array<ResolversTypes['ControlPlaneConfiguration']>, ParentType, ContextType>;
  controlPlanes?: Resolver<Array<ResolversTypes['AccountControlPlane']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  repositories?: Resolver<
    ResolversTypes['AccountRepositoriesResult'],
    ParentType,
    ContextType,
    RequireFields<AccountRepositoriesArgs, 'page' | 'size'>
  >;
  repository?: Resolver<
    ResolversTypes['Repository'],
    ParentType,
    ContextType,
    RequireFields<AccountRepositoryArgs, 'name'>
  >;
  spaces?: Resolver<Array<ResolversTypes['Space']>, ParentType, ContextType, Partial<AccountSpacesArgs>>;
};

export type AccountControlPlaneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountControlPlane'] = ResolversParentTypes['AccountControlPlane'],
> = {
  controlPlane?: Resolver<ResolversTypes['ControlPlane'], ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['ControlPlanePermission'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ControlPlaneStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountRepositoriesResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountRepositoriesResult'] = ResolversParentTypes['AccountRepositoriesResult'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Repository']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthMutation'] = ResolversParentTypes['AuthMutation'],
> = {
  deleteFidoKey?: Resolver<
    Maybe<ResolversTypes['FidoKey']>,
    ParentType,
    ContextType,
    RequireFields<AuthMutationDeleteFidoKeyArgs, 'id'>
  >;
  deleteTotpApp?: Resolver<
    Maybe<ResolversTypes['TotpApplication']>,
    ParentType,
    ContextType,
    RequireFields<AuthMutationDeleteTotpAppArgs, 'id'>
  >;
  enrollFidoChallenge?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<AuthMutationEnrollFidoChallengeArgs, 'payload'>
  >;
  enrollTotpChallenge?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<AuthMutationEnrollTotpChallengeArgs, 'code'>
  >;
  updateFidoKey?: Resolver<
    Maybe<ResolversTypes['FidoKey']>,
    ParentType,
    ContextType,
    RequireFields<AuthMutationUpdateFidoKeyArgs, 'id' | 'name'>
  >;
  updateTotpApp?: Resolver<
    Maybe<ResolversTypes['TotpApplication']>,
    ParentType,
    ContextType,
    RequireFields<AuthMutationUpdateTotpAppArgs, 'id' | 'name'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthQuery'] = ResolversParentTypes['AuthQuery'],
> = {
  enrollFido?: Resolver<ResolversTypes['FidoChallenge'], ParentType, ContextType>;
  enrollTotp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  get2fa?: Resolver<ResolversTypes['TwoFactorKeys'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BaseUser'] = ResolversParentTypes['BaseUser'],
> = {
  __resolveType: TypeResolveFn<'CurrentUser' | 'User', ParentType, ContextType>;
  biography?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type ClaimInstanceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClaimInstanceDetails'] = ResolversParentTypes['ClaimInstanceDetails'],
> = {
  additionalPrinterColumns?: Resolver<Array<ResolversTypes['PrinterColumn']>, ParentType, ContextType>;
  crd?: Resolver<ResolversTypes['CustomResourceDefinition'], ParentType, ContextType>;
  instance?: Resolver<ResolversTypes['CompositeResourceClaim'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResource'] = ResolversParentTypes['CompositeResource'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definition?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinition']>, ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['CompositeResourceSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompositeResourceStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceClaimResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceClaim'] = ResolversParentTypes['CompositeResourceClaim'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definition?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinition']>, ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['CompositeResourceClaimSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompositeResourceClaimStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceClaimConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceClaimConnection'] = ResolversParentTypes['CompositeResourceClaimConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['CompositeResourceClaim']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceClaimConnectionDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceClaimConnectionDetails'] = ResolversParentTypes['CompositeResourceClaimConnectionDetails'],
> = {
  lastPublishedTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceClaimSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceClaimSpec'] = ResolversParentTypes['CompositeResourceClaimSpec'],
> = {
  composition?: Resolver<Maybe<ResolversTypes['Composition']>, ParentType, ContextType>;
  compositionRef?: Resolver<Maybe<ResolversTypes['LocalObjectReference']>, ParentType, ContextType>;
  compositionSelector?: Resolver<Maybe<ResolversTypes['LabelSelector']>, ParentType, ContextType>;
  connectionSecret?: Resolver<Maybe<ResolversTypes['Secret']>, ParentType, ContextType>;
  resource?: Resolver<Maybe<ResolversTypes['CompositeResource']>, ParentType, ContextType>;
  resourceRef?: Resolver<Maybe<ResolversTypes['ObjectReference']>, ParentType, ContextType>;
  writeConnectionSecretToReference?: Resolver<Maybe<ResolversTypes['SecretReference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceClaimStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceClaimStatus'] = ResolversParentTypes['CompositeResourceClaimStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  connectionDetails?: Resolver<
    Maybe<ResolversTypes['CompositeResourceClaimConnectionDetails']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceConnection'] = ResolversParentTypes['CompositeResourceConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['CompositeResource']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceConnectionDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceConnectionDetails'] = ResolversParentTypes['CompositeResourceConnectionDetails'],
> = {
  lastPublishedTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinition'] = ResolversParentTypes['CompositeResourceDefinition'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  claimName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compositeResourceCRD?: Resolver<Maybe<ResolversTypes['CustomResourceDefinition']>, ParentType, ContextType>;
  compositeResourceClaimCRD?: Resolver<Maybe<ResolversTypes['CustomResourceDefinition']>, ParentType, ContextType>;
  definedCompositeResourceClaims?: Resolver<
    ResolversTypes['CompositeResourceClaimConnection'],
    ParentType,
    ContextType,
    Partial<CompositeResourceDefinitionDefinedCompositeResourceClaimsArgs>
  >;
  definedCompositeResources?: Resolver<
    ResolversTypes['CompositeResourceConnection'],
    ParentType,
    ContextType,
    Partial<CompositeResourceDefinitionDefinedCompositeResourcesArgs>
  >;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['Configuration']>, ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['CompositeResourceDefinitionSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinitionStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionConnection'] = ResolversParentTypes['CompositeResourceDefinitionConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['CompositeResourceDefinition']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionControllerStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionControllerStatus'] = ResolversParentTypes['CompositeResourceDefinitionControllerStatus'],
> = {
  compositeResourceClaimType?: Resolver<Maybe<ResolversTypes['TypeReference']>, ParentType, ContextType>;
  compositeResourceType?: Resolver<Maybe<ResolversTypes['TypeReference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionNamesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionNames'] = ResolversParentTypes['CompositeResourceDefinitionNames'],
> = {
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listKind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plural?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  singular?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionSpec'] = ResolversParentTypes['CompositeResourceDefinitionSpec'],
> = {
  claimNames?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinitionNames']>, ParentType, ContextType>;
  connectionSecretKeys?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  defaultComposition?: Resolver<Maybe<ResolversTypes['Composition']>, ParentType, ContextType>;
  enforcedComposition?: Resolver<Maybe<ResolversTypes['Composition']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  names?: Resolver<ResolversTypes['CompositeResourceDefinitionNames'], ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<ResolversTypes['CompositeResourceDefinitionVersion']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionStatus'] = ResolversParentTypes['CompositeResourceDefinitionStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  controllers?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinitionControllerStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceDefinitionVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceDefinitionVersion'] = ResolversParentTypes['CompositeResourceDefinitionVersion'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  schema?: Resolver<Maybe<ResolversTypes['CompositeResourceValidation']>, ParentType, ContextType>;
  served?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceSpec'] = ResolversParentTypes['CompositeResourceSpec'],
> = {
  claim?: Resolver<Maybe<ResolversTypes['CompositeResourceClaim']>, ParentType, ContextType>;
  claimRef?: Resolver<Maybe<ResolversTypes['ObjectReference']>, ParentType, ContextType>;
  composition?: Resolver<Maybe<ResolversTypes['Composition']>, ParentType, ContextType>;
  compositionRef?: Resolver<Maybe<ResolversTypes['LocalObjectReference']>, ParentType, ContextType>;
  compositionSelector?: Resolver<Maybe<ResolversTypes['LabelSelector']>, ParentType, ContextType>;
  connectionSecret?: Resolver<Maybe<ResolversTypes['Secret']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['KubernetesResourceConnection']>, ParentType, ContextType>;
  writeConnectionSecretToReference?: Resolver<Maybe<ResolversTypes['SecretReference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceStatus'] = ResolversParentTypes['CompositeResourceStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  connectionDetails?: Resolver<Maybe<ResolversTypes['CompositeResourceConnectionDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeResourceValidationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositeResourceValidation'] = ResolversParentTypes['CompositeResourceValidation'],
> = {
  openAPIV3Schema?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Composition'] = ResolversParentTypes['Composition'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['CompositionSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompositionStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositionConnection'] = ResolversParentTypes['CompositionConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Composition']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositionSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositionSpec'] = ResolversParentTypes['CompositionSpec'],
> = {
  compositeTypeRef?: Resolver<ResolversTypes['TypeReference'], ParentType, ContextType>;
  writeConnectionSecretsToNamespace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositionStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompositionStatus'] = ResolversParentTypes['CompositionStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition'],
> = {
  lastTransitionTime?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ConditionStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionedStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConditionedStatus'] = ResolversParentTypes['ConditionedStatus'],
> = {
  __resolveType: TypeResolveFn<
    | 'CompositeResourceClaimStatus'
    | 'CompositeResourceDefinitionStatus'
    | 'CompositeResourceStatus'
    | 'CompositionStatus'
    | 'ConfigurationRevisionStatus'
    | 'ConfigurationStatus'
    | 'CustomResourceDefinitionStatus'
    | 'ManagedResourceStatus'
    | 'ProviderConfigStatus'
    | 'ProviderRevisionStatus'
    | 'ProviderStatus',
    ParentType,
    ContextType
  >;
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
};

export type ConfigMapResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigMap'] = ResolversParentTypes['ConfigMap'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['StringMap']>, ParentType, ContextType, Partial<ConfigMapDataArgs>>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration'],
> = {
  activeRevision?: Resolver<Maybe<ResolversTypes['ConfigurationRevision']>, ParentType, ContextType>;
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculatedStatus?: Resolver<ResolversTypes['PackageStatus'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceDefinitions?: Resolver<Maybe<Array<ResolversTypes['CompositeResourceDefinition']>>, ParentType, ContextType>;
  revisions?: Resolver<ResolversTypes['ConfigurationRevisionConnection'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['ConfigurationSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ConfigurationStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationConnection'] = ResolversParentTypes['ConfigurationConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Configuration']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationOrProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationOrProvider'] = ResolversParentTypes['ConfigurationOrProvider'],
> = {
  __resolveType: TypeResolveFn<'Configuration' | 'Provider', ParentType, ContextType>;
};

export type ConfigurationRevisionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationRevision'] = ResolversParentTypes['ConfigurationRevision'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['ConfigurationRevisionSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ConfigurationRevisionStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationRevisionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationRevisionConnection'] = ResolversParentTypes['ConfigurationRevisionConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['ConfigurationRevision']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationRevisionSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationRevisionSpec'] = ResolversParentTypes['ConfigurationRevisionSpec'],
> = {
  desiredState?: Resolver<ResolversTypes['PackageRevisionDesiredState'], ParentType, ContextType>;
  ignoreCrossplaneConstraints?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  package?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagePullPolicy?: Resolver<Maybe<ResolversTypes['PackagePullPolicy']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skipDependencyResolution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationRevisionStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationRevisionStatus'] = ResolversParentTypes['ConfigurationRevisionStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  foundDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  installedDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  invalidDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  objects?: Resolver<ResolversTypes['KubernetesResourceConnection'], ParentType, ContextType>;
  permissionRequests?: Resolver<Maybe<Array<ResolversTypes['PolicyRule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationSpec'] = ResolversParentTypes['ConfigurationSpec'],
> = {
  ignoreCrossplaneConstraints?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  package?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagePullPolicy?: Resolver<Maybe<ResolversTypes['PackagePullPolicy']>, ParentType, ContextType>;
  revisionActivationPolicy?: Resolver<Maybe<ResolversTypes['RevisionActivationPolicy']>, ParentType, ContextType>;
  revisionHistoryLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skipDependencyResolution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigurationStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfigurationStatus'] = ResolversParentTypes['ConfigurationStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  currentIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentRevision?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ControlPlaneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ControlPlane'] = ResolversParentTypes['ControlPlane'],
> = {
  assignedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  controlPlaneConfigurationInstallation?: Resolver<
    Maybe<ResolversTypes['ControlPlaneConfigurationInstallation']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isViewOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kubeClusterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mcpResource?: Resolver<
    Maybe<ResolversTypes['McpResource']>,
    ParentType,
    ContextType,
    RequireFields<ControlPlaneMcpResourceArgs, 'id'>
  >;
  mcpResources?: Resolver<
    Maybe<ResolversTypes['McpResourceNode']>,
    ParentType,
    ContextType,
    Partial<ControlPlaneMcpResourcesArgs>
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  selfHosted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ControlPlaneConfigurationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ControlPlaneConfiguration'] = ResolversParentTypes['ControlPlaneConfiguration'],
> = {
  branch?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  installations?: Resolver<Array<ResolversTypes['ControlPlaneConfigurationInstallation']>, ParentType, ContextType>;
  latestVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<ResolversTypes['ControlPlaneConfigurationProvider'], ParentType, ContextType>;
  repositoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ControlPlaneConfigurationInstallationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ControlPlaneConfigurationInstallation'] = ResolversParentTypes['ControlPlaneConfigurationInstallation'],
> = {
  configuration?: Resolver<ResolversTypes['ControlPlaneConfiguration'], ParentType, ContextType>;
  controlPlane?: Resolver<ResolversTypes['ControlPlane'], ParentType, ContextType>;
  currentVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deployedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  desiredVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ControlPlaneLimitReachedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ControlPlaneLimitReached'] = ResolversParentTypes['ControlPlaneLimitReached'],
> = {
  limitScope?: Resolver<ResolversTypes['ControlPlaneLimitScope'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateControlPlaneResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateControlPlaneResponse'] = ResolversParentTypes['CreateControlPlaneResponse'],
> = {
  __resolveType: TypeResolveFn<'AccountControlPlane' | 'ControlPlaneLimitReached', ParentType, ContextType>;
};

export type CreateKubernetesResourcePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateKubernetesResourcePayload'] = ResolversParentTypes['CreateKubernetesResourcePayload'],
> = {
  resource?: Resolver<Maybe<ResolversTypes['KubernetesResource']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossplaneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Crossplane'] = ResolversParentTypes['Crossplane'],
> = {
  claimCRDs?: Resolver<Array<ResolversTypes['CustomResourceDefinition']>, ParentType, ContextType>;
  claimInstanceDetails?: Resolver<
    ResolversTypes['ClaimInstanceDetails'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneClaimInstanceDetailsArgs, 'crdId' | 'id'>
  >;
  compositeResourceDefinitions?: Resolver<
    ResolversTypes['CompositeResourceDefinitionConnection'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneCompositeResourceDefinitionsArgs, 'dangling'>
  >;
  compositions?: Resolver<
    ResolversTypes['CompositionConnection'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneCompositionsArgs, 'dangling'>
  >;
  configMap?: Resolver<
    Maybe<ResolversTypes['ConfigMap']>,
    ParentType,
    ContextType,
    RequireFields<CrossplaneConfigMapArgs, 'name' | 'namespace'>
  >;
  configurationRevisions?: Resolver<
    ResolversTypes['ConfigurationRevisionConnection'],
    ParentType,
    ContextType,
    Partial<CrossplaneConfigurationRevisionsArgs>
  >;
  configurations?: Resolver<ResolversTypes['ConfigurationConnection'], ParentType, ContextType>;
  crossplaneInfo?: Resolver<Maybe<ResolversTypes['CrossplaneInfo']>, ParentType, ContextType>;
  crossplaneResourceTree?: Resolver<
    ResolversTypes['CrossplaneResourceTreeConnection'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneCrossplaneResourceTreeArgs, 'id'>
  >;
  customResourceDefinitions?: Resolver<
    ResolversTypes['CustomResourceDefinitionConnection'],
    ParentType,
    ContextType,
    Partial<CrossplaneCustomResourceDefinitionsArgs>
  >;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType, Partial<CrossplaneEventsArgs>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kubernetesResource?: Resolver<
    Maybe<ResolversTypes['KubernetesResource']>,
    ParentType,
    ContextType,
    RequireFields<CrossplaneKubernetesResourceArgs, 'id'>
  >;
  kubernetesResources?: Resolver<
    ResolversTypes['KubernetesResourceConnection'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneKubernetesResourcesArgs, 'apiVersion' | 'kind'>
  >;
  providerRevisions?: Resolver<
    ResolversTypes['ProviderRevisionConnection'],
    ParentType,
    ContextType,
    Partial<CrossplaneProviderRevisionsArgs>
  >;
  providers?: Resolver<ResolversTypes['ProviderConnection'], ParentType, ContextType>;
  resourceInstanceDetails?: Resolver<
    ResolversTypes['ResourceInstanceDetails'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneResourceInstanceDetailsArgs, 'crdId' | 'id'>
  >;
  secret?: Resolver<
    Maybe<ResolversTypes['Secret']>,
    ParentType,
    ContextType,
    RequireFields<CrossplaneSecretArgs, 'name' | 'namespace'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossplaneInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossplaneInfo'] = ResolversParentTypes['CrossplaneInfo'],
> = {
  agentVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crossplaneVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  graphQLVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uxpVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossplaneMutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossplaneMutation'] = ResolversParentTypes['CrossplaneMutation'],
> = {
  createKubernetesResource?: Resolver<
    ResolversTypes['CreateKubernetesResourcePayload'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneMutationCreateKubernetesResourceArgs, 'input'>
  >;
  deleteKubernetesResource?: Resolver<
    ResolversTypes['DeleteKubernetesResourcePayload'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneMutationDeleteKubernetesResourceArgs, 'id'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updateKubernetesResource?: Resolver<
    ResolversTypes['UpdateKubernetesResourcePayload'],
    ParentType,
    ContextType,
    RequireFields<CrossplaneMutationUpdateKubernetesResourceArgs, 'id' | 'input'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossplaneResourceTreeConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossplaneResourceTreeConnection'] = ResolversParentTypes['CrossplaneResourceTreeConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['CrossplaneResourceTreeNode']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossplaneResourceTreeNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossplaneResourceTreeNode'] = ResolversParentTypes['CrossplaneResourceTreeNode'],
> = {
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['KubernetesResource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentUser'] = ResolversParentTypes['CurrentUser'],
> = {
  biography?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  features?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  loginProviders?: Resolver<Array<ResolversTypes['LoginProvider']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinition'] = ResolversParentTypes['CustomResourceDefinition'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definedResources?: Resolver<
    ResolversTypes['KubernetesResourceConnection'],
    ParentType,
    ContextType,
    Partial<CustomResourceDefinitionDefinedResourcesArgs>
  >;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['ConfigurationOrProvider']>, ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['CustomResourceType'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['CustomResourceDefinitionSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CustomResourceDefinitionStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  xrd?: Resolver<Maybe<ResolversTypes['CompositeResourceDefinition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinitionConnection'] = ResolversParentTypes['CustomResourceDefinitionConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['CustomResourceDefinition']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionNamesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinitionNames'] = ResolversParentTypes['CustomResourceDefinitionNames'],
> = {
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listKind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plural?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  singular?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinitionSpec'] = ResolversParentTypes['CustomResourceDefinitionSpec'],
> = {
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  names?: Resolver<ResolversTypes['CustomResourceDefinitionNames'], ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['ResourceScope'], ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<ResolversTypes['CustomResourceDefinitionVersion']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinitionStatus'] = ResolversParentTypes['CustomResourceDefinitionStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceDefinitionVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceDefinitionVersion'] = ResolversParentTypes['CustomResourceDefinitionVersion'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schema?: Resolver<Maybe<ResolversTypes['CustomResourceValidation']>, ParentType, ContextType>;
  served?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomResourceValidationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomResourceValidation'] = ResolversParentTypes['CustomResourceValidation'],
> = {
  openAPIV3Schema?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteKubernetesResourcePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteKubernetesResourcePayload'] = ResolversParentTypes['DeleteKubernetesResourcePayload'],
> = {
  resource?: Resolver<Maybe<ResolversTypes['KubernetesResource']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailsResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailsResource'] = ResolversParentTypes['DetailsResource'],
> = {
  __resolveType: TypeResolveFn<
    'CompositeResource' | 'CompositeResourceClaim' | 'ManagedResource',
    ParentType,
    ContextType
  >;
};

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firstTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  involvedObject?: Resolver<ResolversTypes['KubernetesResource'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['EventSource']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventConnection'] = ResolversParentTypes['EventConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Event']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventSource'] = ResolversParentTypes['EventSource'],
> = {
  component?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FidoChallengeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FidoChallenge'] = ResolversParentTypes['FidoChallenge'],
> = {
  appId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  registerRequests?: Resolver<Array<ResolversTypes['RegisterRequest']>, ParentType, ContextType>;
  registeredKeys?: Resolver<Maybe<Array<ResolversTypes['RegisteredKey']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FidoKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FidoKey'] = ResolversParentTypes['FidoKey'],
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fidoId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenericResource'] = ResolversParentTypes['GenericResource'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Highlight'] = ResolversParentTypes['Highlight'],
> = {
  account?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  content?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  group?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  kind?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  repository?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InviteData'] = ResolversParentTypes['InviteData'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type KubernetesResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KubernetesResource'] = ResolversParentTypes['KubernetesResource'],
> = {
  __resolveType: TypeResolveFn<
    | 'CompositeResource'
    | 'CompositeResourceClaim'
    | 'CompositeResourceDefinition'
    | 'Composition'
    | 'ConfigMap'
    | 'Configuration'
    | 'ConfigurationRevision'
    | 'CustomResourceDefinition'
    | 'GenericResource'
    | 'ManagedResource'
    | 'Provider'
    | 'ProviderConfig'
    | 'ProviderRevision'
    | 'Secret',
    ParentType,
    ContextType
  >;
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
};

export type KubernetesResourceConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KubernetesResourceConnection'] = ResolversParentTypes['KubernetesResourceConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['KubernetesResource']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelSelectorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LabelSelector'] = ResolversParentTypes['LabelSelector'],
> = {
  matchLabels?: Resolver<Maybe<ResolversTypes['StringMap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalObjectReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocalObjectReference'] = ResolversParentTypes['LocalObjectReference'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoginProvider'] = ResolversParentTypes['LoginProvider'],
> = {
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LoginProviderStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['LoginProviderType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedResource'] = ResolversParentTypes['ManagedResource'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definition?: Resolver<Maybe<ResolversTypes['ManagedResourceDefinition']>, ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['ManagedResourceSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ManagedResourceStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedResourceDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedResourceDefinition'] = ResolversParentTypes['ManagedResourceDefinition'],
> = {
  __resolveType: TypeResolveFn<'CustomResourceDefinition', ParentType, ContextType>;
};

export type ManagedResourceSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedResourceSpec'] = ResolversParentTypes['ManagedResourceSpec'],
> = {
  connectionSecret?: Resolver<Maybe<ResolversTypes['Secret']>, ParentType, ContextType>;
  deletionPolicy?: Resolver<Maybe<ResolversTypes['DeletionPolicy']>, ParentType, ContextType>;
  providerConfigRef?: Resolver<Maybe<ResolversTypes['ProviderConfigReference']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedResourceStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagedResourceStatus'] = ResolversParentTypes['ManagedResourceStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type McpClaimResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['McpClaim'] = ResolversParentTypes['McpClaim'],
> = {
  creationTimestamp?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namespace?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type McpCompositeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['McpComposite'] = ResolversParentTypes['McpComposite'],
> = {
  creationTimestamp?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type McpManagedResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['McpManagedResource'] = ResolversParentTypes['McpManagedResource'],
> = {
  creationTimestamp?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  providerConfigName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type McpResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['McpResource'] = ResolversParentTypes['McpResource'],
> = {
  __resolveType: TypeResolveFn<'McpClaim' | 'McpComposite' | 'McpManagedResource', ParentType, ContextType>;
  creationTimestamp?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ready?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type McpResourceNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['McpResourceNode'] = ResolversParentTypes['McpResourceNode'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['McpResource']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addTeamRobot?: Resolver<
    ResolversTypes['Robot'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTeamRobotArgs, 'payload'>
  >;
  auth?: Resolver<ResolversTypes['AuthMutation'], ParentType, ContextType>;
  changePassword?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationChangePasswordArgs, 'payload'>
  >;
  clearTeamControlPlanePermission?: Resolver<
    Maybe<ResolversTypes['TeamControlPlane']>,
    ParentType,
    ContextType,
    RequireFields<MutationClearTeamControlPlanePermissionArgs, 'payload'>
  >;
  clearTeamRepositoryPermission?: Resolver<
    Maybe<ResolversTypes['TeamRepository']>,
    ParentType,
    ContextType,
    RequireFields<MutationClearTeamRepositoryPermissionArgs, 'payload'>
  >;
  clearTeamUserPermission?: Resolver<
    Maybe<ResolversTypes['TeamMember']>,
    ParentType,
    ContextType,
    RequireFields<MutationClearTeamUserPermissionArgs, 'payload'>
  >;
  createControlPlane?: Resolver<
    ResolversTypes['CreateControlPlaneResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateControlPlaneArgs, 'payload'>
  >;
  createControlPlaneConfiguration?: Resolver<
    ResolversTypes['ControlPlaneConfiguration'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateControlPlaneConfigurationArgs, 'payload'>
  >;
  createOrUpdateRepository?: Resolver<
    ResolversTypes['Repository'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrUpdateRepositoryArgs, 'payload'>
  >;
  createOrganization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrganizationArgs, 'payload'>
  >;
  createOrganizationInvite?: Resolver<
    ResolversTypes['OrgInvite'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrganizationInviteArgs, 'organizationId' | 'payload'>
  >;
  createRobot?: Resolver<
    ResolversTypes['Robot'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRobotArgs, 'payload'>
  >;
  createTeam?: Resolver<
    ResolversTypes['Team'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTeamArgs, 'payload'>
  >;
  createToken?: Resolver<
    ResolversTypes['Token'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTokenArgs, 'payload'>
  >;
  crossplane?: Resolver<
    ResolversTypes['CrossplaneMutation'],
    ParentType,
    ContextType,
    RequireFields<MutationCrossplaneArgs, 'cpId'>
  >;
  deleteControlPlane?: Resolver<
    ResolversTypes['AccountControlPlane'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteControlPlaneArgs, 'id'>
  >;
  deleteControlPlaneConfiguration?: Resolver<
    ResolversTypes['ControlPlaneConfiguration'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteControlPlaneConfigurationArgs, 'id'>
  >;
  deleteOrganization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOrganizationArgs, 'id'>
  >;
  deleteOrganizationInvite?: Resolver<
    ResolversTypes['OrgInvite'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOrganizationInviteArgs, 'inviteId' | 'organizationId'>
  >;
  deleteRepository?: Resolver<
    ResolversTypes['Repository'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRepositoryArgs, 'payload'>
  >;
  deleteRepositoryVersion?: Resolver<
    ResolversTypes['RepositoryVersion'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRepositoryVersionArgs, 'payload'>
  >;
  deleteRobot?: Resolver<
    ResolversTypes['Robot'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteRobotArgs, 'id'>
  >;
  deleteTeam?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<MutationDeleteTeamArgs, 'id'>>;
  deleteToken?: Resolver<
    ResolversTypes['Token'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTokenArgs, 'id'>
  >;
  disconnectLoginProvider?: Resolver<
    ResolversTypes['LoginProvider'],
    ParentType,
    ContextType,
    RequireFields<MutationDisconnectLoginProviderArgs, 'payload'>
  >;
  joinOrgByToken?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinOrgByTokenArgs, 'token'>
  >;
  removeOrganizationMember?: Resolver<
    ResolversTypes['OrgMember'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOrganizationMemberArgs, 'organizationId' | 'userId'>
  >;
  removeTeamRobot?: Resolver<
    ResolversTypes['Robot'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTeamRobotArgs, 'payload'>
  >;
  setOrgAccountHasCompletedFRE?: Resolver<
    ResolversTypes['OrgAccount'],
    ParentType,
    ContextType,
    RequireFields<MutationSetOrgAccountHasCompletedFreArgs, 'id'>
  >;
  setOrgAccountHasNotCompletedFRE?: Resolver<
    ResolversTypes['OrgAccount'],
    ParentType,
    ContextType,
    RequireFields<MutationSetOrgAccountHasNotCompletedFreArgs, 'id'>
  >;
  setTeamControlPlanePermission?: Resolver<
    ResolversTypes['TeamControlPlane'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTeamControlPlanePermissionArgs, 'payload'>
  >;
  setTeamRepositoryPermission?: Resolver<
    ResolversTypes['TeamRepository'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTeamRepositoryPermissionArgs, 'payload'>
  >;
  setTeamUserPermission?: Resolver<
    ResolversTypes['TeamMember'],
    ParentType,
    ContextType,
    RequireFields<MutationSetTeamUserPermissionArgs, 'payload'>
  >;
  startOrgAccountTrial?: Resolver<
    ResolversTypes['OrgAccount'],
    ParentType,
    ContextType,
    RequireFields<MutationStartOrgAccountTrialArgs, 'id'>
  >;
  updateControlPlane?: Resolver<
    ResolversTypes['AccountControlPlane'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateControlPlaneArgs, 'id' | 'payload'>
  >;
  updateControlPlaneConfiguration?: Resolver<
    ResolversTypes['ControlPlaneConfiguration'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateControlPlaneConfigurationArgs, 'id' | 'payload'>
  >;
  updateControlPlaneConfigurationInstallation?: Resolver<
    ResolversTypes['ControlPlaneConfigurationInstallation'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateControlPlaneConfigurationInstallationArgs, 'controlPlaneId' | 'payload'>
  >;
  updateOrganization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOrganizationArgs, 'id' | 'payload'>
  >;
  updateOrganizationMember?: Resolver<
    ResolversTypes['OrgMember'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOrganizationMemberArgs, 'organizationId' | 'payload' | 'userId'>
  >;
  updateRobot?: Resolver<
    ResolversTypes['Robot'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRobotArgs, 'id' | 'payload'>
  >;
  updateTeam?: Resolver<
    ResolversTypes['Team'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTeamArgs, 'id' | 'payload'>
  >;
  updateUserInfo?: Resolver<
    ResolversTypes['CurrentUser'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserInfoArgs, 'payload'>
  >;
  updateUserSettings?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserSettingsArgs, 'payload'>
  >;
};

export type NodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node'],
> = {
  __resolveType: TypeResolveFn<
    | 'CompositeResource'
    | 'CompositeResourceClaim'
    | 'CompositeResourceDefinition'
    | 'Composition'
    | 'ConfigMap'
    | 'Configuration'
    | 'ConfigurationRevision'
    | 'CustomResourceDefinition'
    | 'Event'
    | 'GenericResource'
    | 'ManagedResource'
    | 'Provider'
    | 'ProviderConfig'
    | 'ProviderRevision'
    | 'Secret',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
};

export type ObjectMetaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ObjectMeta'] = ResolversParentTypes['ObjectMeta'],
> = {
  annotations?: Resolver<
    Maybe<ResolversTypes['StringMap']>,
    ParentType,
    ContextType,
    Partial<ObjectMetaAnnotationsArgs>
  >;
  controller?: Resolver<Maybe<ResolversTypes['KubernetesResource']>, ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  deletionTime?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  generateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  generation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  labels?: Resolver<Maybe<ResolversTypes['StringMap']>, ParentType, ContextType, Partial<ObjectMetaLabelsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namespace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owners?: Resolver<ResolversTypes['OwnerConnection'], ParentType, ContextType>;
  resourceVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ObjectReference'] = ResolversParentTypes['ObjectReference'],
> = {
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  namespace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrgAccount'] = ResolversParentTypes['OrgAccount'],
> = {
  controlPlaneConfigurations?: Resolver<Array<ResolversTypes['ControlPlaneConfiguration']>, ParentType, ContextType>;
  controlPlanes?: Resolver<Array<ResolversTypes['AccountControlPlane']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  repositories?: Resolver<
    ResolversTypes['AccountRepositoriesResult'],
    ParentType,
    ContextType,
    RequireFields<OrgAccountRepositoriesArgs, 'page' | 'size'>
  >;
  repository?: Resolver<
    ResolversTypes['Repository'],
    ParentType,
    ContextType,
    RequireFields<OrgAccountRepositoryArgs, 'name'>
  >;
  spaces?: Resolver<Array<ResolversTypes['Space']>, ParentType, ContextType, Partial<OrgAccountSpacesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgInviteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrgInvite'] = ResolversParentTypes['OrgInvite'],
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['OrganizationPermission'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrgMember'] = ResolversParentTypes['OrgMember'],
> = {
  permission?: Resolver<ResolversTypes['OrganizationPermission'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['OrgMemberUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrgMemberUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrgMemberUser'] = ResolversParentTypes['OrgMemberUser'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization'],
> = {
  allowAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  creatorId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deleteAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  hasCompletedFRE?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invites?: Resolver<Maybe<Array<ResolversTypes['OrgInvite']>>, ParentType, ContextType>;
  licenseLimits?: Resolver<Array<ResolversTypes['OrganizationLicenseLimit']>, ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['OrgMember']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reservedControlPlanes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  robots?: Resolver<Array<ResolversTypes['Robot']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['OrganizationPermission'], ParentType, ContextType>;
  ssoDSLinked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ssoOrgID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['UserTeam']>, ParentType, ContextType>;
  tier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trialEndsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trialStartedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationLicenseLimitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganizationLicenseLimit'] = ResolversParentTypes['OrganizationLicenseLimit'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['OrganizationLicenseLimitKind'], ParentType, ContextType>;
  max?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner'],
> = {
  controller?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['KubernetesResource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OwnerConnection'] = ResolversParentTypes['OwnerConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Owner']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageDependencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageDependency'] = ResolversParentTypes['PackageDependency'],
> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['RegistryPackageType'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PolicyRuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PolicyRule'] = ResolversParentTypes['PolicyRule'],
> = {
  apiGroups?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  nonResourceURLs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  resourceNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  resources?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  verbs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrinterColumnResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrinterColumn'] = ResolversParentTypes['PrinterColumn'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Provider'] = ResolversParentTypes['Provider'],
> = {
  activeRevision?: Resolver<Maybe<ResolversTypes['ProviderRevision']>, ParentType, ContextType>;
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculatedStatus?: Resolver<ResolversTypes['PackageStatus'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  marketplace?: Resolver<Maybe<ResolversTypes['RegistryPackage']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceDefinitions?: Resolver<Maybe<Array<ResolversTypes['CustomResourceDefinition']>>, ParentType, ContextType>;
  revisions?: Resolver<ResolversTypes['ProviderRevisionConnection'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['ProviderSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderConfigResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderConfig'] = ResolversParentTypes['ProviderConfig'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definition?: Resolver<Maybe<ResolversTypes['ProviderConfigDefinition']>, ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderConfigStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderConfigDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderConfigDefinition'] = ResolversParentTypes['ProviderConfigDefinition'],
> = {
  __resolveType: TypeResolveFn<'CustomResourceDefinition', ParentType, ContextType>;
};

export type ProviderConfigReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderConfigReference'] = ResolversParentTypes['ProviderConfigReference'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderConfigStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderConfigStatus'] = ResolversParentTypes['ProviderConfigStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderConnection'] = ResolversParentTypes['ProviderConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['Provider']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderRevisionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderRevision'] = ResolversParentTypes['ProviderRevision'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  spec?: Resolver<ResolversTypes['ProviderRevisionSpec'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProviderRevisionStatus']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderRevisionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderRevisionConnection'] = ResolversParentTypes['ProviderRevisionConnection'],
> = {
  nodes?: Resolver<Maybe<Array<ResolversTypes['ProviderRevision']>>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderRevisionSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderRevisionSpec'] = ResolversParentTypes['ProviderRevisionSpec'],
> = {
  desiredState?: Resolver<ResolversTypes['PackageRevisionDesiredState'], ParentType, ContextType>;
  ignoreCrossplaneConstraints?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  package?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagePullPolicy?: Resolver<Maybe<ResolversTypes['PackagePullPolicy']>, ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skipDependencyResolution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderRevisionStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderRevisionStatus'] = ResolversParentTypes['ProviderRevisionStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  foundDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  installedDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  invalidDependencies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  objects?: Resolver<ResolversTypes['KubernetesResourceConnection'], ParentType, ContextType>;
  permissionRequests?: Resolver<Maybe<Array<ResolversTypes['PolicyRule']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderSpecResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderSpec'] = ResolversParentTypes['ProviderSpec'],
> = {
  ignoreCrossplaneConstraints?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  package?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packagePullPolicy?: Resolver<Maybe<ResolversTypes['PackagePullPolicy']>, ParentType, ContextType>;
  revisionActivationPolicy?: Resolver<Maybe<ResolversTypes['RevisionActivationPolicy']>, ParentType, ContextType>;
  revisionHistoryLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  skipDependencyResolution?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProviderStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProviderStatus'] = ResolversParentTypes['ProviderStatus'],
> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  currentIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentRevision?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<QueryAccountArgs, 'id'>>;
  accountNameAvailable?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<QueryAccountNameAvailableArgs, 'name'>
  >;
  accounts?: Resolver<Array<ResolversTypes['Account']>, ParentType, ContextType>;
  auth?: Resolver<ResolversTypes['AuthQuery'], ParentType, ContextType>;
  controlPlane?: Resolver<
    ResolversTypes['AccountControlPlane'],
    ParentType,
    ContextType,
    RequireFields<QueryControlPlaneArgs, 'id'>
  >;
  controlPlaneConfiguration?: Resolver<
    ResolversTypes['ControlPlaneConfiguration'],
    ParentType,
    ContextType,
    RequireFields<QueryControlPlaneConfigurationArgs, 'id'>
  >;
  crossplane?: Resolver<
    Maybe<ResolversTypes['Crossplane']>,
    ParentType,
    ContextType,
    RequireFields<QueryCrossplaneArgs, 'cpId'>
  >;
  currentUser?: Resolver<ResolversTypes['CurrentUser'], ParentType, ContextType>;
  registry?: Resolver<ResolversTypes['RegistryQuery'], ParentType, ContextType>;
  robot?: Resolver<ResolversTypes['Robot'], ParentType, ContextType, RequireFields<QueryRobotArgs, 'id'>>;
  space?: Resolver<ResolversTypes['Space'], ParentType, ContextType, RequireFields<QuerySpaceArgs, 'id'>>;
  spaceControlPlane?: Resolver<
    ResolversTypes['AccountControlPlane'],
    ParentType,
    ContextType,
    RequireFields<QuerySpaceControlPlaneArgs, 'id'>
  >;
  spaceGroup?: Resolver<
    ResolversTypes['SpaceGroup'],
    ParentType,
    ContextType,
    RequireFields<QuerySpaceGroupArgs, 'id'>
  >;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType, RequireFields<QueryTeamArgs, 'teamId'>>;
  validateInvite?: Resolver<
    Maybe<ResolversTypes['InviteData']>,
    ParentType,
    ContextType,
    RequireFields<QueryValidateInviteArgs, 'token'>
  >;
};

export type RegisterRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisterRequest'] = ResolversParentTypes['RegisterRequest'],
> = {
  challenge?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisteredKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisteredKey'] = ResolversParentTypes['RegisteredKey'],
> = {
  appId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keyHandle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryCrdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryCRD'] = ResolversParentTypes['RegistryCRD'],
> = {
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storageVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryCompositionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryComposition'] = ResolversParentTypes['RegistryComposition'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  xrdGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xrdKind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryCompositionDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryCompositionDetails'] = ResolversParentTypes['RegistryCompositionDetails'],
> = {
  compositionResources?: Resolver<Array<ResolversTypes['RegistryCompositionResource']>, ParentType, ContextType>;
  definition?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xrdGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  xrdKind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryCompositionResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryCompositionResource'] = ResolversParentTypes['RegistryCompositionResource'],
> = {
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryPackageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryPackage'] = ResolversParentTypes['RegistryPackage'],
> = {
  account?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compositions?: Resolver<Maybe<Array<ResolversTypes['RegistryComposition']>>, ParentType, ContextType>;
  definition?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  dependsOn?: Resolver<Array<ResolversTypes['PackageDependency']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  docs?: Resolver<Maybe<ResolversTypes['RegistryPackageDocs']>, ParentType, ContextType>;
  familyRepositoryId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  keywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  license?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maintainer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['RegistryPackageType'], ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  readme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  repositoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  resources?: Resolver<Array<ResolversTypes['RegistryResource']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tier?: Resolver<ResolversTypes['RepositoryTier'], ParentType, ContextType>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSettings']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryPackageDocsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryPackageDocs'] = ResolversParentTypes['RegistryPackageDocs'],
> = {
  metadata?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  tableOfContents?: Resolver<Array<ResolversTypes['RegistryPackageDocsPage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryPackageDocsPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryPackageDocsPage'] = ResolversParentTypes['RegistryPackageDocsPage'],
> = {
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryQuery'] = ResolversParentTypes['RegistryQuery'],
> = {
  compositionDetails?: Resolver<
    ResolversTypes['RegistryCompositionDetails'],
    ParentType,
    ContextType,
    RequireFields<RegistryQueryCompositionDetailsArgs, 'payload'>
  >;
  package?: Resolver<
    ResolversTypes['RegistryPackage'],
    ParentType,
    ContextType,
    RequireFields<RegistryQueryPackageArgs, 'payload'>
  >;
  resourceDetails?: Resolver<
    ResolversTypes['RegistryResourceDetails'],
    ParentType,
    ContextType,
    RequireFields<RegistryQueryResourceDetailsArgs, 'payload'>
  >;
  search?: Resolver<
    ResolversTypes['RegistrySearchResults'],
    ParentType,
    ContextType,
    RequireFields<RegistryQuerySearchArgs, 'payload'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryResourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryResource'] = ResolversParentTypes['RegistryResource'],
> = {
  __resolveType: TypeResolveFn<'RegistryCRD' | 'RegistryXRD', ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
};

export type RegistryResourceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryResourceDetails'] = ResolversParentTypes['RegistryResourceDetails'],
> = {
  account?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  definition?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  examples?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repository?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['RegistryResourceType'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistrySearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistrySearchResult'] = ResolversParentTypes['RegistrySearchResult'],
> = {
  account?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  familyRepositoryId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlight?: Resolver<Maybe<ResolversTypes['Highlight']>, ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageType?: Resolver<ResolversTypes['RegistryPackageType'], ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  repositoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tier?: Resolver<ResolversTypes['RepositoryTier'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['RegistrySearchResultType']>, ParentType, ContextType>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSettings']>, ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistrySearchResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistrySearchResults'] = ResolversParentTypes['RegistrySearchResults'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  results?: Resolver<Array<ResolversTypes['RegistrySearchResult']>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistryXrdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistryXRD'] = ResolversParentTypes['RegistryXRD'],
> = {
  group?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceableVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepositoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Repository'] = ResolversParentTypes['Repository'],
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  currentVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  packageType?: Resolver<Maybe<ResolversTypes['RegistryPackageType']>, ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tier?: Resolver<ResolversTypes['RepositoryTier'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  userSettings?: Resolver<Maybe<ResolversTypes['UserSettings']>, ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<ResolversTypes['RepositoryVersion']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepositoryVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RepositoryVersion'] = ResolversParentTypes['RepositoryVersion'],
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  digest?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['PackageState'], ParentType, ContextType>;
  stateDetail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceInstanceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResourceInstanceDetails'] = ResolversParentTypes['ResourceInstanceDetails'],
> = {
  additionalPrinterColumns?: Resolver<Array<ResolversTypes['PrinterColumn']>, ParentType, ContextType>;
  crd?: Resolver<ResolversTypes['CustomResourceDefinition'], ParentType, ContextType>;
  instance?: Resolver<ResolversTypes['DetailsResource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RobotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Robot'] = ResolversParentTypes['Robot'],
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  teams?: Resolver<Array<ResolversTypes['TeamRobotRelationship']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecretResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Secret'] = ResolversParentTypes['Secret'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['StringMap']>, ParentType, ContextType, Partial<SecretDataArgs>>;
  events?: Resolver<ResolversTypes['EventConnection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['ObjectMeta'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unstructured?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecretReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecretReference'] = ResolversParentTypes['SecretReference'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namespace?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Space'] = ResolversParentTypes['Space'],
> = {
  controlPlanes?: Resolver<Maybe<Array<ResolversTypes['AccountControlPlane']>>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['SpaceGroup']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['SpaceProvider']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['SpaceRegion']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SpaceStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SpaceType'], ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpaceGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SpaceGroup'] = ResolversParentTypes['SpaceGroup'],
> = {
  controlPlanes?: Resolver<Maybe<Array<ResolversTypes['AccountControlPlane']>>, ParentType, ContextType>;
  groupName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  labels?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpaceStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SpaceStatus'] = ResolversParentTypes['SpaceStatus'],
> = {
  lastQueried?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['SpaceConnectionStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface StringMapScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['StringMap'], any> {
  name: 'StringMap';
}

export type TeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team'],
> = {
  controlPlanes?: Resolver<Array<ResolversTypes['TeamControlPlane']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['TeamMember']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repositories?: Resolver<
    ResolversTypes['TeamRepositoriesResult'],
    ParentType,
    ContextType,
    RequireFields<TeamRepositoriesArgs, 'page' | 'size'>
  >;
  robots?: Resolver<Array<ResolversTypes['Robot']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamControlPlaneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamControlPlane'] = ResolversParentTypes['TeamControlPlane'],
> = {
  controlPlane?: Resolver<ResolversTypes['ControlPlane'], ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['ControlPlanePermission'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamMember'] = ResolversParentTypes['TeamMember'],
> = {
  permission?: Resolver<ResolversTypes['TeamMemberPermission'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['TeamMemberUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMemberUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamMemberUser'] = ResolversParentTypes['TeamMemberUser'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRepositoriesResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamRepositoriesResult'] = ResolversParentTypes['TeamRepositoriesResult'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TeamRepository']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRepositoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamRepository'] = ResolversParentTypes['TeamRepository'],
> = {
  permission?: Resolver<ResolversTypes['RepositoryPermission'], ParentType, ContextType>;
  repository?: Resolver<ResolversTypes['Repository'], ParentType, ContextType>;
  teamId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamRobotRelationshipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamRobotRelationship'] = ResolversParentTypes['TeamRobotRelationship'],
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relationshipCreatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token'],
> = {
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  jwt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUsedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotpApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotpApplication'] = ResolversParentTypes['TotpApplication'],
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totpId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwoFactorKeysResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TwoFactorKeys'] = ResolversParentTypes['TwoFactorKeys'],
> = {
  fido?: Resolver<Maybe<Array<ResolversTypes['FidoKey']>>, ParentType, ContextType>;
  totp?: Resolver<Maybe<Array<ResolversTypes['TotpApplication']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TypeReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeReference'] = ResolversParentTypes['TypeReference'],
> = {
  apiVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateKubernetesResourcePayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateKubernetesResourcePayload'] = ResolversParentTypes['UpdateKubernetesResourcePayload'],
> = {
  resource?: Resolver<Maybe<ResolversTypes['KubernetesResource']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  biography?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Timestamp'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Timestamp']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAccount'] = ResolversParentTypes['UserAccount'],
> = {
  controlPlaneConfigurations?: Resolver<Array<ResolversTypes['ControlPlaneConfiguration']>, ParentType, ContextType>;
  controlPlanes?: Resolver<Array<ResolversTypes['AccountControlPlane']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  repositories?: Resolver<
    ResolversTypes['AccountRepositoriesResult'],
    ParentType,
    ContextType,
    RequireFields<UserAccountRepositoriesArgs, 'page' | 'size'>
  >;
  repository?: Resolver<
    ResolversTypes['Repository'],
    ParentType,
    ContextType,
    RequireFields<UserAccountRepositoryArgs, 'name'>
  >;
  spaces?: Resolver<Array<ResolversTypes['Space']>, ParentType, ContextType, Partial<UserAccountSpacesArgs>>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserSettings'] = ResolversParentTypes['UserSettings'],
> = {
  starred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserTeam'] = ResolversParentTypes['UserTeam'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  permission?: Resolver<Maybe<ResolversTypes['TeamMemberPermission']>, ParentType, ContextType>;
  team?: Resolver<ResolversTypes['Team'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  AccountControlPlane?: AccountControlPlaneResolvers<ContextType>;
  AccountRepositoriesResult?: AccountRepositoriesResultResolvers<ContextType>;
  AuthMutation?: AuthMutationResolvers<ContextType>;
  AuthQuery?: AuthQueryResolvers<ContextType>;
  BaseUser?: BaseUserResolvers<ContextType>;
  ClaimInstanceDetails?: ClaimInstanceDetailsResolvers<ContextType>;
  CompositeResource?: CompositeResourceResolvers<ContextType>;
  CompositeResourceClaim?: CompositeResourceClaimResolvers<ContextType>;
  CompositeResourceClaimConnection?: CompositeResourceClaimConnectionResolvers<ContextType>;
  CompositeResourceClaimConnectionDetails?: CompositeResourceClaimConnectionDetailsResolvers<ContextType>;
  CompositeResourceClaimSpec?: CompositeResourceClaimSpecResolvers<ContextType>;
  CompositeResourceClaimStatus?: CompositeResourceClaimStatusResolvers<ContextType>;
  CompositeResourceConnection?: CompositeResourceConnectionResolvers<ContextType>;
  CompositeResourceConnectionDetails?: CompositeResourceConnectionDetailsResolvers<ContextType>;
  CompositeResourceDefinition?: CompositeResourceDefinitionResolvers<ContextType>;
  CompositeResourceDefinitionConnection?: CompositeResourceDefinitionConnectionResolvers<ContextType>;
  CompositeResourceDefinitionControllerStatus?: CompositeResourceDefinitionControllerStatusResolvers<ContextType>;
  CompositeResourceDefinitionNames?: CompositeResourceDefinitionNamesResolvers<ContextType>;
  CompositeResourceDefinitionSpec?: CompositeResourceDefinitionSpecResolvers<ContextType>;
  CompositeResourceDefinitionStatus?: CompositeResourceDefinitionStatusResolvers<ContextType>;
  CompositeResourceDefinitionVersion?: CompositeResourceDefinitionVersionResolvers<ContextType>;
  CompositeResourceSpec?: CompositeResourceSpecResolvers<ContextType>;
  CompositeResourceStatus?: CompositeResourceStatusResolvers<ContextType>;
  CompositeResourceValidation?: CompositeResourceValidationResolvers<ContextType>;
  Composition?: CompositionResolvers<ContextType>;
  CompositionConnection?: CompositionConnectionResolvers<ContextType>;
  CompositionSpec?: CompositionSpecResolvers<ContextType>;
  CompositionStatus?: CompositionStatusResolvers<ContextType>;
  Condition?: ConditionResolvers<ContextType>;
  ConditionedStatus?: ConditionedStatusResolvers<ContextType>;
  ConfigMap?: ConfigMapResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  ConfigurationConnection?: ConfigurationConnectionResolvers<ContextType>;
  ConfigurationOrProvider?: ConfigurationOrProviderResolvers<ContextType>;
  ConfigurationRevision?: ConfigurationRevisionResolvers<ContextType>;
  ConfigurationRevisionConnection?: ConfigurationRevisionConnectionResolvers<ContextType>;
  ConfigurationRevisionSpec?: ConfigurationRevisionSpecResolvers<ContextType>;
  ConfigurationRevisionStatus?: ConfigurationRevisionStatusResolvers<ContextType>;
  ConfigurationSpec?: ConfigurationSpecResolvers<ContextType>;
  ConfigurationStatus?: ConfigurationStatusResolvers<ContextType>;
  ControlPlane?: ControlPlaneResolvers<ContextType>;
  ControlPlaneConfiguration?: ControlPlaneConfigurationResolvers<ContextType>;
  ControlPlaneConfigurationInstallation?: ControlPlaneConfigurationInstallationResolvers<ContextType>;
  ControlPlaneLimitReached?: ControlPlaneLimitReachedResolvers<ContextType>;
  CreateControlPlaneResponse?: CreateControlPlaneResponseResolvers<ContextType>;
  CreateKubernetesResourcePayload?: CreateKubernetesResourcePayloadResolvers<ContextType>;
  Crossplane?: CrossplaneResolvers<ContextType>;
  CrossplaneInfo?: CrossplaneInfoResolvers<ContextType>;
  CrossplaneMutation?: CrossplaneMutationResolvers<ContextType>;
  CrossplaneResourceTreeConnection?: CrossplaneResourceTreeConnectionResolvers<ContextType>;
  CrossplaneResourceTreeNode?: CrossplaneResourceTreeNodeResolvers<ContextType>;
  CurrentUser?: CurrentUserResolvers<ContextType>;
  CustomResourceDefinition?: CustomResourceDefinitionResolvers<ContextType>;
  CustomResourceDefinitionConnection?: CustomResourceDefinitionConnectionResolvers<ContextType>;
  CustomResourceDefinitionNames?: CustomResourceDefinitionNamesResolvers<ContextType>;
  CustomResourceDefinitionSpec?: CustomResourceDefinitionSpecResolvers<ContextType>;
  CustomResourceDefinitionStatus?: CustomResourceDefinitionStatusResolvers<ContextType>;
  CustomResourceDefinitionVersion?: CustomResourceDefinitionVersionResolvers<ContextType>;
  CustomResourceValidation?: CustomResourceValidationResolvers<ContextType>;
  DeleteKubernetesResourcePayload?: DeleteKubernetesResourcePayloadResolvers<ContextType>;
  DetailsResource?: DetailsResourceResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventConnection?: EventConnectionResolvers<ContextType>;
  EventSource?: EventSourceResolvers<ContextType>;
  FidoChallenge?: FidoChallengeResolvers<ContextType>;
  FidoKey?: FidoKeyResolvers<ContextType>;
  GenericResource?: GenericResourceResolvers<ContextType>;
  Highlight?: HighlightResolvers<ContextType>;
  InviteData?: InviteDataResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  KubernetesResource?: KubernetesResourceResolvers<ContextType>;
  KubernetesResourceConnection?: KubernetesResourceConnectionResolvers<ContextType>;
  LabelSelector?: LabelSelectorResolvers<ContextType>;
  LocalObjectReference?: LocalObjectReferenceResolvers<ContextType>;
  LoginProvider?: LoginProviderResolvers<ContextType>;
  ManagedResource?: ManagedResourceResolvers<ContextType>;
  ManagedResourceDefinition?: ManagedResourceDefinitionResolvers<ContextType>;
  ManagedResourceSpec?: ManagedResourceSpecResolvers<ContextType>;
  ManagedResourceStatus?: ManagedResourceStatusResolvers<ContextType>;
  McpClaim?: McpClaimResolvers<ContextType>;
  McpComposite?: McpCompositeResolvers<ContextType>;
  McpManagedResource?: McpManagedResourceResolvers<ContextType>;
  McpResource?: McpResourceResolvers<ContextType>;
  McpResourceNode?: McpResourceNodeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  ObjectMeta?: ObjectMetaResolvers<ContextType>;
  ObjectReference?: ObjectReferenceResolvers<ContextType>;
  OrgAccount?: OrgAccountResolvers<ContextType>;
  OrgInvite?: OrgInviteResolvers<ContextType>;
  OrgMember?: OrgMemberResolvers<ContextType>;
  OrgMemberUser?: OrgMemberUserResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationLicenseLimit?: OrganizationLicenseLimitResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  OwnerConnection?: OwnerConnectionResolvers<ContextType>;
  PackageDependency?: PackageDependencyResolvers<ContextType>;
  PolicyRule?: PolicyRuleResolvers<ContextType>;
  PrinterColumn?: PrinterColumnResolvers<ContextType>;
  Provider?: ProviderResolvers<ContextType>;
  ProviderConfig?: ProviderConfigResolvers<ContextType>;
  ProviderConfigDefinition?: ProviderConfigDefinitionResolvers<ContextType>;
  ProviderConfigReference?: ProviderConfigReferenceResolvers<ContextType>;
  ProviderConfigStatus?: ProviderConfigStatusResolvers<ContextType>;
  ProviderConnection?: ProviderConnectionResolvers<ContextType>;
  ProviderRevision?: ProviderRevisionResolvers<ContextType>;
  ProviderRevisionConnection?: ProviderRevisionConnectionResolvers<ContextType>;
  ProviderRevisionSpec?: ProviderRevisionSpecResolvers<ContextType>;
  ProviderRevisionStatus?: ProviderRevisionStatusResolvers<ContextType>;
  ProviderSpec?: ProviderSpecResolvers<ContextType>;
  ProviderStatus?: ProviderStatusResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegisterRequest?: RegisterRequestResolvers<ContextType>;
  RegisteredKey?: RegisteredKeyResolvers<ContextType>;
  RegistryCRD?: RegistryCrdResolvers<ContextType>;
  RegistryComposition?: RegistryCompositionResolvers<ContextType>;
  RegistryCompositionDetails?: RegistryCompositionDetailsResolvers<ContextType>;
  RegistryCompositionResource?: RegistryCompositionResourceResolvers<ContextType>;
  RegistryPackage?: RegistryPackageResolvers<ContextType>;
  RegistryPackageDocs?: RegistryPackageDocsResolvers<ContextType>;
  RegistryPackageDocsPage?: RegistryPackageDocsPageResolvers<ContextType>;
  RegistryQuery?: RegistryQueryResolvers<ContextType>;
  RegistryResource?: RegistryResourceResolvers<ContextType>;
  RegistryResourceDetails?: RegistryResourceDetailsResolvers<ContextType>;
  RegistrySearchResult?: RegistrySearchResultResolvers<ContextType>;
  RegistrySearchResults?: RegistrySearchResultsResolvers<ContextType>;
  RegistryXRD?: RegistryXrdResolvers<ContextType>;
  Repository?: RepositoryResolvers<ContextType>;
  RepositoryVersion?: RepositoryVersionResolvers<ContextType>;
  ResourceInstanceDetails?: ResourceInstanceDetailsResolvers<ContextType>;
  Robot?: RobotResolvers<ContextType>;
  Secret?: SecretResolvers<ContextType>;
  SecretReference?: SecretReferenceResolvers<ContextType>;
  Space?: SpaceResolvers<ContextType>;
  SpaceGroup?: SpaceGroupResolvers<ContextType>;
  SpaceStatus?: SpaceStatusResolvers<ContextType>;
  StringMap?: GraphQLScalarType;
  Team?: TeamResolvers<ContextType>;
  TeamControlPlane?: TeamControlPlaneResolvers<ContextType>;
  TeamMember?: TeamMemberResolvers<ContextType>;
  TeamMemberUser?: TeamMemberUserResolvers<ContextType>;
  TeamRepositoriesResult?: TeamRepositoriesResultResolvers<ContextType>;
  TeamRepository?: TeamRepositoryResolvers<ContextType>;
  TeamRobotRelationship?: TeamRobotRelationshipResolvers<ContextType>;
  Time?: GraphQLScalarType;
  Timestamp?: GraphQLScalarType;
  Token?: TokenResolvers<ContextType>;
  TotpApplication?: TotpApplicationResolvers<ContextType>;
  TwoFactorKeys?: TwoFactorKeysResolvers<ContextType>;
  TypeReference?: TypeReferenceResolvers<ContextType>;
  UpdateKubernetesResourcePayload?: UpdateKubernetesResourcePayloadResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAccount?: UserAccountResolvers<ContextType>;
  UserSettings?: UserSettingsResolvers<ContextType>;
  UserTeam?: UserTeamResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  goField?: GoFieldDirectiveResolver<any, any, ContextType>;
  goModel?: GoModelDirectiveResolver<any, any, ContextType>;
};
